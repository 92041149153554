import {
  ITBox,
  QboAlert,
  QboFormGroup,
  QboLabelGroup,
  QboModal,
  QboSelect,
  QboSpinnerProgress,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import useUserClaims from '@hooks/useUserClaims';
import useChangeSubscription from './pageHooks/useChangeSubscription';
import useChangeSubscriptionPlans from './pageHooks/useChangeSubscriptionPlans';

function ChangeSubscriptionModal({ closeModal, showModal, organization }) {
  const claims = useUserClaims();
  const isFinance = claims?.role && ['finance'].includes(claims?.role?.toLowerCase());
  const { changeSubscriptionState, errorMessage, form, handleSubmit, handleClose } =
    useChangeSubscription({
      organization,
      closeModal,
      isFinance,
    });
  const plans = useChangeSubscriptionPlans({
    organizationOwnerId: organization?.organizationOwnerId,
    showModal,
    closeModal,
  });

  const { t } = useTranslation();

  if (showModal && plans.fetching) {
    return <QboSpinnerProgress className="DefaultApplication__not-ready-loading" overlay />;
  }

  const showSeatInput =
    organization?.planType !== 'personal' && organization?.planType !== 'qbo_sku';

  return (
    <QboModal
      onClose={handleClose}
      open={showModal && plans.options && plans.options.length > 0}
      title={t('organization_list_page.change_subscription.title')}
      showCloseIcon
      preventOutsideClick>
      <ITBox direction="TransferSubscriptionFormPage__wrapper column">
        <QboFormGroup
          loadingButton={changeSubscriptionState.fetching}
          onSubmit={handleSubmit}
          withGenerateAndResetButton
          handleOnResetButton={handleClose}
          secondButtonLabel="Cancel"
          firstButtonLabel="Submit">
          {errorMessage && (
            <QboAlert
              variant="outlined"
              type="error"
              style={{
                // border: '1px solid #ebebeb',
                background: '#fdeded',
                fontSize: '10px !important',
              }}>
              <p>
                {t('organization_list_page.change_subscription.error', { error: errorMessage })}
              </p>
            </QboAlert>
          )}
          <QboAlert
            variant="outlined"
            type="info"
            style={{
              border: '1px solid #ebebeb',
              background: '#f3f3f3',
              fontSize: '10px !important',
              marginTop: '20px',
            }}>
            <QboTypography
              noWrap={false}
              style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
              {t('organization_list_page.change_subscription.confirmation')}
            </QboTypography>
          </QboAlert>
          <ITBox
            className="TransferSubresellerPage__information-box"
            direction="column"
            sx={{ marginBottom: '20px' }}>
            <QboLabelGroup
              title="Organization Owner ID"
              description={organization?.organizationOwnerId?.toString() || '-'}
            />
            <QboLabelGroup
              title="Organization Name"
              description={organization?.orgName?.toString() || '-'}
            />
            <QboLabelGroup title="Current Plan" description={organization?.plan || '-'} />
            <QboLabelGroup
              title="Current Seat Count"
              description={organization?.seatsPurchased?.toString() || '-'}
            />
          </ITBox>

          <pre>{errorMessage}</pre>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboTextField
                id="supportTicket"
                required={!isFinance}
                placeholder="Support Ticket"
                label="Support Ticket"
                fullWidth
                disabled={changeSubscriptionState.fetching}
                value={form.values.supportTicket}
                errorMessage={form.errors.supportTicket}
                inputProps={{ maxLength: 225 }}
                onChange={(e) => {
                  form.change('supportTicket', e.currentTarget.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <QboTextField
                id="escalationTicket"
                placeholder="Escalation Ticket"
                label="Escalation Ticket"
                fullWidth
                disabled={changeSubscriptionState.fetching}
                value={form.values.escalationTicket}
                onChange={(e) => {
                  form.change('escalationTicket', e.currentTarget.value);
                }}
                inputProps={{ maxLength: 225 }}
              />
            </Grid>
          </Grid>
          <QboTextField
            id="description"
            placeholder="Description"
            label="Description"
            fullWidth
            disabled={changeSubscriptionState.fetching}
            value={form.values.description}
            onChange={(e) => {
              form.change('description', e.currentTarget.value);
            }}
            inputProps={{ maxLength: 225 }}
          />

          <Grid container spacing={2}>
            <Grid item xs={showSeatInput ? 6 : 12}>
              <QboSelect
                label="Plan"
                required
                options={plans.options}
                handleChange={(e) => {
                  form.change('planId', e.target.value);
                }}
                disabled={changeSubscriptionState.fetching}
                value={form.values.planId}
                errorMessage={form.errors.planId}
                defaultValue={0}
              />
            </Grid>
            <Grid item xs={6}>
              {showSeatInput && (
                <QboTextField
                  id="seatCount"
                  placeholder="Seat count"
                  label="Seat count (leave empty to use the current seat count)"
                  fullWidth
                  disabled={changeSubscriptionState.fetching}
                  value={form.values.seatCount}
                  errorMessage={form.errors.seatCount}
                  inputProps={{ maxLength: 225 }}
                  onChange={(e) => {
                    form.change('seatCount', e.currentTarget.value);
                  }}
                />
              )}
            </Grid>
          </Grid>
        </QboFormGroup>
      </ITBox>
    </QboModal>
  );
}

ChangeSubscriptionModal.propTypes = {
  closeModal: PropTypes.func,
  showModal: PropTypes.bool,
  organization: PropTypes.object,
};

ChangeSubscriptionModal.defaultProps = {
  closeModal: () => {},
  showModal: false,
  organization: null,
};

export default ChangeSubscriptionModal;
