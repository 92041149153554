import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ITBox,
  QboAlert,
  QboButton,
  QboLabelGroup,
  QboModal,
  QboSimpleDataTable,
  QboSnackbar,
  QboSpinnerProgress,
  QboTypography,
} from '@ui/Components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { usePreviewSkippedItemsMutation } from '@services/WebApiService/RestoreSkippedItemsService';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import {
  EMAIL_RESTORE,
  EMAIL_RESTORE_SKIPPED_ITEMS_COLUMNS,
} from '@pages/RestoreStatusPage/pageHooks/constants/emailRestoreConstants';
import useDownload from '@hooks/useDownload';
import {
  downloadRestoreSkippedItemsCsvAsync,
  resetDownloadRestoreSkippedItemsCsvState,
} from '@features/restoreSkippedItems/downloadRestoreSkippedItemsCsvSlice';
import { convertSize } from '@utilities/stringUtils';

export default function RestoreSkippedItemsModal({ closeModal, showModal, selectedRestore }) {
  const dispatch = useDispatch();
  const { downloadRestoreSkippedItemsCsvState } = useSelector(
    (s) => s.downloadRestoreSkippedItemsCsv
  );
  const [snackbarState, setSnackbarState] = useState({ open: false });
  const { t } = useTranslation();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [previewSkippedItems, previewSkippedItemsState] = usePreviewSkippedItemsMutation();

  useDownload(
    downloadRestoreSkippedItemsCsvState.data,
    'text/csv;charset=utf-8',
    `restore_skipped_items_${selectedRestore?.restorableType}_${selectedRestore?.restoreId}.csv`,
    () => {
      dispatch(resetDownloadRestoreSkippedItemsCsvState());
    },
    () => setSnackbarState({ open: true, type: 'success', message: 'Successfully downloaded' }),
    (err) => setSnackbarState({ open: true, type: 'error', message: err })
  );

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleDownload = () => {
    if (!selectedRestore.restoreId || !selectedRestore.restorableType) return;
    dispatch(resetDownloadRestoreSkippedItemsCsvState());
    dispatch(
      downloadRestoreSkippedItemsCsvAsync({
        accessToken: accessTokenSet,
        id: selectedRestore.restoreId,
        restorableType: selectedRestore.restorableType,
      })
    );
  };

  const header = useMemo(() => {
    switch (selectedRestore.restorableType) {
      case EMAIL_RESTORE:
        return EMAIL_RESTORE_SKIPPED_ITEMS_COLUMNS;
      default:
        return EMAIL_RESTORE_SKIPPED_ITEMS_COLUMNS;
    }
  }, [selectedRestore]);

  const total = useMemo(() => {
    if (!selectedRestore?.skippedItems) return undefined;
    return JSON.parse(selectedRestore.skippedItems).count;
  }, [selectedRestore]);

  useEffect(() => {
    dispatch(resetDownloadRestoreSkippedItemsCsvState());
    if (!showModal) return;
    previewSkippedItems({
      accessToken: accessTokenSet,
      id: selectedRestore.restoreId,
      restorableType: selectedRestore.restorableType,
    });
  }, [showModal]);

  const calcList = useMemo(() => {
    if (!previewSkippedItemsState.data?.data) return [];
    return previewSkippedItemsState.data?.data.map((row) => ({
      ...row,
      size: convertSize(`${row.size}Bytes`, 'KB', 2),
    }));
  }, [previewSkippedItemsState]);

  if (previewSkippedItemsState.isLoading) {
    return <QboSpinnerProgress className="DefaultApplication__not-ready-loading" overlay />;
  }

  return (
    <QboModal
      onClose={closeModal}
      open={showModal}
      title={t('restore_status_page.skipped_items.title')}
      showCloseIcon
      preventOutsideClick>
      <QboSnackbar onClose={handleSnackbarClose} {...snackbarState} />
      {total > calcList.length && (
        <QboAlert
          variant="outlined"
          type="info"
          style={{
            border: '1px solid #ebebeb',
            background: '#f3f3f3',
            fontSize: '10px !important',
          }}>
          <QboTypography
            noWrap={false}
            style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
            {t('restore_status_page.skipped_items.information')}
          </QboTypography>
        </QboAlert>
      )}
      <ITBox
        className="TransferSubresellerPage__information-box"
        direction="column"
        sx={{ marginBottom: '20px' }}>
        <QboLabelGroup
          title="Restore ID"
          description={selectedRestore?.restoreId?.toString() || '-'}
        />
        <QboLabelGroup
          title="Restore Type"
          description={selectedRestore?.restorableType?.toString() || '-'}
        />
      </ITBox>
      <div
        style={{
          marginBottom: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <QboTypography
          sx={{
            fontSize: '15px !important',
            color: '#42526e',
            fontWeight: 600,
          }}>
          Showing {calcList?.length || 0} out of {total} items
        </QboTypography>
        <QboButton
          style={{ width: 160 }}
          variant="contained"
          onClick={handleDownload}
          loading={false}>
          {t('restore_status_page.skipped_items.download_csv')}
        </QboButton>
      </div>

      <QboSimpleDataTable
        sx={{ width: '100%' }}
        rows={calcList}
        header={header}
        loading={previewSkippedItemsState.isLoading}
      />
    </QboModal>
  );
}

RestoreSkippedItemsModal.propTypes = {
  closeModal: PropTypes.func,
  showModal: PropTypes.bool,
  selectedRestore: PropTypes.object,
};

RestoreSkippedItemsModal.defaultProps = {
  closeModal: () => {},
  showModal: false,
  selectedRestore: {},
};
