import { useEffect, useMemo, useState } from 'react';

const msGroupSettingLabel = 'ms group settings';

const impersonateSettingsLabels = [
  'permissions',
  'view',
  'preview',
  'restore',
  'download',
  'migrate',
  'others',
];

export default function useResellerLabelSearch(labels, onSearch, onReset) {
  const [searchInputText, setSearchInputText] = useState('');
  const [searchValue, setSearchValue] = useState();

  const handleOnSearch = () => {
    setSearchValue(searchInputText);
  };

  const handleOnSearchReset = () => {
    setSearchInputText('');
    setSearchValue(undefined);
  };

  useEffect(() => {
    if (searchValue) onSearch();
    else onReset();
  }, [searchValue]);

  const filteredLabels = useMemo(() => {
    if (!searchValue) return labels;
    const lowerSearch = searchValue.toLowerCase();
    const result = {};
    Object.keys(labels).forEach((k) => {
      const filtered = labels[k].filter((e) => e.label?.toLowerCase().includes(lowerSearch));
      result[k] = filtered;
    });
    return result;
  }, [labels, searchValue]);

  const showMsGroupSettings = useMemo(
    () => !searchValue || msGroupSettingLabel.includes(searchValue.toLowerCase()),
    [searchValue]
  );

  const filteredImpersonateSettings = useMemo(() => {
    const result = {};
    impersonateSettingsLabels.forEach((l) => {
      result[l] = !searchValue || l.includes(searchValue.toLowerCase());
    });
    return result;
  }, [searchValue]);

  const visibleSections = useMemo(() => {
    const result = {};

    // Reseller Details
    result.partnerType = filteredLabels?.resellerDetailsPartnerType?.length > 0;
    result.portalSettings = filteredLabels?.resellerDetailsPortalSettings?.length > 0;
    result.resellerDetails =
      result.partnerType || result.portalSettings || filteredLabels?.resellerDetails?.length > 0;

    // API Settings
    result.apiSettings = filteredLabels?.apiSettings?.length > 0;

    // Reseller Permissions
    result.adminPrivileges = filteredLabels?.resellerPermissionsAdminPrivileges?.length > 0;
    result.contractSettings = filteredLabels?.resellerPermissionsContractSettings?.length > 0;
    result.distributorMasterResellerPermissions =
      filteredLabels?.resellerPermissionsDistributorMasterResellerPermissions?.length > 0;

    // Reseller Permissions - Features
    result.msGroupSettings = showMsGroupSettings;

    // Reseller Permissions - Login as Client
    result.impersonatePermissions = filteredImpersonateSettings.permissions;
    result.impersonateView = filteredImpersonateSettings.view;
    result.impersonatePreview = filteredImpersonateSettings.preview;
    result.impersonateRestore = filteredImpersonateSettings.restore;
    result.impersonateDownload = filteredImpersonateSettings.download;
    result.impersonateMigrate = filteredImpersonateSettings.migrate;
    result.impersonateOthers = filteredImpersonateSettings.others;
    result.loginAsClient =
      result.impersonatePermissions ||
      result.impersonateView ||
      result.impersonatePreview ||
      result.impersonateRestore ||
      result.impersonateDownload ||
      result.impersonateMigrate ||
      result.impersonateOthers ||
      filteredLabels?.resellerPermissionsFeaturesLoginAsClient?.length > 0;

    result.featuresNFR = filteredLabels?.resellerPermissionsFeaturesNFR?.length > 0;
    result.nonProfit = filteredLabels?.resellerPermissionsFeaturesNonProfit?.length > 0;
    result.featuresQBO = filteredLabels?.resellerPermissionsFeaturesQBO?.length > 0;
    result.features =
      result.msGroupSettings ||
      result.loginAsClient ||
      result.featuresNFR ||
      result.nonProfit ||
      result.featuresQBO ||
      filteredLabels?.resellerPermissionsFeatures?.length > 0;

    // Reseller Permissions - Organization Settings
    result.accountsActivities =
      filteredLabels?.resellerPermissionsOrgSettingsOrgPermissionsAccountsActivities?.length > 0;
    result.enableRestoreMigrateDownloadLimit =
      filteredLabels?.resellerPermissionsOrgSettingsOrgPermissionsEnableRestoreMigrateDownloadLimit
        ?.length > 0;
    result.journalAccountsArchiverPlanOnly =
      filteredLabels?.resellerPermissionsOrgSettingsOrgPermissionsJournalAccountsArchiverPlanOnly
        ?.length > 0;
    result.organisationPreferences =
      filteredLabels?.resellerPermissionsOrgSettingsOrgPermissionsOrganisationPreferences?.length >
      0;
    result.others = filteredLabels?.resellerPermissionsOrgSettingsOrgPermissionsOthers?.length > 0;
    result.savedSearchArchiverPlanOnly =
      filteredLabels?.resellerPermissionsOrgSettingsOrgPermissionsSavedSearchArchiverPlanOnly
        ?.length > 0;
    result.userManagementBackupAndArchiverPlanOnly =
      filteredLabels
        ?.resellerPermissionsOrgSettingsOrgPermissionsUserManagementBackupAndArchiverPlanOnly
        ?.length > 0;
    result.organisationSettings =
      result.accountsActivities ||
      result.enableRestoreMigrateDownloadLimit ||
      result.journalAccountsArchiverPlanOnly ||
      result.organisationPreferences ||
      result.others ||
      result.savedSearchArchiverPlanOnly ||
      result.userManagementBackupAndArchiverPlanOnly;

    result.paymentSettings = filteredLabels?.resellerPermissionsPaymentSettings?.length > 0;
    result.trial = filteredLabels?.resellerPermissionsTrial?.length > 0;

    result.resellerPermissions =
      result.adminPrivileges ||
      result.contractSettings ||
      result.distributorMasterResellerPermissions ||
      result.features ||
      result.organisationSettings ||
      result.paymentSettings ||
      result.trial ||
      filteredLabels?.resellerPermissions?.length > 0;

    result.resellerPricing = filteredLabels?.resellerPricingLabels?.length > 0;
    result.smtpSettings = filteredLabels?.smtpSettingsLabels?.length > 0;

    return result;
  }, [filteredLabels, showMsGroupSettings]);

  const noResult = useMemo(() => {
    const k = Object.keys(visibleSections);
    for (let i = 0; i < k.length; i += 1) {
      if (visibleSections[k[i]]) return false;
    }
    return true;
  }, [visibleSections]);

  return {
    searchInputText,
    searchValue,
    filteredLabels,
    visibleSections,
    noResult,
    setSearchInputText,
    handleOnSearch,
    handleOnSearchReset,
  };
}
