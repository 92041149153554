import { constant } from '@config/BaseSetting';
import { showFixedAlert } from '@features/application/applicationSlice';
import {
  resetValidateToggleEnforceSSOState,
  validateToggleEnforceSSOAsync,
} from '@features/updateEnforceSSO/validateToggleEnforceSSOSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useValidateEnforceSSO(selectedAccount) {
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);
  const { validateToggleEnforceSSOState } = useSelector((state) => state.validateToggleEnforceSSO);
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const reset = () => {
    setIsValid(false);
    dispatch(resetValidateToggleEnforceSSOState());
  };

  useEffect(() => {
    reset();
    if (!selectedAccount?.id || !selectedAccount?.type || !selectedAccount?.sso) return;
    dispatch(
      validateToggleEnforceSSOAsync({
        accessTokenSet,
        reset: true,
        parameters: {
          'account-id': selectedAccount.id,
          'force-reset-oauth': false,
          'change-request-type': selectedAccount.type,
        },
      })
    );
  }, [selectedAccount]);

  useEffect(() => {
    const result = validateToggleEnforceSSOState.validationResult;
    if (!result) return;
    setIsValid(!!result['is-valid']);
    if (!result['is-valid']) {
      dispatch(
        showFixedAlert({
          descriptionHtml: result.message,
          severity: 'error',
        })
      );
    }
  }, [validateToggleEnforceSSOState]);

  return {
    isValid,
    validating: validateToggleEnforceSSOState.fetching,
    reset,
  };
}
