import { organizationInformationPath, resellersListPath } from '@config/Routes/WebClientRoutes';

export const ENTRA_ID_STATUS = 'entraid';
export const ENTRA_ID_STATUS_COLUMNS = [
  { label: 'ID', columnName: 'backupId' },
  { label: 'Backup Account ID', columnName: 'userBackupId' },
  { label: 'Name', columnName: 'backupName' },
  { label: 'Initial Domain', columnName: 'domainName' },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
    navigateURL: (row) =>
      `${organizationInformationPath}?id=${row.organizationOwnerId}&name=${row.organizationName}`,
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
    navigateURL: (row) => `${resellersListPath}?id=${row.partnerId}&name=${row.partnerName}`,
  },
  { label: 'Backup Status', columnName: 'status', size: 'sm' },
  { label: 'Additional Status', columnName: 'additionalStatus', size: 'sm' },
  { label: 'Start Backup Time', columnName: 'startTime', size: 'sm' },
  { label: 'End Backup Time', columnName: 'endTime', size: 'sm' },
  { label: 'Current Object Number', columnName: 'currentObjectsCount', size: 'sm' },
  { label: 'Total Object Number', columnName: 'totalObjectsCount', size: 'sm' },
  { label: 'Retries Time', columnName: 'retries', size: 'sm' },
  { label: 'Error Messages', columnName: 'errorMessage', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
];
