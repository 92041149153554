import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import WebApiRoutes from '@config/Routes/WebApiRoutes';
import WebApiGeneralRoutes from '@config/Routes/WebApiGeneralRoutes';

const baseQuery = fetchBaseQuery({
  baseUrl: WebApiRoutes.getBaseUrl(),
});

const RestoreSkippedItemsApi = createApi({
  reducerPath: 'restoreSkippedItems',
  baseQuery,
  endpoints: (builder) => ({
    previewSkippedItems: builder.mutation({
      query: ({ id, restorableType, accessToken }) => ({
        url: WebApiGeneralRoutes.apiV1Path(`/restore-skipped-items/preview`, {
          id,
          restorableType,
        }),
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    }),
  }),
});

export const { usePreviewSkippedItemsMutation } = RestoreSkippedItemsApi;

export default RestoreSkippedItemsApi;
