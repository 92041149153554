import {
  ITBox,
  QboAlert,
  QboCardListItem,
  QboFormGroup,
  QboLabelGroup,
  QboModal,
  QboSelect,
  QboSimpleDataTable,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import useTenantBulkExcludeMailbox from './pageHooks/useTenantBulkExcludeMailbox';

const mailboxHeaders = [
  { label: 'ID', columnName: 'id' },
  {
    label: 'User Name',
    columnName: 'name',
    size: 'sm',
  },
  {
    label: 'Email',
    columnName: 'email',
    size: 'sm',
  },
  {
    label: 'UPN',
    columnName: 'upn',
    size: 'sm',
  },
];

const credentialTypeMapping = {
  M365: 3,
  GWS: 4,
};

export default function TenantBulkExcludeMailboxModal(props) {
  const {
    closeModal,
    showModal,
    organizationOwnerId,
    organizationName,
    credentialId,
    credentialType,
    mailboxes,
  } = props;

  const protocol = credentialTypeMapping[credentialType];

  const { t } = useTranslation();

  const { bulkExcludeMailboxState, errorMessage, form, handleSubmit, handleClose } =
    useTenantBulkExcludeMailbox({
      organizationOwnerId,
      credentialId,
      protocol,
      emails: mailboxes.map((m) => m.email),
      closeModal,
    });

  return (
    <QboModal
      onClose={handleClose}
      open={showModal}
      title={t('organization_list_page.bulk_exclude_mailbox.title')}
      showCloseIcon
      preventOutsideClick>
      <ITBox direction="TransferSubscriptionFormPage__wrapper column">
        <QboFormGroup
          loadingButton={bulkExcludeMailboxState.fetching}
          onSubmit={handleSubmit}
          withGenerateAndResetButton
          handleOnResetButton={handleClose}
          secondButtonLabel="Cancel"
          firstButtonLabel="Submit">
          {errorMessage && (
            <QboAlert
              variant="outlined"
              type="error"
              style={{
                // border: '1px solid #ebebeb',
                background: '#fdeded',
                fontSize: '10px !important',
              }}>
              <p>
                {t('organization_list_page.bulk_exclude_mailbox.error', { error: errorMessage })}
              </p>
            </QboAlert>
          )}
          <ITBox
            className="TransferSubresellerPage__information-box"
            direction="column"
            sx={{ marginBottom: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <QboLabelGroup
                  title="Organization Owner ID"
                  description={organizationOwnerId?.toString() || '-'}
                />
              </Grid>
              <Grid item xs={6}>
                <QboLabelGroup
                  title="Organization Name"
                  description={organizationName?.toString() || '-'}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <QboLabelGroup
                  title="Credential ID"
                  description={credentialId?.toString() || '-'}
                />
              </Grid>
              <Grid item xs={6}>
                <QboLabelGroup title="Credential Type" description={credentialType || '-'} />
              </Grid>
            </Grid>
          </ITBox>
          <div style={{ marginBottom: '2rem' }}>
            <QboTypography
              sx={{
                color: '#000000DE',
                fontWeight: 600,
              }}>
              {t('tenant_page.bulk_exclude_mailbox.confirmation')}
            </QboTypography>
            <QboSimpleDataTable rows={mailboxes} header={mailboxHeaders} />
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboTextField
                id="supportTicket"
                required
                placeholder="Support Ticket"
                label="Support Ticket"
                fullWidth
                disabled={bulkExcludeMailboxState.fetching}
                value={form.values.supportTicket}
                errorMessage={form.errors.supportTicket}
                inputProps={{ maxLength: 225 }}
                onChange={(e) => {
                  form.change('supportTicket', e.currentTarget.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <QboTextField
                id="escalationTicket"
                placeholder="Escalation Ticket"
                label="Escalation Ticket"
                fullWidth
                disabled={bulkExcludeMailboxState.fetching}
                value={form.values.escalationTicket}
                onChange={(e) => {
                  form.change('escalationTicket', e.currentTarget.value);
                }}
                inputProps={{ maxLength: 225 }}
              />
            </Grid>
          </Grid>
          <QboTextField
            id="description"
            placeholder="Description"
            label="Description"
            fullWidth
            disabled={bulkExcludeMailboxState.fetching}
            value={form.values.description}
            onChange={(e) => {
              form.change('description', e.currentTarget.value);
            }}
            inputProps={{ maxLength: 225 }}
          />
        </QboFormGroup>
      </ITBox>
    </QboModal>
  );
}

TenantBulkExcludeMailboxModal.propTypes = {
  closeModal: PropTypes.func,
  showModal: PropTypes.bool,
  organizationOwnerId: PropTypes.number,
  organizationName: PropTypes.string,
  credentialId: PropTypes.number,
  credentialType: PropTypes.oneOf(['M365', 'GWS']).isRequired,
  mailboxes: PropTypes.arrayOf(PropTypes.object),
};

TenantBulkExcludeMailboxModal.defaultProps = {
  closeModal: () => {},
  showModal: false,
  organizationOwnerId: 0,
  organizationName: '',
  credentialId: 0,
  mailboxes: [],
};
