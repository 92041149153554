import { constant } from '@config/BaseSetting';
import { showFixedAlert } from '@features/application/applicationSlice';
import {
  resetValidateToggleMFAState,
  validateToggleMFAAsync,
} from '@features/toggleMFA/validateToggleMFASlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useValidateMFA(selectedAccount) {
  const dispatch = useDispatch();
  const [isValid, setIsValid] = useState(false);
  const { validateToggleMFAState } = useSelector((state) => state.validateToggleMFA);
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  const reset = () => {
    setIsValid(false);
    dispatch(resetValidateToggleMFAState());
  };

  useEffect(() => {
    reset();
    if (!selectedAccount?.id || !selectedAccount?.type || !selectedAccount?.mfaStatusText) return;
    dispatch(
      validateToggleMFAAsync({
        accessTokenSet,
        reset: true,
        parameters: {
          'account-id': selectedAccount.id,
          'change-request-type': selectedAccount.type,
        },
      })
    );
  }, [selectedAccount]);

  useEffect(() => {
    const result = validateToggleMFAState.validationResult;
    if (!result) return;
    setIsValid(!!result['is-valid']);
    if (!result['is-valid']) {
      dispatch(
        showFixedAlert({
          descriptionHtml: result.message,
          severity: 'error',
        })
      );
    }
  }, [validateToggleMFAState]);

  return {
    isValid,
    validating: validateToggleMFAState.fetching,
    reset,
  };
}
