import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import {
  ITBox,
  QboAdvanceTable,
  QboButton,
  QboCard,
  QboFormGroup,
  QboTextField,
  QboTypography,
} from '@ui/Components';

import QboSortByButton from '@ui/Components/QboSortByButton';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import { resetGwsUsersTenantState } from '@features/gwsUsersTenant/gwsUsersTenantSlice';
import TenantBulkExcludeMailboxModal from '@ui/Sections/TenantDetailsPage/TenantBulkExcludeMailboxModal';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useDataService from './pageHooks/useDataService';
import useGwsUsersTenantService from './pageHooks/useGwsUsersTenantService';
import useUserSelection from './pageHooks/useUserSelection';

export default function GwsUsersTenantPage(props) {
  const { lastRequestId, credentialId, organizationOwnerId, organizationName } = props;
  const { hasPermission, featureFlags } = useFeatureFlag();
  const { t } = useTranslation();
  const { getGwsUsersTenantState } = useSelector((state) => state.gwsUsersTenant);
  const { gwsUsersTenantInformation, fetching, paginationList } = getGwsUsersTenantState;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const showCheckboxes = hasPermission(featureFlags.BulkExcludeMailbox);

  const paginationProp = useRef(pagination);

  const {
    gwsUsersTenantHeaders,
    buttonStyle,
    archivedStatus,
    suspendedStatus,
    activeStatus,
    filterStates,
    initialFilterState,
    columnOrder,
  } = useDataService();

  const [filterDataState, setFilterDataState] = useState(filterStates);

  const { selected, rowSelection, setRowSelection } = useUserSelection(gwsUsersTenantInformation);

  const [adFilter, setAdFilter] = useState(initialFilterState);

  const dispatch = useDispatch();

  const {
    handleFetchGwsUsersTenantAsync,
    handleOnTextFiledChange,
    handleOnResetButtonClick,
    handleStateUpdate,
    handleOnHideAdvanceClick,
    handleUpdateFilters,
    matchTextHandler,
    excludeModalState,
    showExcludeModal,
    closeExcludeModal,
  } = useGwsUsersTenantService(
    filterDataState,
    setFilterDataState,
    filterStates,
    pagination,
    adFilter,
    setAdFilter,
    initialFilterState,
    lastRequestId,
    setPagination
  );

  const handleOnSearchClick = (e) => {
    e.preventDefault();
    matchTextHandler();
    handleFetchGwsUsersTenantAsync(false);
    setPagination({ pageIndex: 0, pageSize: 10 });
  };

  useEffect(() => {
    if (lastRequestId !== null) {
      handleFetchGwsUsersTenantAsync(false);
    } else {
      dispatch(resetGwsUsersTenantState());
    }
  }, []);

  useEffect(() => {
    const paginationPropList = paginationProp.current;
    if (!isEqual(paginationPropList, pagination)) {
      paginationProp.current = pagination;
      handleFetchGwsUsersTenantAsync(false);
    }
  }, [pagination]);

  const excludeValidation = useMemo(() => {
    if (!selected?.length) return t('tenant_page.tooltip.exclude_no_user_selected');
    if (!selected?.filter((row) => row.active === 'available').length)
      return t('tenant_page.tooltip.exclude_no_user_available');
    return null;
  }, [selected]);

  return (
    <>
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <div style={{ display: 'flex' }}>
            <QboTextField
              id="fullTextSearch"
              explicitStyleClass="FormControl__width-wrapper_40"
              value={filterDataState.textFieldValue}
              isWidthSetExplicit
              placeholder="User Name/Email/UPN/GWS ID"
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                handleOnTextFiledChange(e);
              }}
            />
          </div>

          <div style={{ marginTop: 8 }}>
            <Button
              disableRipple
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => {
                handleStateUpdate('advanceClick', !filterDataState.advanceClick);
                handleOnHideAdvanceClick();
              }}>
              {filterDataState.advanceClick
                ? t('partner_details_page.button.hide_advance_search')
                : t('partner_details_page.button.show_advance_search')}
            </Button>
          </div>
          {filterDataState.advanceClick && (
            <div>
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={adFilter.find((item) => item.field === 'filter_active').value}
                buttonWidth={220}
                startAdornmentTextWidth={60}
                startAdornmentText="Status :"
                handleChange={(e) => handleUpdateFilters('filter_active', e.target.value)}
                menuItemList={activeStatus}
              />
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={adFilter.find((item) => item.field === 'filter_suspended').value}
                buttonWidth={220}
                startAdornmentTextWidth={190}
                startAdornmentText="Suspended :"
                handleChange={(e) => handleUpdateFilters('filter_suspended', e.target.value)}
                menuItemList={suspendedStatus}
              />
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={adFilter.find((item) => item.field === 'filter_archived').value}
                buttonWidth={220}
                startAdornmentTextWidth={190}
                startAdornmentText="Archived :"
                handleChange={(e) => handleUpdateFilters('filter_archived', e.target.value)}
                menuItemList={archivedStatus}
              />
            </div>
          )}
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <hr />
        <ITBox
          direction="row"
          sx={{
            margin: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 3,
          }}>
          <QboTypography
            sx={{
              fontSize: 18,
              color: '#000000DE',
              fontWeight: 600,
            }}>
            GWS Users Tenant List
          </QboTypography>
          {hasPermission(featureFlags.BulkExcludeMailbox) && (
            <QboButton
              disabled={!!excludeValidation}
              withTitle={!!excludeValidation}
              title={excludeValidation}
              variant="contained"
              onClick={() => showExcludeModal(selected)}>
              {t('tenant_page.bulk_exclude_mailbox.exclude_button')}
            </QboButton>
          )}
        </ITBox>

        {!filterDataState.resetClicked && filterDataState.matchText ? (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'pre-wrap',
            }}>
            {!filterDataState.resetClicked && filterDataState.matchText}
          </QboTypography>
        ) : null}

        {lastRequestId && (gwsUsersTenantInformation.length > 0 || fetching) && (
          <QboAdvanceTable
            enableColumnClickable={!false}
            isLoading={fetching}
            enableRowSelection={showCheckboxes}
            isCheckBoxFilterEnable={showCheckboxes}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableTopToolBar={false}
            headers={gwsUsersTenantHeaders}
            rows={gwsUsersTenantInformation}
            rowsCount={Number(paginationList?.TotalCount || 0)}
            pagination={pagination}
            setPagination={setPagination}
          />
        )}

        {gwsUsersTenantInformation.length === 0 && !fetching && <NoStatusSection />}
      </QboCard>
      <TenantBulkExcludeMailboxModal
        showModal={excludeModalState.showModal}
        closeModal={closeExcludeModal}
        organizationOwnerId={organizationOwnerId}
        organizationName={organizationName}
        credentialId={credentialId}
        credentialType="GWS"
        mailboxes={excludeModalState.mailboxes}
      />
    </>
  );
}

GwsUsersTenantPage.propTypes = {
  lastRequestId: PropTypes.number,
  credentialId: PropTypes.number,
  organizationOwnerId: PropTypes.number,
  organizationName: PropTypes.string,
};

GwsUsersTenantPage.defaultProps = {
  lastRequestId: null,
  credentialId: null,
  organizationOwnerId: null,
  organizationName: '',
};
