import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect } from 'react';
import { showFixedAlert } from '@features/application/applicationSlice';
import { useTranslation } from 'react-i18next';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import { useEditOrganizationSettingsMutation } from '@services/WebApiService/OrganizationService';
import * as yup from 'yup';
import useForm from '@hooks/useForm';

const formSchema = yup.object().shape({
  supportTicket: yup.string().required('Support Ticket is required').default(''),
  escalationTicket: yup.string().default(''),
  description: yup.string().default(''),
});

export default function useEditOrganizationSettings({
  organizationOwnerId,
  editData,
  onSuccessRequest,
  onFailedRequest,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const form = useForm(formSchema);
  const [editOrganizationSettings, editOrganizationSettingsState] =
    useEditOrganizationSettingsMutation();

  const submitRequest = () => {
    const entries = {};
    editData.forEach((e) => {
      entries[e.key] = e.value?.toString() ?? null;
    });
    editOrganizationSettings({
      accessToken: accessTokenSet,
      parameters: {
        'support-ticket': form.values.supportTicket,
        description: form.values.description,
        'escalation-ticket': form.values.escalationTicket,
        organizationOwnerId,
        entries,
      },
    });
  };

  const handleOnValidateData = (e) => {
    e.preventDefault();
    submitRequest();
  };

  const resetAll = () => {
    editOrganizationSettingsState.reset();
    form.reset();
  };

  useEffect(() => {
    if (editOrganizationSettingsState.data?.['status-code'] === 200) {
      if (editOrganizationSettingsState.data?.data?.serviceTypeCode) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': editOrganizationSettingsState.data?.data?.serviceTypeCode,
        });
        dispatch(
          showFixedAlert({
            descriptionHtml: t(
              'organization_details_page.edit_organization_settings.success_submitted_with_link',
              {
                link,
              }
            ),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t(
              'organization_details_page.edit_organization_settings.success_submitted'
            ),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
      resetAll();
    } else if (editOrganizationSettingsState.error && onFailedRequest) {
      onFailedRequest(
        editOrganizationSettingsState.error?.data?.message ||
          editOrganizationSettingsState.error?.data?.['status-code'] ||
          editOrganizationSettingsState.error?.status
      );
      form.reset();
    }
  }, [editOrganizationSettingsState.data, editOrganizationSettingsState.error]);

  return {
    form,
    fetching: editOrganizationSettingsState.isLoading,
    editOrganizationSettingsState,
    handleOnValidateData,
    resetAll,
  };
}
