import React, { useEffect, useMemo, useState } from 'react';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { gwsTenantListPath } from '@config/Routes/WebClientRoutes';
import M365TenantDetailsPage from '@pages/TenantDetailsPage/M365TenantDetailsPage';
import { QboBreadcrumbs, QboCard, QboTabs, QboTypography } from '@ui/Components';
import { setGWSTenantDetailState } from '@features/application/applicationSlice';
import GWSTenantDetailsPage from '@pages/TenantDetailsPage/GWSTenantDetailsPage';
import GwsUsersTenantPage from '@pages/TenantDetailsPage/GwsUsersTenant';
import GwsSharedDrivesTenantPage from '@pages/TenantDetailsPage/GwsSharedDrivesTenant';
import useUrlQuery from '@hooks/useUrlQuery';

export default function GWSTenantDetailsTab() {
  const { query } = useUrlQuery();
  const { gwsTenantDetailState } = useSelector((state) => state.application);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectTab, setSelectTab] = useState(0);
  const names = ['Details', 'GWS Users', 'GWS Shared Drive'];
  const { row } = gwsTenantDetailState;
  const original = row?.row?.original;

  const handleOnBreadcrumbClick = () => {
    dispatch(setGWSTenantDetailState({}));
  };

  const handleOnTabClick = (e, newValue) => {
    setSelectTab(newValue);
  };

  const current = useMemo(() => {
    return {
      id: original?.id || gwsTenantDetailState?.id || query.id,
      domain: original?.domain || gwsTenantDetailState?.domain || query.name,
      organizationOwnerId:
        original?.organizationOwnerId ||
        gwsTenantDetailState?.organizationOwnerId ||
        query.organizationOwnerId,
      orgName: original?.orgName || gwsTenantDetailState?.orgName || query.orgName,
      lastRequestId:
        original?.lastRequestId || gwsTenantDetailState?.lastRequestId || query.lastRequestId,
      lastSdDriveRequestId:
        original?.lastSdDriveRequestId ||
        gwsTenantDetailState?.lastSdDriveRequestId ||
        query.lastSdDriveRequestId,
    };
  }, [original, gwsTenantDetailState, query]);

  useEffect(() => {
    if (!current?.id || !current?.domain || !current?.organizationOwnerId || !current?.orgName) {
      navigate(gwsTenantListPath);
    }
  }, [current]);

  const yourComponent = (namesArray, selectTabState) => {
    if (namesArray[selectTabState] === 'Details') {
      return <GWSTenantDetailsPage gwsTenantCredentialID={current?.id} />;
    }
    if (namesArray[selectTabState] === 'GWS Users') {
      return (
        <GwsUsersTenantPage
          lastRequestId={current?.lastRequestId}
          organizationOwnerId={current?.organizationOwnerId ?? 0}
          credentialId={current?.id ?? 0}
          organizationName={current?.orgName ?? ''}
        />
      );
    }
    if (namesArray[selectTabState] === 'GWS Shared Drive') {
      return <GwsSharedDrivesTenantPage lastRequestId={current?.lastSdDriveRequestId} />;
    }
    return (
      <QboCard sx={{ marginTop: 2 }} isCentered isBordered>
        <HeaderPageBlock>
          The {namesArray[selectTabState] || namesArray[0]} feature will be available soon.
        </HeaderPageBlock>
      </QboCard>
    );
  };
  return (
    <>
      <HeaderPageBlock>GWS Tenant Information</HeaderPageBlock>
      <QboBreadcrumbs sx={{ marginBottom: 2 }}>
        <Link
          onClick={() => handleOnBreadcrumbClick()}
          to={gwsTenantListPath}
          style={{
            color: '#1c63d5',
            fontSize: '1.5rem',
            textDecoration: 'underline',
          }}>
          GWS Tenant
        </Link>
        <QboTypography color="text.primary" style={{ fontSize: '1.5rem' }}>
          GWS Tenant Information
        </QboTypography>
      </QboBreadcrumbs>

      <QboCard isMainWrapper>
        {current?.domain && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
            <QboTypography variant="h5" wrap sx={{ fontSize: 18 }}>
              {current?.domain}
            </QboTypography>
            <QboTypography variant="h5" sx={{ marginLeft: 1, fontSize: 16 }}>
              (ID: {current?.id})
            </QboTypography>
          </div>
        )}
        <div
          style={{
            overflowX: 'auto',
            marginRight: 5,
            display: 'flex',
            scrollbarWidth: 'thin',
            backgroundColor: '#c4e3f0',
          }}>
          <QboTabs tabIndex={selectTab} title={names} onChange={handleOnTabClick} />
        </div>
        {yourComponent(names, selectTab, current)}
      </QboCard>
    </>
  );
}
