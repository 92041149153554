import WebApiRoutes from '@config/Routes/WebApiRoutes';
import WebApiGeneralRoutes from '@config/Routes/WebApiGeneralRoutes';
import ServiceClientFactory from '@libs/ServiceClient';

const webClientApi = (accessToken) => {
  return new ServiceClientFactory({ baseUrl: WebApiRoutes.getBaseUrl(), accessToken }).create();
};

const PsaIntegrationService = {
  platformsApi: (paramsList = {}) => {
    const { accessToken, reset } = paramsList;
    const url = WebApiGeneralRoutes.apiV1Path('/psa-integration/platforms');
    const response = webClientApi(accessToken, reset).getRequest({
      url,
    });
    return response;
  },
  platformByIdApi: (paramsList = {}) => {
    const { accessToken, reset, platformId } = paramsList;
    const response = webClientApi(accessToken, reset).postRequest({
      url: WebApiGeneralRoutes.apiV1Path('/psa-integration/platform-by-id', { platformId }),
    });

    return response;
  },
  resellerPlatformsApi: (paramsList = {}) => {
    const { accessToken, reset, resellerId } = paramsList;
    const response = webClientApi(accessToken, reset).getRequest({
      url: WebApiGeneralRoutes.apiV1Path('/psa-integration/reseller-platforms', { resellerId }),
    });

    return response;
  },
};

export default PsaIntegrationService;
