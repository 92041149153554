import { createAsyncThunk } from '@reduxjs/toolkit';
import { toggleMFAAPI } from '@services/WebApiService';

const toggleMFAAsync = createAsyncThunk('toggleMFA/toggleMFAAsync', async (paramsList) => {
  try {
    const data = await toggleMFAAPI(paramsList);
    return data;
  } catch (error) {
    return { error: error.response };
  }
});

const { pending, fulfilled, rejected } = toggleMFAAsync;

const toggleMFAExtraReducers = {
  [pending]: (state) => {
    state.toggleMFAState.fetching = true;
  },
  [rejected]: (state) => {
    state.toggleMFAState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.toggleMFAState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.toggleMFAState.status = action?.payload?.status;
      state.toggleMFAState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.toggleMFAState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.toggleMFAState.message = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.toggleMFAState.status = action?.payload?.error?.status;
      state.toggleMFAState.message = statusMessage?.title || statusMessage?.message;
      state.toggleMFAState.error = action.payload.error;
    } else {
      state.toggleMFAState.success = true;
      state.toggleMFAState.serviceType = action.payload.data.data.serviceTypeCode;
    }
  },
};

export { toggleMFAAsync, toggleMFAExtraReducers };
