import { ITBox, QboAdvanceTable, QboCard, QboTypography } from '@ui/Components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { useWorkerJobItemsQuery } from '@services/WebApiService/SetupHostedExchange';
import { WORKER_JOB_ITEMS_HEADERS } from './constants/toolsRequestHistoryConstants';

const initialPagination = { pageIndex: 0, pageSize: 10 };

export default function WorkerJobItems({ jobId }) {
  const { t } = useTranslation();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [pagination, setPagination] = useState(initialPagination);

  const { data: response, error, isLoading } = useWorkerJobItemsQuery({ accessToken, jobId });
  return (
    <QboCard>
      <ITBox className="SystemStatusSection__wrapper" direction="row">
        <QboTypography style={{ margin: 16, fontSize: '2rem' }}>
          {t('tools_request_status.setup_hosted_exchange.title', { jobId })}
        </QboTypography>
      </ITBox>

      <QboAdvanceTable
        isLoading={isLoading}
        enableTopToolBar={false}
        headers={WORKER_JOB_ITEMS_HEADERS}
        rows={response?.data}
        pagination={response?.pagination}
        setPagination={setPagination}
        rowsCount={response?.pagination?.TotalCount}
      />
    </QboCard>
  );
}

WorkerJobItems.propTypes = {
  jobId: PropTypes.number,
};

WorkerJobItems.defaultProps = {
  jobId: null,
};
