const { createSlice } = require('@reduxjs/toolkit');
const {
  validateToggleMFAExtraReducers,
  validateToggleMFAAsync,
} = require('./serviceActions/validateToggleMFAAsync');

const initialState = {
  validateToggleMFAState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
    validationResult: null,
    serviceType: null,
  },
};

const validateToggleMFASlice = createSlice({
  name: 'validateToggleMFA',
  initialState,
  reducers: {
    resetValidateToggleMFAState(state) {
      state.validateToggleMFAState = initialState.validateToggleMFAState;
    },
  },
  extraReducers: {
    ...validateToggleMFAExtraReducers,
  },
});

export { validateToggleMFAAsync };
export const { resetValidateToggleMFAState } = validateToggleMFASlice.actions;
export default validateToggleMFASlice.reducer;
