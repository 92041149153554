import { createSlice } from '@reduxjs/toolkit';
import {
  downloadRestoreSkippedItemsCsvAsync,
  downloadRestoreSkippedItemsCsvExtraReducers,
} from './serviceActions/downloadRestoreSkippedItemsCsvAsync';

const initialState = {
  downloadRestoreSkippedItemsCsvState: {
    fetching: false,
    success: false,
    statusCode: null,
    data: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const downloadRestoreSkippedItemsCsvSlice = createSlice({
  name: 'downloadRestoreSkippedItemsCsv',
  initialState,
  reducers: {
    resetDownloadRestoreSkippedItemsCsvState(state) {
      state.downloadRestoreSkippedItemsCsvState = initialState.downloadRestoreSkippedItemsCsvState;
    },
  },
  extraReducers: {
    ...downloadRestoreSkippedItemsCsvExtraReducers,
  },
});

export { downloadRestoreSkippedItemsCsvAsync };
export const { resetDownloadRestoreSkippedItemsCsvState } =
  downloadRestoreSkippedItemsCsvSlice.actions;
export default downloadRestoreSkippedItemsCsvSlice.reducer;
