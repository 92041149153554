import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { QboTextField } from '.';

const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

function convertToArray(value) {
  if (Array.isArray(value)) return value;
  if (typeof value === 'string') {
    try {
      const parseResult = JSON.parse(value);
      return Array.isArray(parseResult) ? parseResult : [];
    } catch {
      return [];
    }
  }
  return [];
}

export default function QboChipCollection({ value, editting, onChange, isEmail }) {
  const items = useMemo(() => convertToArray(value), [value]);
  const [textInput, setTextInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleAdd = (newValue) => {
    if (isEmail && !emailRegex.test(newValue)) {
      setErrorMessage('Invalid email address.');
      return;
    }
    setErrorMessage('');
    setTextInput('');
    if (newValue) {
      onChange([...items, newValue]);
    }
  };

  const handleRemove = (index) => {
    onChange(items.filter((_, i) => i !== index));
  };

  const handleInputEnter = (e) => {
    if (e.key === 'Enter') {
      handleAdd(textInput);
    }
  };

  const addButton = useMemo(
    () => (
      <InputAdornment
        className="QboChipCollection__add-icon"
        position="end"
        onClick={() => handleAdd(textInput)}>
        <AddIcon />
      </InputAdornment>
    ),
    [textInput]
  );

  return (
    <div className="QboChipCollection">
      <div className="QboChipCollection__chips">
        {items.map((item, index) =>
          editting ? (
            <Chip key={Math.random()} label={item} onDelete={() => handleRemove(index)} />
          ) : (
            <Chip key={Math.random()} label={item} />
          )
        )}
      </div>
      {editting && (
        <QboTextField
          value={textInput}
          onChange={(e) => setTextInput(e.currentTarget.value)}
          onKeyUp={handleInputEnter}
          fullWidth
          endAdornment={addButton}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
}

QboChipCollection.propTypes = {
  value: PropTypes.oneOfType(PropTypes.string, PropTypes.arrayOf(PropTypes.string)),
  editting: PropTypes.bool,
  onChange: PropTypes.func,
  isEmail: PropTypes.bool,
};

QboChipCollection.defaultProps = {
  value: [],
  editting: false,
  onChange: () => {},
  isEmail: false,
};
