const { createAsyncThunk } = require('@reduxjs/toolkit');
const { validateToggleMFAApi } = require('@services/WebApiService');

const validateToggleMFAAsync = createAsyncThunk(
  'UpdateMFA/validateToggleMFA',
  async (paramsList) => {
    try {
      const data = await validateToggleMFAApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = validateToggleMFAAsync;

const validateToggleMFAExtraReducers = {
  [pending]: (state) => {
    state.validateToggleMFAState.fetching = true;
  },
  [rejected]: (state) => {
    state.validateToggleMFAState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.validateToggleMFAState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.validateToggleMFAState.status = action?.payload?.status;
      state.validateToggleMFAState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.validateToggleMFAState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.validateToggleMFAState.message = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.validateToggleMFAState.status = action?.payload?.error?.status;
      state.validateToggleMFAState.message = statusMessage?.title || statusMessage?.message;
      state.validateToggleMFAState.error = action.payload.error;
    } else {
      state.validateToggleMFAState.success = true;
      state.validateToggleMFAState.validationResult = action?.payload?.data?.data;
      state.validateToggleMFAState.serviceType = 24;
    }
  },
};

export { validateToggleMFAAsync, validateToggleMFAExtraReducers };
