import { constant } from '@config/BaseSetting';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import { showFixedAlert } from '@features/application/applicationSlice';
import {
  changeSubscriptionAsync,
  resetChangeSubscriptionState,
} from '@features/changeSubscription/changeSubscriptionSlice';
import useForm from '@hooks/useForm';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

export default function useChangeSubscription({ organization, closeModal, isFinance }) {
  const formSchema = useMemo(() => {
    return yup.object().shape({
      supportTicket: isFinance
        ? yup.string().default('')
        : yup.string().required('Support Ticket is required').default(''),
      escalationTicket: yup.string().default(''),
      description: yup.string().default(''),
      planId: yup.number().not([0], 'Please select a plan').default(0),
      seatCount: yup
        .string()
        .matches(/^(?:\d+)?$/, 'Seat count must be a number')
        .not(['0'], 'Seat count cannot be 0')
        .default(''),
    });
  }, [isFinance]);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { changeSubscriptionState } = useSelector((state) => state.changeSubscription);
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const form = useForm(formSchema);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (form.isValid) {
      const seatCount = parseInt(form.values.seatCount, 10);
      const parameters = {
        'support-ticket': form.values.supportTicket,
        'escalation-ticket': form.values.escalationTicket,
        description: form.values.description,
        'org-owner-id': organization.organizationOwnerId,
        'plan-id': form.values.planId,
        'seat-count': Number.isNaN(seatCount) ? null : seatCount,
      };
      dispatch(
        changeSubscriptionAsync({
          accessTokenSet,
          reset: true,
          parameters,
        })
      );
    }
  }, [form.isValid]);

  const resetAll = () => {
    dispatch(resetChangeSubscriptionState());
    setErrorMessage('');
    form.reset();
  };

  const handleClose = () => {
    resetAll();
    closeModal();
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    form.validate();
  };

  useEffect(() => {
    if (changeSubscriptionState.statusCode === 200) {
      if (changeSubscriptionState.serviceType) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': changeSubscriptionState.serviceType,
        });
        dispatch(
          showFixedAlert({
            descriptionHtml: t(
              'organization_list_page.change_subscription.success_submitted_with_link',
              {
                link,
              }
            ),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('organization_list_page.change_subscription.success_submitted'),
          })
        );
      }
      resetAll();
      handleClose();
    } else if (changeSubscriptionState.error?.data?.message) {
      dispatch(resetChangeSubscriptionState());
      form.reset(true);
      setErrorMessage(changeSubscriptionState.error?.data?.message);
    }
  }, [changeSubscriptionState.statusCode]);

  return {
    changeSubscriptionState,
    errorMessage,
    form,
    handleSubmit,
    handleClose,
  };
}
