import { createSlice } from '@reduxjs/toolkit';
import { toggleMFAAsync, toggleMFAExtraReducers } from './serviceActions/toggleMFAAsync';

const initialState = {
  toggleMFAState: {
    fetching: false,
    status: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    toggleMFACorsErrorMessage: null,
    serviceType: null,
  },
};

const toggleMFASlice = createSlice({
  name: 'toggleMFA',
  initialState,
  reducers: {
    resetToggleMFAState(state) {
      state.toggleMFAState = initialState.toggleMFAState;
    },
  },
  extraReducers: {
    ...toggleMFAExtraReducers,
  },
});

export { toggleMFAAsync };

export const { resetToggleMFAState } = toggleMFASlice.actions;
export default toggleMFASlice.reducer;
