import {
  ITBox,
  QboAlert,
  QboFormGroup,
  QboModal,
  QboSimpleDataTable,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { FormControl, Grid, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OrganizationFilterSection from '@ui/Sections/Shared/OrganizationFilterSection';
import useSetupHostedExchage from './pagehooks/useSetupHostedExchage';

function SetupHostedExchageModal({ closeModal, showModal, title, children }) {
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState();

  const {
    form,
    isLoading,
    validAdmin,
    validMailbox,
    parseAdminResult,
    parseMailboxResult,
    handleOnValidateData,
    handleAdminOnFileUpload,
    handleMailboxOnFileUpload,
    resetAll,
    disabled,
  } = useSetupHostedExchage({
    onSuccessRequest: closeModal,
    onFailedRequest: setErrorMessage,
  });
  const { t } = useTranslation();

  const closeAndReset = () => {
    setErrorMessage(null);
    closeModal();
    resetAll();
  };

  const SetupTypes = {
    setup_admin: 'Setup Hosted Exchange Admin',
    setup_mailbox: 'Setup Hosted Exchange Mailbox',
    bulk_setup_admin: 'Bulk Setup Hosted Exchange Admin',
    bulk_setup_mailbox: 'Bulk Setup Hosted Exchange Mailbox',
  };

  const CREATE_ADMIN_HEADERS = [
    { label: 'ID', columnName: 'org_owner_id' },
    { label: 'Organization Name', columnName: 'org_name' },
    { label: 'EWS Url', columnName: 'exchange_ews_url' },
    { label: 'Email', columnName: 'email' },
    { label: 'Password', columnName: 'password' },
  ];

  const CREATE_MAILBOX_HEADERS = [
    { label: 'ID', columnName: 'org_owner_id' },
    { label: 'Organization Name', columnName: 'org_name' },
    { label: 'Email', columnName: 'email' },
  ];

  return (
    <QboModal
      onClose={closeAndReset}
      open={showModal}
      title={title}
      showCloseIcon
      preventOutsideClick>
      <ITBox direction="TransferSubscriptionFormPage__wrapper column">
        <QboFormGroup
          loadingButton={isLoading}
          buttonDisabled={
            ['bulk_setup_admin', 'bulk_setup_mailbox'].includes(form.setupTypeAttributes.value) &&
            disabled
          }
          onSubmit={(e) => {
            setErrorMessage(null);
            handleOnValidateData(e);
          }}
          withGenerateAndResetButton
          handleOnResetButton={closeAndReset}
          secondButtonLabel="Cancel"
          firstButtonLabel="Submit">
          {errorMessage && (
            <QboAlert
              variant="outlined"
              type="error"
              style={{
                // border: '1px solid #ebebeb',
                background: '#fdeded',
                fontSize: '10px !important',
              }}>
              <p>
                {t('partner_list_page.bulk_create_organization.error', { error: errorMessage })}
              </p>
            </QboAlert>
          )}
          {children}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboTextField
                id="supportTicket"
                required
                placeholder="Support Ticket"
                label="Support Ticket"
                fullWidth
                value={form.supportTicketAttributes.value}
                errorMessage={form.supportTicketAttributes.errorMessage}
                inputProps={{ maxLength: 225 }}
                onChange={(e) => {
                  form.supportTicketAttributes.handleChange(e.currentTarget.value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <QboTextField
                id="escalationTicket"
                placeholder="Escalation Ticket"
                label="Escalation Ticket"
                fullWidth
                value={form.escalationTicketAttributes.value}
                onChange={(e) =>
                  form.escalationTicketAttributes.handleChange(e.currentTarget.value)
                }
                inputProps={{ maxLength: 225 }}
              />
            </Grid>
          </Grid>
          <QboTextField
            id="description"
            placeholder="Description"
            label="Description"
            fullWidth
            value={form.descriptionAttributes.value}
            onChange={(e) => form.descriptionAttributes.handleChange(e.currentTarget.value)}
            inputProps={{ maxLength: 225 }}
          />
          <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
            <div className="QboTextField__label" id="transferType">
              Setup Type
              <span style={{ color: 'red' }}> *</span>
            </div>
            <TextField
              value={form.setupTypeAttributes.value}
              select
              onChange={(e) => form.setupTypeAttributes.handleChange(e.target.value)}>
              {Object.keys(SetupTypes).map((option) => (
                <MenuItem key={option} value={option}>
                  {SetupTypes[option]}
                </MenuItem>
              ))}
            </TextField>
            {form.setupTypeAttributes.errorMessage && (
              <span className="QboTextField__error-text">
                <ErrorIcon />
                {` ${form.setupTypeAttributes.errorMessage}`}
              </span>
            )}
          </FormControl>
          {['setup_admin', 'setup_mailbox'].includes(form.setupTypeAttributes.value) && (
            <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
              <div className="QboTextField__label" id="transferType">
                Organization
                <span style={{ color: 'red' }}> *</span>
              </div>
              <OrganizationFilterSection
                label=""
                value={form.organizationIdAttributes.value}
                marginRightValue={2}
                buttonWidth="100%"
                inputSize={null}
                defaultValue={null}
                handleChange={(value) => {
                  form.organizationIdAttributes.handleChange(value);
                }}
              />
              {form.organizationIdAttributes.errorMessage && (
                <span className="QboTextField__error-text">
                  <ErrorIcon />
                  {` ${form.organizationIdAttributes.errorMessage}`}
                </span>
              )}
            </FormControl>
          )}
          {['setup_admin', 'setup_mailbox'].includes(form.setupTypeAttributes.value) && (
            <QboTextField
              id="email"
              placeholder="Email"
              label="Email"
              required
              fullWidth
              value={form.emailAttributes.value}
              errorMessage={form.emailAttributes.errorMessage}
              onChange={(e) => form.emailAttributes.handleChange(e.currentTarget.value)}
              inputProps={{ maxLength: 225 }}
            />
          )}
          {form.setupTypeAttributes.value === 'setup_admin' && (
            <>
              <QboTextField
                id="ewsUrl"
                placeholder="Exchange EWS URL"
                label="Exchange EWS URL"
                required
                fullWidth
                value={form.ewsUrlAttributes.value}
                errorMessage={form.ewsUrlAttributes.errorMessage}
                onChange={(e) => form.ewsUrlAttributes.handleChange(e.currentTarget.value)}
                inputProps={{ maxLength: 225 }}
              />
              <QboTextField
                id="password"
                placeholder="Password"
                label="Password"
                required
                fullWidth
                value={form.passwordAttributes.value}
                errorMessage={form.passwordAttributes.errorMessage}
                onChange={(e) => form.passwordAttributes.handleChange(e.currentTarget.value)}
                inputProps={{ maxLength: 225 }}
              />
            </>
          )}
          {form.setupTypeAttributes.value === 'bulk_setup_admin' && (
            <QboTextField
              id="csvAdminFile"
              placeholder={t('bulk_purge.labels.csv_file')}
              label={t('partner_list_page.bulk_create_organization.labels.csv_file')}
              type="file"
              fullWidth
              errorMessage={
                parseAdminResult.results?.length ? null : form.csvAdminValidAttributes.errorMessage
              }
              inputRef={fileInputRef}
              onChange={handleAdminOnFileUpload}
            />
          )}

          {form.setupTypeAttributes.value === 'bulk_setup_admin' &&
            parseAdminResult.errors?.length && (
              <QboAlert
                variant="outlined"
                type="error"
                style={{
                  background: '#fdeded',
                  fontSize: '10px !important',
                  marginTop: '20px',
                }}>
                {parseAdminResult.errors.map((e) => (
                  <p>{e}</p>
                ))}
              </QboAlert>
            )}
          {form.setupTypeAttributes.value === 'bulk_setup_admin' &&
            parseAdminResult.results?.length && (
              <>
                <QboTypography variant="h5">Valid Organization</QboTypography>
                <QboSimpleDataTable header={CREATE_ADMIN_HEADERS} rows={validAdmin} />
                <QboTypography variant="h5">Invalid Organization</QboTypography>
                <QboSimpleDataTable
                  header={CREATE_ADMIN_HEADERS}
                  rows={parseAdminResult.results.filter(
                    (r) => !validAdmin.some((v) => r.org_owner_id === v.org_owner_id)
                  )}
                />
              </>
            )}
          {form.setupTypeAttributes.value === 'bulk_setup_mailbox' && (
            <QboTextField
              id="csvMailboxFile"
              placeholder={t('bulk_purge.labels.csv_file')}
              label={t('partner_list_page.bulk_create_organization.labels.csv_file')}
              type="file"
              fullWidth
              errorMessage={
                parseMailboxResult.results?.length
                  ? null
                  : form.csvMailboxValidAttributes.errorMessage
              }
              inputRef={fileInputRef}
              onChange={handleMailboxOnFileUpload}
            />
          )}
          {form.setupTypeAttributes.value === 'bulk_setup_mailbox' &&
            parseMailboxResult.errors?.length && (
              <QboAlert
                variant="outlined"
                type="error"
                style={{
                  background: '#fdeded',
                  fontSize: '10px !important',
                  marginTop: '20px',
                }}>
                {parseMailboxResult.errors.map((e) => (
                  <p>{e}</p>
                ))}
              </QboAlert>
            )}
          {form.setupTypeAttributes.value === 'bulk_setup_mailbox' &&
            parseMailboxResult.results?.length && (
              <>
                <QboTypography variant="h5">Valid Organization</QboTypography>
                <QboSimpleDataTable header={CREATE_MAILBOX_HEADERS} rows={validMailbox} />
                <QboTypography variant="h5">Invalid Organization</QboTypography>
                <QboSimpleDataTable
                  header={CREATE_MAILBOX_HEADERS}
                  rows={parseMailboxResult.results.filter(
                    (r) => !validMailbox.some((v) => r.org_owner_id === v.org_owner_id)
                  )}
                />
              </>
            )}
        </QboFormGroup>
      </ITBox>
    </QboModal>
  );
}

SetupHostedExchageModal.propTypes = {
  closeModal: PropTypes.func,
  showModal: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

SetupHostedExchageModal.defaultProps = {
  closeModal: () => {},
  showModal: false,
  title: '',
  children: '',
};

export default SetupHostedExchageModal;
