import { Grid } from '@mui/material';
import {
  ITBox,
  QboAlert,
  QboFormGroup,
  QboLabelGroup,
  QboModal,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useEditOrganizationSettings from './pageHooks/useEditOrganizationSettings';

export default function EditOrganizationSettingsModal({
  onSuccess,
  closeModal,
  showModal,
  organizationOwnerId,
  organizationName,
  editData,
}) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState();
  const { form, fetching, handleOnValidateData, resetAll } = useEditOrganizationSettings({
    organizationOwnerId,
    editData,
    onSuccessRequest: onSuccess,
    onFailedRequest: setErrorMessage,
  });

  const closeAndReset = () => {
    resetAll();
    setErrorMessage(null);
    closeModal();
  };

  return (
    <QboModal
      open={showModal}
      onClose={closeAndReset}
      title={t('organization_details_page.edit_organization_settings.title')}
      showCloseIcon
      preventOutsideClick>
      <ITBox direction="TransferSubscriptionFormPage__wrapper column">
        <QboFormGroup
          loadingButton={fetching}
          onSubmit={(e) => {
            setErrorMessage(null);
            handleOnValidateData(e);
          }}
          withGenerateAndResetButton
          handleOnResetButton={closeAndReset}
          secondButtonLabel="Cancel"
          firstButtonLabel="Submit">
          {errorMessage && (
            <QboAlert
              variant="outlined"
              type="error"
              style={{
                background: '#fdeded',
                fontSize: '10px !important',
              }}>
              <p>
                {t('organization_details_page.edit_organization_settings.error', {
                  error: errorMessage,
                })}
              </p>
            </QboAlert>
          )}
          <QboAlert
            variant="outlined"
            type="info"
            style={{
              border: '1px solid #ebebeb',
              background: '#f3f3f3',
              fontSize: '10px !important',
              marginTop: '20px',
            }}>
            <QboTypography
              noWrap={false}
              style={{ fontSize: '13px', color: '#767676', whiteSpace: 'pre-line' }}>
              {t('organization_details_page.edit_organization_settings.confirmation')}
            </QboTypography>
          </QboAlert>
          <ITBox
            className="TransferSubresellerPage__information-box"
            direction="column"
            sx={{ marginBottom: '20px' }}>
            <QboLabelGroup title="Organization Owner ID" description={organizationOwnerId || '-'} />
            <QboLabelGroup title="Organization Name" description={organizationName || '-'} />
          </ITBox>
          <div style={{ marginBottom: '20px' }}>
            <QboTypography sx={{ fontSize: 13, fontWeight: 700 }}>
              {t('organization_details_page.edit_organization_settings.label.settings')}
            </QboTypography>
            {editData &&
              editData.map((e) => (
                <QboCardSingleList
                  subHeadingText={e.subHeadingText}
                  leftContent={e.label ?? e.key}
                  rightContent={e.value.toString()}
                />
              ))}
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <QboTextField
                id="supportTicket"
                placeholder="Support Ticket"
                label="Support Ticket"
                fullWidth
                value={form.values.supportTicket}
                onChange={(e) => {
                  form.change('supportTicket', e.currentTarget.value);
                }}
                inputProps={{ maxLength: 225 }}
              />
            </Grid>
            <Grid item xs={6}>
              <QboTextField
                id="escalationTicket"
                placeholder="Escalation Ticket"
                label="Escalation Ticket"
                fullWidth
                value={form.values.escalationTicket}
                onChange={(e) => {
                  form.change('escalationTicket', e.currentTarget.value);
                }}
                inputProps={{ maxLength: 225 }}
              />
            </Grid>
          </Grid>
          <QboTextField
            id="description"
            placeholder="Description"
            label="Description"
            fullWidth
            value={form.values.description}
            onChange={(e) => {
              form.change('description', e.currentTarget.value);
            }}
            inputProps={{ maxLength: 225 }}
          />
        </QboFormGroup>
      </ITBox>
    </QboModal>
  );
}

EditOrganizationSettingsModal.propTypes = {
  onSuccess: PropTypes.func,
  closeModal: PropTypes.func,
  showModal: PropTypes.bool,
  organizationOwnerId: PropTypes.number,
  organizationName: PropTypes.string,
  editData: PropTypes.arrayOf(PropTypes.object),
};

EditOrganizationSettingsModal.defaultProps = {
  onSuccess: () => {},
  closeModal: () => {},
  showModal: false,
  organizationOwnerId: 0,
  organizationName: '',
  editData: [],
};
