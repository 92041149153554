import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import {
  ITBox,
  QboButton,
  QboCard,
  QboLink,
  QboSimpleDataTable,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationsListAsync } from '@features/organizationList/organizationsListSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import {
  ORGANIZATION_PRICE,
  PLAN_PRICE,
  PRICE_GUIDE,
} from '@pages/PlansListPage/pageHooks/useValidation';
import { constant } from '@config/BaseSetting';

function TableConfirmation({ title, list }) {
  if (list.length < 1) return <h3>There is no valid id can be applied to change the price</h3>;
  return (
    <div style={{ marginBottom: '50px' }}>
      <h3>{title}</h3>
      <QboSimpleDataTable
        sx={{ width: '100%' }}
        rows={list}
        header={[
          {
            id: 1,
            label: 'ID',
            columnName: 'organizationOwnerId',
          },
          {
            id: 2,
            label: 'Email',
            columnName: 'ownerEmail',
          },
          {
            id: 3,
            label: 'Organization Name',
            columnName: 'orgName',
          },
        ]}
      />
    </div>
  );
}

TableConfirmation.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
};

TableConfirmation.defaultProps = {
  title: '',
  list: [],
};

function TextWithLabel({ title, description, color }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: 7,
        borderBottom: '1px solid #d7dbe3',
        paddingBottom: '10px',
      }}>
      <QboTypography sx={{ fontSize: 13, fontWeight: 700 }}>{title}</QboTypography>
      <QboTypography wrap sx={{ fontSize: 13, color: { color } }}>
        &nbsp;{description}
      </QboTypography>
    </div>
  );
}

TextWithLabel.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
};

TextWithLabel.defaultProps = {
  title: '',
  color: '',
  description: '',
};

export default function SpecialPricingConfirmationSection({
  onSubmit,
  onBack,
  onCloseModal,
  formData,
  detailState,
  validList,
  setValidList,
  inValidList,
  setInValidList,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { fetching: isLoading, data } = detailState;
  const organizationIds = formData.accountIdsAttributes.value
    .split(',')
    .map((str) => parseInt(str, 10));

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const FetchOrganizationsListAsync = () => {
    const params = {
      searchById: 0,
      fullTextSearch: '',
      reseller: formData.partnerIdAttributes.value,
      pageNumber: 1,
      pageSize: 250,
      filter: [{ field: 'filter_Idplan', value: formData.planIdAttributes.value.toString() }],
      orderBy: 'OrganizationId',
    };
    dispatch(
      getOrganizationsListAsync({
        accessToken,
        reset: true,
        ...params,
      })
    );
  };
  const { getOrganizationsListState } = useSelector((state) => state.organizationsList);
  const { organizationsList, fetching } = getOrganizationsListState;

  useEffect(() => {
    if (formData.requestTypeAttributes.value === ORGANIZATION_PRICE) {
      FetchOrganizationsListAsync();
    }
  }, [formData.requestTypeAttributes.value]);

  useEffect(() => {
    setValidList(organizationsList.filter((l) => organizationIds.includes(l.organizationOwnerId)));
  }, [organizationsList]);

  useEffect(() => {
    setInValidList(
      organizationIds.filter((id) => {
        const validIds = validList.map((e) => e.organizationOwnerId);
        return !validIds.includes(id);
      })
    );
  }, [validList]);

  const renderContent = () => {
    return (
      <QboCard
        className="TransferSubresellerPage__information-wrapper"
        titleCard="Special Pricing Information"
        isBordered>
        {fetching && renderLoading()}
        {!fetching && (
          <ITBox className="TransferSubresellerPage__information-box" direction="column">
            {fetching ? (
              renderLoading()
            ) : (
              <>
                {!data?.errorDescription && !fetching && (
                  <>
                    <TextWithLabel
                      title={t('plans_list_page.labels.support_ticket')}
                      description={formData.supportTicketAttributes.value || '-'}
                    />
                    <TextWithLabel
                      title={t('plans_list_page.labels.escalation_ticket')}
                      description={formData.escalationTicketAttributes.value || '-'}
                    />
                    <TextWithLabel
                      title={t('plans_list_page.labels.description')}
                      description={formData.descriptionAttributes.value || '-'}
                    />
                    <TextWithLabel
                      title={t('plans_list_page.labels.partner_id')}
                      description={`${formData.partnerIdAttributes.value} - ${data.name}` || '-'}
                    />
                    {formData.requestTypeAttributes.value === PRICE_GUIDE && (
                      <>
                        <TextWithLabel
                          title={t('plans_list_page.labels.backup_price')}
                          description={formData.newBackupPriceAttributes.value || '-'}
                        />
                        <TextWithLabel
                          title={t('plans_list_page.labels.archive_price')}
                          description={formData.newArchivePriceAttributes.value || '-'}
                        />
                        <TextWithLabel
                          title={t('plans_list_page.labels.qbo_price')}
                          description={formData.newQboPriceAttributes.value || '-'}
                        />
                        <TextWithLabel
                          title={t('plans_list_page.labels.nfp_backup_price')}
                          description={formData.newNfpBackupPriceAttributes.value || '-'}
                        />
                        <TextWithLabel
                          title={t('plans_list_page.labels.nfp_archive_price')}
                          description={formData.newNfpArchivePriceAttributes.value || '-'}
                        />
                        <TextWithLabel
                          title={t('plans_list_page.labels.entra_backup_price')}
                          description={formData.newEntraBackupPriceAttributes.value || '-'}
                        />
                        <TextWithLabel
                          title={t('plans_list_page.labels.entra_archive_price')}
                          description={formData.newEntraArchivePriceAttributes.value || '-'}
                        />
                        <TextWithLabel
                          title={t('plans_list_page.labels.nfp_entra_backup_price')}
                          description={formData.newNfpEntraBackupPriceAttributes.value || '-'}
                        />
                        <TextWithLabel
                          title={t('plans_list_page.labels.nfp_entra_archive_price')}
                          description={formData.newNfpEntraArchivePriceAttributes.value || '-'}
                        />
                      </>
                    )}
                    {[PLAN_PRICE, ORGANIZATION_PRICE].includes(
                      formData.requestTypeAttributes.value
                    ) && (
                      <>
                        <TextWithLabel
                          title={t('plans_list_page.labels.plan_id')}
                          description={formData.planIdAttributes.value || '-'}
                        />
                        <TextWithLabel
                          title={t('plans_list_page.labels.new_plan_price')}
                          description={formData.newPlanPriceAttributes.value || '-'}
                        />
                      </>
                    )}
                    {formData.requestTypeAttributes.value === ORGANIZATION_PRICE && (
                      <>
                        <TableConfirmation
                          list={validList}
                          title={`The change price can only apply to ${
                            validList.length
                          } organization${validList.length > 1 ? 's' : ''}`}
                        />
                        <TextWithLabel
                          title="Invalid Organization Owner Id"
                          description={inValidList.join(', ') || '-'}
                        />
                      </>
                    )}
                  </>
                )}

                {data?.errorDescription && (
                  <TextWithLabel
                    color="#D63301"
                    title="Error Description"
                    description={data?.errorDescription}
                  />
                )}
              </>
            )}
          </ITBox>
        )}
      </QboCard>
    );
  };

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      {isLoading && renderLoading()}
      {!isLoading && (
        <ITBox className="TransferSubresellerPage__information-box" direction="column">
          {isLoading ? renderLoading() : renderContent()}
        </ITBox>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <QboButton
          sx={{ width: '100px !important' }}
          variant="outlined"
          color="secondary"
          onClick={() => onBack()}>
          Back
        </QboButton>
        <QboButton
          sx={{ width: '150px !important', justifyContent: 'end' }}
          disabled={
            formData.requestTypeAttributes.value === ORGANIZATION_PRICE && validList.length < 1
          }
          variant="contained"
          onClick={onSubmit}>
          Submit Request
        </QboButton>
      </div>
    </ITBox>
  );
}

SpecialPricingConfirmationSection.propTypes = {
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
  onCloseModal: PropTypes.func,
  formData: PropTypes.object,
  detailState: PropTypes.object,
  validList: PropTypes.array,
  setValidList: PropTypes.func,
  inValidList: PropTypes.array,
  setInValidList: PropTypes.func,
};

SpecialPricingConfirmationSection.defaultProps = {
  onSubmit: () => {},
  onBack: () => {},
  onCloseModal: () => {},
  formData: {},
  detailState: {},
  validList: [],
  setValidList: () => {},
  inValidList: [],
  setInValidList: () => {},
};
