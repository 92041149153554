import { createAsyncThunk } from '@reduxjs/toolkit';
import { platformsApi } from '@services/WebApiService';

const getPlatformsAsync = createAsyncThunk('psaIntegration/getPlatforms', async (paramsList) => {
  try {
    const data = await platformsApi(paramsList);
    return data;
  } catch (error) {
    return error.response;
  }
});

const { pending, fulfilled, rejected } = getPlatformsAsync;

const getPlatformsExtraReducers = {
  [pending]: (state) => {
    state.getPlatformsState.fetching = true;
  },
  [rejected]: (state) => {
    state.getPlatformsState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.getPlatformsState.fetching = false;

    if (action?.payload?.status === 403) {
      state.getPlatformsState.forbiddenState = true;
      state.getPlatformsState.forbiddenErrorMessage = {
        heading: 'Forbidden Error',
        caption: 'You are not authorize to access this resource',
      };
    }

    if (!action?.payload) {
      state.getPlatformsState.corsErrorMessage =
        'Something went wrong while processing your request.';
    }

    if (action?.payload?.data?.data) {
      state.getPlatformsState.data = action?.payload?.data?.data;
      state.getPlatformsState.pagination = JSON.parse(action?.payload?.data?.pagination);
      state.getPlatformsState.success = true;
      state.getPlatformsState.statusCode = action?.payload?.data['status-code'];
    }

    if (action?.payload?.error) {
      state.getPlatformsState.error = action.payload.error;
    }
  },
};

export { getPlatformsAsync, getPlatformsExtraReducers };
