import React, { useEffect, useState } from 'react';
import { QboCard } from '@ui/Components';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import PropTypes from 'prop-types';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import OrganizationSettingsSection from '@ui/Sections/OrganizationInformationPage/OrganizationSettingsSection';
import EditOrganizationSettingsModal from '@ui/Sections/OrganizationInformationPage/EditOrganizationSettingsModal';
import useOrganizationInformationService from './pageHooks/useOrganizationInformationService';
import useDataService from './pageHooks/useDataService';

export default function OrganizationInformationPage(props) {
  const { resellerID } = props;
  const { getOrganizationInformationState } = useSelector((state) => state.organizationInformation);
  const { organizationInformation, fetching } = getOrganizationInformationState;
  const [editModalState, setEditModalState] = useState({});
  const { handleFetchOrganizationInformationAsync } = useOrganizationInformationService();
  const { organizationDetails } = useDataService();

  const handleEditSave = (editData) => {
    setEditModalState({
      editData,
      show: true,
    });
  };

  const handleCloseEditModal = () => {
    setEditModalState({});
  };

  const handleEditSuccess = () => {
    setEditModalState({});
    if (resellerID) {
      handleFetchOrganizationInformationAsync(resellerID);
    }
  };

  useEffect(() => {
    if (organizationInformation.length === 0) {
      handleFetchOrganizationInformationAsync(resellerID);
    }
  }, []);

  const valueHandler = (label, properties, valType) => {
    const responseData = organizationInformation[0];
    let value = ' ';

    if (responseData && Object.keys(responseData).length > 0) {
      if (label === 'API Authentication Token') {
        value = responseData[properties]?.substring(0, 10) || '-';
      } else {
        value = responseData[properties] || '-';
      }
    }

    return value;
  };

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const componentHandler = () => {
    let content = null;
    if (fetching) {
      content = renderLoading();
    } else if (organizationInformation[0] && Object.keys(organizationInformation[0]).length > 0) {
      content = (
        <>
          <QboCard
            className="QboCard__wrapper_search_text_highlight"
            sx={{
              scrollbarWidth: 'none !important',
              overflowY: 'scroll !important',
              height: '65vh',
            }}>
            <OrganizationSettingsSection
              data={organizationInformation[0] ?? {}}
              labels={organizationDetails}
              onSave={handleEditSave}
            />
          </QboCard>
          <EditOrganizationSettingsModal
            onSuccess={handleEditSuccess}
            closeModal={handleCloseEditModal}
            showModal={editModalState?.show ?? false}
            editData={editModalState?.editData ?? []}
            organizationOwnerId={organizationInformation[0]?.id}
            organizationName={organizationInformation[0]?.name}
          />
        </>
      );
    } else if (!fetching && !organizationInformation[0]) {
      content = <NoStatusSection />;
    }

    return content;
  };

  return componentHandler();
}

OrganizationInformationPage.propTypes = {
  resellerID: PropTypes.number,
};

OrganizationInformationPage.defaultProps = {
  resellerID: null,
};
