import { constant } from '@config/BaseSetting';
import { getPlatformsAsync } from '@features/psaIntegration/platformsSlice';
import { getResellerPlatformsAsync } from '@features/psaIntegration/resellerPlatformsSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function useIntegrationService(resellerId) {
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();
  const { getPlatformsState } = useSelector((s) => s.platforms);
  const { getResellerPlatformsState } = useSelector((s) => s.resellerPlatforms);

  useEffect(() => {
    dispatch(
      getPlatformsAsync({
        accessToken,
        reset: true,
        resellerId,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getResellerPlatformsAsync({
        accessToken,
        reset: true,
        resellerId,
      })
    );
  }, []);

  return { getPlatformsState, getResellerPlatformsState };
}
