import React from 'react';
import {
  m365TenantDetailPath,
  organizationInformationPath,
  resellersListPath,
} from '@config/Routes/WebClientRoutes';

export default function useDataService() {
  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };
  const clickableCoulmnList = [
    {
      id: 1,
      column: 'tenant',
      path: (row) => [
        m365TenantDetailPath,
        `id=${row.id || ''}&name=${row.domain || ''}&organizationOwnerId=${
          row.organizationOwnerId || ''
        }&orgName=${row.orgName || ''}`,
      ],
    },
    {
      id: 2,
      column: 'tenantPartnerName',
      path: (row) => [resellersListPath, `id=${row.partnerId}&name=${row.tenantPartnerName}`],
    },
    {
      id: 3,
      column: 'orgName',
      path: (row) => [
        organizationInformationPath,
        `id=${row.organizationOwnerId}&name=${row.orgName}`,
      ],
      tab: 0,
    },
    {
      id: 4,
      column: 'tenantParent',
      path: resellersListPath,
    },
  ];
  const mainCredentails = [
    { id: 1, value: 'true', title: 'True' },
    { id: 2, value: 'false', title: 'False' },
  ];
  const initialSetupStatus = [
    { id: 1, value: 'Not Ready', title: 'Not Ready' },
    { id: 2, value: 'In Progress', title: 'In Progress' },
    { id: 3, value: 'Completed', title: 'Completed' },
    { id: 4, value: 'Error', title: 'Error' },
  ];
  const mfaEnabled = [
    { id: 1, value: 'true', title: 'True' },
    { id: 2, value: 'false', title: 'False' },
  ];
  const adSync = [
    { id: 1, value: 'true', title: 'True' },
    { id: 2, value: 'false', title: 'False' },
  ];
  const customerTenantApp = [
    { id: 1, value: 'true', title: 'True' },
    { id: 2, value: 'false', title: 'False' },
  ];
  const backUpAdmin = [
    { id: 1, value: 'true', title: 'True' },
    { id: 2, value: 'false', title: 'False' },
  ];
  const spaFlow = [
    { id: 1, value: 'Manual', title: 'Manual' },
    { id: 2, value: 'Automatic', title: 'Automatic' },
  ];

  const deletedAtFilterValues = [
    { id: 1, value: 'true', title: 'Yes' },
    { id: 2, value: 'false', title: 'No' },
  ];
  const migratedSpaStatus = [
    { id: 1, value: 'In Progress', title: 'In Progress' },
    { id: 2, value: 'Completed', title: 'Completed' },
  ];
  const sortColumnNames = [
    { id: 1, value: 'id', title: 'Credential ID' },
    { id: 2, value: 'organization_id', title: 'Organization ID' },
    { id: 3, value: 'domain', title: 'Domain' },
    { id: 4, value: 'status', title: 'Status' },
  ];

  return {
    buttonStyle,
    mainCredentails,
    sortColumnNames,
    initialSetupStatus,
    backUpAdmin,
    spaFlow,
    migratedSpaStatus,
    mfaEnabled,
    adSync,
    customerTenantApp,
    deletedAtFilterValues,
    clickableCoulmnList,
  };
}
