import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Skeleton } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { QboSelect, QboTextField, QboTypography } from '.';
import QboChipCollection from './QboChipCollection';

const booleanOptions = [
  { id: 1, label: 'true' },
  { id: 2, label: 'false' },
];

const floatRegex = /^[0-9]*(\.[0-9]*)?$/;

export default function QboCardSingleList({
  leftContent,
  rightContent,
  children,
  className,
  loading,
  compact,
  subHeadingText,
  editable,
  value,
  valueType,
  onChange,
  options,
  defaultOption,
  errorMessage,
  ...rest
}) {
  const renderLeftContent = () => {
    if (!leftContent) return null;
    if (loading) {
      return (
        <Skeleton
          className="QboCardListItem__left-content"
          variant="circular"
          width={40}
          height={40}
        />
      );
    }

    return (
      <Grid
        sx={{
          backgroundColor: '#f2f0f0',
          padding: 1,
          border: '1px solid black',
          borderColor: '#ffffff',
          width: '40%',
        }}
        item
        className="QboCardListItem__left-content">
        {leftContent}
        {subHeadingText && (
          <QboTypography wrap={!false} sx={{ color: '#42526e', fontSize: '12px !important' }}>
            {subHeadingText}
          </QboTypography>
        )}
      </Grid>
    );
  };

  const renderInput = () => {
    if (options) {
      return (
        <QboSelect
          defaultValue={defaultOption}
          value={value}
          options={options}
          handleChange={(e) => onChange(e.target.value)}
        />
      );
    }
    if (valueType === 'jsonEmailArray') {
      return (
        <QboChipCollection
          value={value}
          onChange={(a) => onChange(JSON.stringify(a))}
          editting
          isEmail
        />
      );
    }
    if (valueType === 'date' || valueType === 'nextMonthDate') {
      const baseDate = dayjs().add(valueType === 'nextMonthDate' ? 1 : 0, 'month');
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['day']}
            slotProps={{ field: { shouldRespectLeadingZeros: true } }}
            value={baseDate.set('date', value)}
            defaultValue={baseDate.startOf('month')}
            maxDate={baseDate.endOf('month')}
            minDate={baseDate.startOf('month')}
            onChange={(d) => {
              onChange(d.date());
            }}
            sx={{ width: '100%' }}
          />
        </LocalizationProvider>
      );
    }
    if (valueType === 'boolean' || valueType === 'bool') {
      return (
        <QboSelect
          options={booleanOptions}
          value={value ? 1 : 2}
          handleChange={(e) => onChange(e.target.value !== 2)}
        />
      );
    }
    const isInt = valueType === 'int' || valueType === 'integer';
    const isFloat = valueType === 'double' || valueType === 'float';
    return (
      <QboTextField
        fullWidth
        type={isInt ? 'number' : 'text'}
        value={value}
        errorMessage={errorMessage}
        onChange={(e) => {
          const newValue = e.currentTarget.value;
          if (isInt) {
            onChange(parseInt(newValue, 10));
          } else if (isFloat) {
            if (floatRegex.test(newValue)) onChange(newValue);
          } else {
            onChange(newValue);
          }
        }}
      />
    );
  };

  const renderPreview = () => {
    if (valueType === 'jsonEmailArray') {
      return <QboChipCollection value={rightContent} isEmail />;
    }
    return (
      <QboTypography sx={{ overflow: 'hidden', wordWrap: 'break-word' }} wrap={!false}>
        {rightContent}
      </QboTypography>
    );
  };

  const renderRightContent = () => {
    if (!rightContent && !editable) return null;

    return (
      <Grid
        sx={{
          padding: 1,
          border: '1px solid black',
          borderColor: '#eef1f5e0',
          width: '60%',
          display: 'flex',
          alignItems: 'center',
        }}
        item
        className="QboCardListItem__right-content">
        {editable ? renderInput() : renderPreview()}
      </Grid>
    );
  };

  const attributes = {
    ...(loading ? { justifyContent: 'center', alignItems: 'center' } : {}),
  };

  return (
    <Grid container {...attributes} {...rest}>
      {renderLeftContent()}
      {renderRightContent()}
    </Grid>
  );
}

QboCardSingleList.propTypes = {
  leftContent: PropTypes.node,
  rightContent: PropTypes.node,
  className: PropTypes.string,
  subHeadingText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  loading: PropTypes.bool,
  compact: PropTypes.bool,
  editable: PropTypes.bool,
  value: PropTypes.any,
  valueType: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  defaultOption: PropTypes.string,
  errorMessage: PropTypes.string,
};

QboCardSingleList.defaultProps = {
  leftContent: null,
  rightContent: null,
  className: '',
  children: '',
  subHeadingText: '',
  loading: false,
  compact: false,
  editable: false,
  value: null,
  valueType: '',
  onChange: () => {},
  options: null,
  defaultOption: '',
  errorMessage: null,
};
