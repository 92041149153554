import useValidation from './useValidation';

function useForm() {
  const {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    partnerIdAttributes,
    requestTypeAttributes,
    newArchivePriceAttributes,
    newBackupPriceAttributes,
    newQboPriceAttributes,
    newNfpBackupPriceAttributes,
    newNfpArchivePriceAttributes,
    newEntraBackupPriceAttributes,
    newEntraArchivePriceAttributes,
    newNfpEntraBackupPriceAttributes,
    newNfpEntraArchivePriceAttributes,
    planIdAttributes,
    newPlanPriceAttributes,
    accountIdsAttributes,
    FileAttributes,
    resetForm,
    isValid,
    validateAll,
    requestTypes,
    invalidateForm,
  } = useValidation();

  const attributes = {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    partnerIdAttributes,
    requestTypeAttributes,
    newArchivePriceAttributes,
    newBackupPriceAttributes,
    newQboPriceAttributes,
    newNfpBackupPriceAttributes,
    newNfpArchivePriceAttributes,
    newEntraBackupPriceAttributes,
    newEntraArchivePriceAttributes,
    newNfpEntraBackupPriceAttributes,
    newNfpEntraArchivePriceAttributes,
    planIdAttributes,
    newPlanPriceAttributes,
    accountIdsAttributes,
    FileAttributes,
  };

  const onValidateData = (e) => {
    e.preventDefault();
    validateAll();
  };

  return {
    attributes,
    isValid,
    resetForm,
    onValidateData,
    requestTypes,
    invalidateForm,
  };
}

export default useForm;
