import { constant } from '@config/BaseSetting';
import { showFixedAlert } from '@features/application/applicationSlice';
import { getChangeSubscriptionPlansAsync } from '@features/changeSubscription/getChangeSubscriptionPlansSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export default function useChangeSubscriptionPlans({ organizationOwnerId, showModal, closeModal }) {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { getChangeSubscriptionPlansState } = useSelector(
    (state) => state.getChangeSubscriptionPlans
  );

  useEffect(() => {
    setOptions([]);
    if (!organizationOwnerId) return;
    dispatch(
      getChangeSubscriptionPlansAsync({
        accessTokenSet,
        reset: true,
        id: organizationOwnerId,
      })
    );
  }, [organizationOwnerId]);

  useEffect(() => {
    if (!showModal || !organizationOwnerId || getChangeSubscriptionPlansState.fetching) return;
    if (
      !getChangeSubscriptionPlansState.list ||
      getChangeSubscriptionPlansState.list.length === 0
    ) {
      dispatch(
        showFixedAlert({
          descriptionHtml: t(
            'organization_list_page.change_subscription.validations.no_available_plans'
          ),
          severity: 'error',
        })
      );
      closeModal();
    } else {
      const plans = getChangeSubscriptionPlansState.list.map((p) => {
        return {
          id: p?.id,
          label: `${p?.id} - ${p.planName} - ${p.planCurrency} ${p.planPrice?.toFixed(2)}`,
        };
      });
      setOptions([{ id: 0, label: 'Select a plan' }, ...plans]);
    }
  }, [getChangeSubscriptionPlansState.fetching]);

  return {
    fetching: getChangeSubscriptionPlansState.fetching,
    options,
  };
}
