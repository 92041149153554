import React, { useEffect, useState } from 'react';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  ITBox,
  QboAdvanceTable,
  QboButton,
  QboCard,
  QboFormGroup,
  QboTextField,
  QboTypography,
} from '@ui/Components';
import { Button } from '@mui/material';
import QboSortByButton from '@ui/Components/QboSortByButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useDataService from './pagehooks/useDataService';
import useHEXTenantService from './pagehooks/useHEXTenantService';
import SetupHostedExchageModal from './SetupHostedExchageModal';
import SetupHostedExhangeTemplates from './SetupHostedExhangeTemplates';

export default function HEXTenantPage(props) {
  const { organizationOwnerId, organizationOwnerIdProp, paginationProp } = props;
  const { t } = useTranslation();
  const { paginationState } = useSelector((state) => state.application);
  const { hexTenantPaginations } = paginationState;
  const { getPartnersFilterListState } = useSelector((state) => state.partnersFilterList);
  const { getOrganizationFilterListState } = useSelector((state) => state.organizationFilterList);
  const { getParentFilterListState } = useSelector((state) => state.parentFilterList);
  const { partnersFilterList } = getPartnersFilterListState;
  const { organizationFilterList } = getOrganizationFilterListState;
  const { parentFilterList } = getParentFilterListState;
  const {
    hexTenantHeaders,
    buttonStyle,
    sslData,
    sortColumnNames,
    clickableColumn,
    initialState,
    filterStates,
    initialFilterState,
  } = useDataService();

  const { getHEXTenantState } = useSelector((state) => state.hexTenant);
  const { hexTenantInformation, fetching, paginationList } = getHEXTenantState;
  const [hexListData, setHEXListData] = useState([]);
  const [partnerNames, setPartnerNames] = useState([]);
  const [organizationNames, setOrganizationNames] = useState([]);
  const [parentNames, setParentNames] = useState([]);
  const [shownModal, setShownModal] = useState();
  const { hasPermission, featureFlags } = useFeatureFlag();
  const [filterStateList, setFilterStateList] = useState(initialState);

  const [pagination, setPagination] = useState({
    pageIndex: hexTenantPaginations?.pageIndex || 0,
    pageSize: hexTenantPaginations?.pageSize || 10,
  });

  const [filterDataState, setFilterDataState] = useState(filterStates);

  const [adFilter, setAdFilter] = useState(initialFilterState);

  const {
    handleHEXTenantAsync,
    handleStateUpdate,
    handleUpdateFilters,
    handleOnResetButtonClick,
    handleOnTextFiledChange,
    handleOnHideAdvanceClick,
    matchTextHandler,
    getHEXDataFromResponse,
    handlePartnerFilterSearch,
    handleOrganizationFilterSearch,
    handleParentFilterSearch,
    handleOnSearchChange,
    handleOnNameChange,
    responseConverter,
    handleOnSearchClose,
  } = useHEXTenantService(
    filterDataState,
    setFilterDataState,
    filterStates,
    pagination,
    adFilter,
    setAdFilter,
    organizationOwnerId,
    setFilterStateList,
    initialState,
    initialFilterState,
    filterStateList
  );

  const handleOnSearchClick = (e) => {
    e.preventDefault();
    matchTextHandler();
    handleHEXTenantAsync(true);
    setPagination({ pageIndex: 0, pageSize: 10 });
  };

  useEffect(() => {
    const previousProps = paginationProp.current;
    if (
      !organizationOwnerId ||
      organizationOwnerId !== organizationOwnerIdProp?.current ||
      !isEqual(previousProps, pagination)
    ) {
      organizationOwnerIdProp.current = organizationOwnerId;
      paginationProp.current = pagination;
      if (!fetching) {
        handleHEXTenantAsync(false);
      }
    }
  }, [pagination]);

  useEffect(() => {
    if (!filterStateList?.searchState) {
      setFilterStateList((prevState) => ({
        ...prevState,
        forceUpdate: false,
      }));
    }
  }, [filterStateList?.searchState]);

  useEffect(() => {
    getHEXDataFromResponse(hexTenantInformation, setHEXListData);
  }, [hexTenantInformation]);

  const getFlag = (keyProp) => {
    return filterStateList[keyProp]?.length === 0 || filterStateList[keyProp]?.length > 2;
  };

  useEffect(() => {
    if (getFlag('searchTerm')) {
      handlePartnerFilterSearch(filterStateList?.searchTerm);
    }
  }, [filterStateList?.searchTerm]);

  useEffect(() => {
    if (getFlag('searchTermOrganization')) {
      handleOrganizationFilterSearch(filterStateList?.searchTermOrganization);
    }
  }, [filterStateList?.searchTermOrganization]);

  useEffect(() => {
    if (getFlag('searchTermParent')) {
      handleParentFilterSearch(filterStateList?.searchTermParent);
    }
  }, [filterStateList?.searchTermParent]);

  useEffect(() => {
    setOrganizationNames(responseConverter(organizationFilterList, 'organizationName'));
  }, [organizationFilterList]);

  useEffect(() => {
    setParentNames(responseConverter(parentFilterList, 'parentName'));
  }, [parentFilterList]);

  useEffect(() => {
    setPartnerNames(responseConverter(partnersFilterList, 'partnerName'));
  }, [partnersFilterList]);

  return (
    <>
      {!organizationOwnerId && <HeaderPageBlock>HEX Tenant Information</HeaderPageBlock>}
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <QboTextField
            id="fullTextSearch"
            value={filterDataState.textFieldValue}
            isWidthSetExplicit
            placeholder="Id/Email"
            inputProps={{ maxLength: 225 }}
            onChange={(e) => {
              handleOnTextFiledChange(e);
            }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              disableRipple
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                textTransform: 'none',
              }}
              onClick={() => {
                handleStateUpdate('advanceClick', !filterDataState.advanceClick);
                handleOnHideAdvanceClick();
              }}>
              {filterDataState.advanceClick
                ? t('partner_details_page.button.hide_advance_search')
                : t('partner_details_page.button.show_advance_search')}
            </Button>
          </div>
          {filterDataState.advanceClick && (
            <div style={{ display: 'flex', gap: '3rem' }}>
              <QboSortByButton
                defaultValue="All"
                marginTopValue={2}
                marginRightValue={2}
                value={adFilter.find((item) => item.field === 'filter_enable_ssl').value}
                buttonWidth={220}
                startAdornmentTextWidth={40}
                startAdornmentText="SSL :"
                handleChange={(e) => handleUpdateFilters('filter_enable_ssl', e.target.value)}
                menuItemList={sslData}
              />
              <QboSortByButton
                defaultValue="All"
                forceUpdate={filterStateList?.forceUpdate}
                searchState={filterStateList?.searchState}
                handleCloseClick={() => {
                  handleOnSearchClose(
                    'forceUpdate',
                    ['searchState', 'searchTerm', 'selectedPartner'],
                    'forceUpdate',
                    handlePartnerFilterSearch,
                    filterStateList?.searchTerm
                  );
                }}
                isSearchEnable={!false}
                handleOnSearchChange={(e) => {
                  handleOnSearchChange('searchTerm', e.target.value);
                }}
                handleChange={(event) => {
                  handleOnNameChange(event, 'filter_selected_partner', [
                    'searchState',
                    'selectedPartner',
                  ]);
                }}
                marginRightValue={2}
                marginTopValue={2}
                value={filterStateList?.selectedPartner}
                buttonWidth={220}
                startAdornmentTextWidth={180}
                startAdornmentText="Partner Name:"
                menuItemList={partnerNames}
              />
              <QboSortByButton
                defaultValue="All"
                forceUpdate={filterStateList?.forceUpdateOrganization}
                searchState={filterStateList?.searchStateOrganization}
                handleCloseClick={() => {
                  handleOnSearchClose(
                    'forceUpdateOrganization',
                    ['searchStateOrganization', 'searchTermOrganization', 'selectedOrganization'],
                    'forceUpdateOrganization',
                    handleOrganizationFilterSearch,
                    filterStateList?.searchTermOrganization
                  );
                }}
                isSearchEnable={!false}
                handleOnSearchChange={(e) => {
                  handleOnSearchChange('searchTermOrganization', e.target.value);
                }}
                handleChange={(event) => {
                  handleOnNameChange(event, 'filter_selected_organization', [
                    'searchStateOrganization',
                    'selectedOrganization',
                  ]);
                }}
                marginRightValue={2}
                marginTopValue={2}
                value={filterStateList?.selectedOrganization}
                buttonWidth={220}
                startAdornmentTextWidth={180}
                startAdornmentText="Organization Name:"
                menuItemList={organizationNames}
              />
              <QboSortByButton
                defaultValue="All"
                forceUpdate={filterStateList?.forceUpdateParent}
                searchState={filterStateList?.searchStateParent}
                handleCloseClick={() => {
                  handleOnSearchClose(
                    'forceUpdateParent',
                    ['searchStateParent', 'searchTermParent', 'selectedParent'],
                    'forceUpdateParent',
                    handleParentFilterSearch,
                    filterStateList?.searchTermParent
                  );
                }}
                isSearchEnable={!false}
                handleOnSearchChange={(e) => {
                  handleOnSearchChange('searchTermParent', e.target.value);
                }}
                handleChange={(event) => {
                  handleOnNameChange(event, 'filter_selected_parent', [
                    'searchStateParent',
                    'selectedParent',
                  ]);
                }}
                marginRightValue={2}
                marginTopValue={2}
                value={filterStateList?.selectedParent}
                buttonWidth={220}
                startAdornmentTextWidth={180}
                startAdornmentText="Parent Name:"
                menuItemList={parentNames}
              />
              <div>
                <QboSortByButton
                  defaultValue="None"
                  marginTopValue={2}
                  marginRightValue={2}
                  value={filterDataState.selectedSortColumn}
                  buttonWidth={220}
                  startAdornmentTextWidth={70}
                  startAdornmentText="Sort by:"
                  handleChange={(e) => handleStateUpdate('selectedSortColumn', e.target.value)}
                  menuItemList={sortColumnNames}
                />
              </div>
            </div>
          )}
        </QboFormGroup>
      </QboCard>
      <SetupHostedExhangeTemplates />
      <QboCard noPadding isMainWrapper>
        <hr />
        <ITBox
          direction="row"
          sx={{
            margin: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <QboTypography
            sx={{
              marginLeft: 2,
              marginBottom: 3,
              fontSize: 18,
              color: '#000000DE',
              fontWeight: 600,
            }}>
            HEX Tenant List
          </QboTypography>
          {hasPermission(featureFlags.SetupHostedExchange) && (
            <QboButton
              style={{ width: 220 }}
              variant="contained"
              onClick={() => {
                setShownModal(true);
              }}
              loading={fetching}>
              Setup Hosted Exchange
            </QboButton>
          )}
        </ITBox>
        {!filterDataState.resetClicked && filterDataState.matchText ? (
          <QboTypography
            sx={{
              marginLeft: 2,
              marginTop: 2,
              marginBottom: 3,
              fontSize: '15px !important',
              color: '#42526e',
              fontWeight: 600,
              overflow: 'hidden',
              whiteSpace: 'pre-wrap',
            }}>
            {!filterDataState.resetClicked && filterDataState.matchText}
          </QboTypography>
        ) : null}
        {(hexListData.length > 0 || fetching) && (
          <QboAdvanceTable
            isLoading={fetching}
            enableTopToolBar={false}
            headers={hexTenantHeaders}
            rows={hexListData}
            rowsCount={Number(paginationList?.TotalCount || 50)}
            pagination={pagination}
            setPagination={setPagination}
            enableColumnClickable={!false}
            columnsClickableList={clickableColumn}
          />
        )}
        {hexListData.length === 0 && !fetching && <NoStatusSection />}
      </QboCard>

      <SetupHostedExchageModal
        title="Setup Hosted Exchange"
        showModal={shownModal}
        closeModal={() => setShownModal(null)}
      />
    </>
  );
}

HEXTenantPage.propTypes = {
  organizationOwnerId: PropTypes.number,
  organizationOwnerIdProp: PropTypes.object,
  paginationProp: PropTypes.object,
};

HEXTenantPage.defaultProps = {
  organizationOwnerId: null,
  organizationOwnerIdProp: {},
  paginationProp: {},
};
