import useSetupHostedExchagFormValidation from './useSetupHostedExchagFormValidation';

function useSetupHostedExchageForm() {
  const {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    setupTypeAttributes,
    organizationIdAttributes,
    emailAttributes,
    ewsUrlAttributes,
    passwordAttributes,
    csvAdminValidAttributes,
    csvMailboxValidAttributes,
    resetForm,
    validateAll,
    isValid,
  } = useSetupHostedExchagFormValidation();

  const onValidateData = (e) => {
    e.preventDefault();
    validateAll();
  };

  return {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    csvAdminValidAttributes,
    csvMailboxValidAttributes,
    setupTypeAttributes,
    organizationIdAttributes,
    emailAttributes,
    ewsUrlAttributes,
    passwordAttributes,
    resetForm,
    onValidateData,
    isValid,
  };
}

export default useSetupHostedExchageForm;
