import { useMemo, useState } from 'react';

export default function useUserSelection(data) {
  const [rowSelection, setRowSelection] = useState({});

  const selected = useMemo(
    () =>
      data.filter((row) => {
        const key = Object.keys(rowSelection).find((k) => k === row.id);
        return key && rowSelection[key];
      }),
    [data, rowSelection]
  );

  return {
    selected,
    rowSelection,
    setRowSelection,
  };
}
