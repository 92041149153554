import BaseSetting from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import Routes from './Routes';

// const [region] = useLocalStorage(constant.SELECTED_REGIONS);
// const regionName = region?.split(' ')[0];

/**
 * Contains web api urls
 * */

class WebApiGeneralRoutes extends Routes {
  getSelectedRegion() {
    this.selectRegion = localStorage.getItem('INT_STORAGE_SELECTED_REGIONS');

    return this.selectRegion?.split(' ')[0];
  }

  apiV1Path(path = '', params = {}, format = '') {
    return this.createUrl(`/${this.getSelectedRegion()}/api/v1.0${path}`, params, format);
  }
}

const ApiGeneralRoutes = new WebApiGeneralRoutes(BaseSetting.graphConfig.internalGateWayHost);

export default ApiGeneralRoutes;
