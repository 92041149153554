import { createSlice } from '@reduxjs/toolkit';

import { getPlatformsAsync, getPlatformsExtraReducers } from './serviceActions/getPlatformsAsync';

const initialState = {
  getPlatformsState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    data: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const platformsSlice = createSlice({
  name: 'Platforms',
  initialState,
  reducers: {
    resetPlatformsState(state) {
      state.getPlatformsState = initialState.getPlatformsState;
    },
    resetPlatformsStatusCodeState(state) {
      state.getPlatformsState.statusCode = initialState.getPlatformsState.statusCode;
    },
  },
  extraReducers: {
    ...getPlatformsExtraReducers,
  },
});

export { getPlatformsAsync };
export const { resetPlatformsState, resetPlatformsStatusCodeState } = platformsSlice.actions;
export default platformsSlice.reducer;
