import { constant } from '@config/BaseSetting';
import {
  exportOrganizationsAsync,
  resetExportOrganizationsState,
} from '@features/organizationList/exportOrganizationsSlice';
import { getOrganizationsListAsync } from '@features/organizationList/organizationsListSlice';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useLocalStorage from '@hooks/useLocalStorage';
import { MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { showFixedAlert } from '@features/application/applicationSlice';
import useDataService from './useDataService';

export default function useOrganizationListService(
  setAdvanceClick,
  advanceClick,
  setSelectedColumn,
  searchIDFieldValue,
  fullTextSearchValue,
  resellerID,
  pagination,
  adFilter,
  selectedColumn
) {
  const dispatch = useDispatch();
  const { featureFlags, hasPermission } = useFeatureFlag();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [fetchParams, setFetchParams] = useState();
  const { t } = useTranslation();
  const [commandState, setCommandState] = useState({});
  const { modalNames } = useDataService();
  const emptyFilterState = [
    { field: 'filter_status', value: '' },
    { field: 'filter_autoLicense', value: '' },
    { field: 'filter_license', value: '' },
    { field: 'filter_partner', value: '' },
    { field: 'filter_plan', value: '' },
    { field: 'filter_country', value: '' },
    { field: 'filter_storageLocation', value: '' },
  ];

  const handleFetchOrganizationsListAsync = (flag, paginationFlag) => {
    const params = {
      searchById: flag ? 0 : searchIDFieldValue || 0,
      fullTextSearch: flag ? '' : fullTextSearchValue,
      pageNumber: paginationFlag ? 1 : pagination.pageIndex + 1,
      pageSize: paginationFlag ? 10 : pagination.pageSize,
      reseller: flag ? '' : resellerID,
      filter: flag ? emptyFilterState : adFilter,
      orderBy: flag ? 'OrganizationId' : selectedColumn || 'OrganizationId',
    };
    dispatch(
      getOrganizationsListAsync({
        accessToken,
        reset: true,
        ...params,
      })
    );
    setFetchParams(params);
  };

  const { getOrganizationsListState } = useSelector((state) => state.organizationsList);
  const { fetching } = getOrganizationsListState;

  const handleOnAdvanceSearchClick = () => {
    setAdvanceClick(!advanceClick);
  };

  const handleOnSearchClick = (e) => {
    e.preventDefault();
    if (!fetching) {
      handleFetchOrganizationsListAsync({});
    }
  };

  const handleOnSortByChange = (event) => {
    setSelectedColumn(String(event.target.value));
  };

  const matchingText = (setMatchText) => {
    let textData = 'Matching ';

    if (searchIDFieldValue || fullTextSearchValue) {
      if (searchIDFieldValue && fullTextSearchValue) {
        textData += ` id = '${searchIDFieldValue}' and email/name like '%${fullTextSearchValue}%', `;
      } else if (searchIDFieldValue) {
        textData += ` id = ${searchIDFieldValue},`;
      } else if (fullTextSearchValue) {
        textData += ` email/name like '%${fullTextSearchValue}%', `;
      }
    }
    adFilter.forEach((filter, index) => {
      if (filter.value) {
        if (filter.field === 'filter_partner') {
          textData += 'Partner Type = ';
        } else if (filter.field === 'filter_status') {
          textData += 'Status = ';
        } else if (filter.field === 'filter_plan') {
          textData += 'Plan = ';
        } else if (filter.field === 'filter_autoLicense') {
          textData += 'Auto License = ';
        } else if (filter.field === 'filter_license') {
          textData += 'License = ';
        } else if (filter.field === 'filter_country') {
          textData += 'Country = ';
        } else if (filter.field === 'filter_storageLocation') {
          textData += 'Storage Location = ';
        }

        textData += filter.value;
        textData += ', ';
      }
    });

    textData = textData.replace(/,\s*$/, '');

    if (textData === 'Matching ') return '';

    setMatchText(textData);

    return textData;
  };

  const handleExport = () => {
    dispatch(
      exportOrganizationsAsync({
        accessToken,
        reset: true,
        ...fetchParams,
      })
    );
  };

  const handleResetExport = () => {
    dispatch(resetExportOrganizationsState());
  };

  const handleCloseModal = () => {
    setCommandState({});
  };

  const showBulkExcludeMailboxModal = (row) => {
    if (row.original.status !== 'active') {
      dispatch(
        showFixedAlert({
          descriptionHtml: t(
            'organization_list_page.bulk_exclude_mailbox.validations.organization_not_active'
          ),
          severity: 'error',
        })
      );
      return;
    }
    setCommandState({
      shownModal: modalNames.BulkExcludeMailbox,
      organization: row.original,
    });
  };

  const showChangeSubscriptionModal = (row) => {
    if (row.original.status !== 'active') {
      dispatch(
        showFixedAlert({
          descriptionHtml: t(
            'organization_list_page.change_subscription.validations.organization_not_active'
          ),
          severity: 'error',
        })
      );
      return;
    }
    setCommandState({
      shownModal: modalNames.ChangeSubscription,
      organization: row.original,
    });
  };

  const menuActions = ({ row, closeMenu }) => {
    let i = 0;
    const items = [];
    if (hasPermission(featureFlags.BulkExcludeMailbox)) {
      i += 1;
      items.push(
        <MenuItem
          key={i}
          onClick={() => {
            showBulkExcludeMailboxModal(row);
            setTimeout(() => {
              closeMenu();
            }, 1000);
          }}>
          {t('organization_list_page.bulk_exclude_mailbox.title')}
        </MenuItem>
      );
    }
    if (hasPermission(featureFlags.ChangeSubscription)) {
      i += 1;
      items.push(
        <MenuItem
          key={i}
          onClick={() => {
            showChangeSubscriptionModal(row);
            setTimeout(() => {
              closeMenu();
            }, 1000);
          }}>
          {t('organization_list_page.change_subscription.title')}
        </MenuItem>
      );
    }
    return items;
  };

  const hasMenuActions =
    hasPermission(featureFlags.BulkExcludeMailbox) ||
    hasPermission(featureFlags.ChangeSubscription);

  return {
    fetchParams,
    handleOnAdvanceSearchClick,
    handleOnSearchClick,
    handleOnSortByChange,
    handleFetchOrganizationsListAsync,
    handleExport,
    handleResetExport,
    matchingText,
    handleCloseModal,
    commandState,
    hasMenuActions,
    menuActions,
  };
}
