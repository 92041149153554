import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from '@hooks/useLocalStorage';
import { constant } from '@config/BaseSetting';
import { useTranslation } from 'react-i18next';
import { resetToggleMFAState, toggleMFAAsync } from '@features/toggleMFA/toggleMFASlice';
import { useEffect } from 'react';
import { showFixedAlert } from '@features/application/applicationSlice';
import ClientRoutes from '@config/Routes/WebClientRoutes';
import useForm from './useForm';

export default function useToggleMFA({ selectedAccount, onSuccessRequest }) {
  const dispatch = useDispatch();
  const form = useForm();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const { toggleMFAState } = useSelector((state) => state.toggleMFA);
  const { t } = useTranslation();

  const handleToggleMFARequestAsync = (parameters) => {
    dispatch(
      toggleMFAAsync({
        accessTokenSet,
        reset: true,
        parameters,
      })
    );
  };

  const submitRequest = () => {
    if (form.isValid) {
      handleToggleMFARequestAsync([
        {
          'support-ticket': form.attributes.supportTicketAttributes.value,
          description: form.attributes.descriptionAttributes.value,
          'escalation-ticket': form.attributes.escalationTicketAttributes.value,
          'mfa-enabled': form.attributes.mfaStatusAttributes.value,
          'reset-mfa': form.attributes.resetMfaAttributes.value,
          'account-id': selectedAccount.id,
          'change-request-type': selectedAccount.type,
          'org-owner-id': selectedAccount.organizationOwnerId,
          'partner-id': selectedAccount.partnerId,
        },
      ]);
    }
  };

  const onValidateData = (e) => {
    form.onValidateData(e);
    submitRequest();
  };

  useEffect(() => {
    submitRequest();
  }, [form.isValid]);

  useEffect(() => {
    if (toggleMFAState.status === 200) {
      if (toggleMFAState.serviceType) {
        const link = ClientRoutes.toolsRequestStatusPath({
          'service-type': toggleMFAState.serviceType,
        });
        dispatch(
          showFixedAlert({
            descriptionHtml: t('user_page.toggle_mfa.success_submitted_with_link', {
              link,
            }),
          })
        );
      } else {
        dispatch(
          showFixedAlert({
            descriptionHtml: t('user_page.toggle_mfa.success_submitted'),
          })
        );
      }
      if (onSuccessRequest) onSuccessRequest();
    }
    form.resetForm();
    dispatch(resetToggleMFAState());
  }, [toggleMFAState.status]);

  return {
    form,
    onValidateData,
    toggleMFAState,
  };
}
