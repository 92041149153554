import { constant } from '@config/BaseSetting';
import {
  getGwsUsersTenantAsync,
  resetGwsUsersTenantState,
} from '@features/gwsUsersTenant/gwsUsersTenantSlice';
import useLocalStorage from '@hooks/useLocalStorage';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export default function useGwsUsersTenantService(
  filterDataState,
  setFilterDataState,
  filterStates,
  pagination,
  adFilter,
  setAdFilter,
  initialFilterState,
  lastRequestId,
  setPagination
) {
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const dispatch = useDispatch();
  const [excludeModalState, setExcludeModalState] = useState({
    showModal: false,
    mailboxes: [],
  });

  const handleFetchGwsUsersTenantAsync = (flag, resetFlag = false) => {
    dispatch(
      getGwsUsersTenantAsync({
        accessToken,
        reset: true,
        lastRequestId,
        fullTextSearch: flag
          ? filterStates.searchFieldValueText
          : filterDataState.searchFieldValueText,
        pageNumber: flag ? 1 : pagination.pageIndex + 1,
        pageSize: flag ? 10 : pagination.pageSize,
        orderBy: flag ? filterDataState.selectedSortColumn : filterStates.selectedSortColumn,
        filter: resetFlag ? initialFilterState : adFilter,
      })
    );
  };

  const handleStateUpdate = (key, value) => {
    setFilterDataState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleUpdateFilters = (label, val) => {
    const newList = adFilter.map((ele) => {
      if (ele.field === label) {
        return { ...ele, value: val };
      }
      return ele;
    });
    setAdFilter([...newList]);
  };

  const handleOnResetButtonClick = () => {
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: filterStates.textFieldValue,
      searchFieldValueText: filterStates.searchFieldValueText,
      searchFieldValueID: filterStates.searchFieldValueID,
      selectedSortColumn: filterStates.selectedSortColumn,
      matchText: filterStates.matchText,
      resetClicked: filterStates.resetClicked,
    }));
    setAdFilter(initialFilterState);
    setPagination({ pageIndex: 0, pageSize: 10 });

    dispatch(resetGwsUsersTenantState());
    handleFetchGwsUsersTenantAsync(true, true);
  };

  const handleOnTextFiledChange = (e) => {
    const input = e.target.value;
    setFilterDataState((prevState) => ({
      ...prevState,
      textFieldValue: input,
    }));
    setFilterDataState((prevState) => ({
      ...prevState,
      searchFieldValueID: '',
      searchFieldValueText: input,
    }));
  };

  const handleOnHideAdvanceClick = () => {
    setFilterDataState((prevState) => ({
      ...prevState,
      selectedSortColumn: filterStates.selectedSortColumn,
    }));
    setAdFilter(initialFilterState);
  };

  const matchTextHandler = () => {
    const { searchFieldValueID, searchFieldValueText } = filterDataState;

    let textData = 'Matching ';

    if (searchFieldValueText) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` User Name/Email/UPN/GWS ID = '${searchFieldValueText}' `;
    }

    if (searchFieldValueID) {
      if (textData !== 'Matching ') {
        textData += ', ';
      }
      textData += ` User Name/Email/UPN/GWS ID = '${searchFieldValueID}' `;
    }

    adFilter.forEach((filter) => {
      if (filter.value) {
        if (filter.field === 'filter_assignedLicenses') {
          textData += 'Assigned Licenses  = ';
        } else if (filter.field === 'filter_licensed') {
          textData += 'Has GWS License = ';
        } else if (filter.field === 'filter_active') {
          textData += 'Status = ';
        } else if (filter.field === 'filter_selected_partner') {
          textData += 'Partner Name = ';
        } else if (filter.field === 'filter_selected_organization') {
          textData += 'Organization Name = ';
        } else if (filter.field === 'filter_selected_parent') {
          textData += 'Parent Name = ';
        }
        textData += filter.value;
        textData += ', ';
      }
    });

    if (textData === 'Matching ') {
      textData = '';
    }
    textData = textData.replace(/,\s*$/, '');

    setFilterDataState((prevState) => ({
      ...prevState,
      matchText: textData,
    }));
  };

  const showExcludeModal = (selected) => {
    setExcludeModalState({
      showModal: true,
      mailboxes: selected.filter((s) => s.active === 'available'),
    });
  };

  const closeExcludeModal = () => {
    setExcludeModalState({
      showModal: false,
      mailboxes: [],
    });
  };

  return {
    handleFetchGwsUsersTenantAsync,
    handleStateUpdate,
    handleUpdateFilters,
    handleOnResetButtonClick,
    handleOnTextFiledChange,
    handleOnHideAdvanceClick,
    matchTextHandler,
    excludeModalState,
    showExcludeModal,
    closeExcludeModal,
  };
}
