import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import WebApiRoutes from '@config/Routes/WebApiRoutes';
import WebApiGeneralRoutes from '@config/Routes/WebApiGeneralRoutes';

const baseQuery = fetchBaseQuery({
  baseUrl: WebApiRoutes.getBaseUrl(),
});

const OrganizationService = createApi({
  reducerPath: 'jobStatusActivities',
  baseQuery,
  endpoints: (builder) => ({
    editOrganizationSettings: builder.mutation({
      query: ({ accessToken, parameters }) => ({
        url: WebApiGeneralRoutes.apiV1Path(`/edit_organization_settings`),
        method: 'POST',
        headers: { Authorization: `Bearer ${accessToken}` },
        body: parameters,
      }),
    }),
  }),
});

export const { useEditOrganizationSettingsMutation } = OrganizationService;

export default OrganizationService;
