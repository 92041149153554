import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ITBox, QboAlert, QboFormGroup, QboTextField, QboTypography } from '@ui/Components';
import ErrorIcon from '@mui/icons-material/Error';
import { FormControl, MenuItem, TextField } from '@mui/material';
import {
  ORGANIZATION_PRICE,
  PLAN_PRICE,
  PRICE_GUIDE,
} from '@pages/PlansListPage/pageHooks/useValidation';
import useEmailFromCSV from '@hooks/useEmailFromCSV';
import PartnerFilterSection from '../Shared/PartnerFilterSection';
import PlanFilterSection from '../Shared/PlanFilterSection';

export default function SpecialPricingFormSection({
  onSubmit,
  formData,
  requestTypeOptions,
  detailState,
}) {
  const { t } = useTranslation();
  const [accountLists, setAccountLists] = useState([]);
  const { handleOnFileUpload } = useEmailFromCSV();
  const { statusCode: status, errorMessage } = detailState;
  const isAstericRequired = true;
  const inputRef = useRef(null);

  useEffect(() => {
    if (accountLists.length > 0) {
      formData.accountIdsAttributes.handleChange(accountLists.toString());
    }
  }, [accountLists]);

  return (
    <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
      <QboFormGroup onSubmit={onSubmit} withSubmitButton buttonLabel="Next">
        {errorMessage && status !== 200 && (
          <QboAlert type="error" style={{ fontSize: '1.15em' }}>
            {errorMessage}
          </QboAlert>
        )}
        <QboTextField
          id="supportTicket"
          required={isAstericRequired}
          placeholder={t('plans_list_page.labels.support_ticket')}
          label={t('plans_list_page.labels.support_ticket')}
          fullWidth
          value={formData.supportTicketAttributes.value}
          errorMessage={formData.supportTicketAttributes.errorMessage}
          inputProps={{ maxLength: 225 }}
          onChange={(e) => {
            formData.supportTicketAttributes.handleChange(e.currentTarget.value);
          }}
        />
        <QboTextField
          id="escalationTicket"
          placeholder={t('plans_list_page.labels.escalation_ticket')}
          label={t('plans_list_page.labels.escalation_ticket')}
          fullWidth
          value={formData.escalationTicketAttributes.value}
          onChange={(e) => formData.escalationTicketAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <QboTextField
          id="description"
          placeholder={t('plans_list_page.labels.description')}
          label={t('plans_list_page.labels.description')}
          fullWidth
          value={formData.descriptionAttributes.value}
          onChange={(e) => formData.descriptionAttributes.handleChange(e.currentTarget.value)}
          inputProps={{ maxLength: 225 }}
        />
        <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
          <div className="QboTextField__label" id="transferType">
            {t('plans_list_page.labels.partner_id')}
            <span style={{ color: 'red' }}> *</span>
          </div>
          <PartnerFilterSection
            label=""
            value={formData.partnerIdAttributes.value}
            marginRightValue={2}
            buttonWidth="100%"
            inputSize={null}
            defaultValue={null}
            handleChange={(value) => {
              formData.partnerIdAttributes.handleChange(value);
            }}
          />
          {formData.partnerIdAttributes.errorMessage && (
            <span className="QboTextField__error-text">
              <ErrorIcon />
              {` ${formData.partnerIdAttributes.errorMessage}`}
            </span>
          )}
        </FormControl>
        <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
          <div className="QboTextField__label" id="transferType">
            {t('plans_list_page.labels.request_type')}
            <span style={{ color: 'red' }}> *</span>
          </div>
          <TextField
            value={formData.requestTypeAttributes.value}
            select
            onChange={(e) => formData.requestTypeAttributes.handleChange(e.target.value)}>
            {Object.keys(requestTypeOptions).map((option) => (
              <MenuItem key={option} value={option}>
                {requestTypeOptions[option]}
              </MenuItem>
            ))}
          </TextField>
          {formData.requestTypeAttributes.errorMessage && (
            <span className="QboTextField__error-text">
              <ErrorIcon />
              {` ${formData.requestTypeAttributes.errorMessage}`}
            </span>
          )}
        </FormControl>
        {formData.requestTypeAttributes.value === PRICE_GUIDE && (
          <>
            <QboTextField
              id="backupPrice"
              placeholder={t('plans_list_page.labels.backup_price')}
              label={t('plans_list_page.labels.backup_price')}
              fullWidth
              value={formData.newBackupPriceAttributes.value}
              errorMessage={formData.newBackupPriceAttributes.errorMessage}
              onChange={(e) => {
                formData.newBackupPriceAttributes.handleChange(e.currentTarget.value);
              }}
            />
            <QboTextField
              id="archivePrice"
              placeholder={t('plans_list_page.labels.archive_price')}
              label={t('plans_list_page.labels.archive_price')}
              fullWidth
              value={formData.newArchivePriceAttributes.value}
              errorMessage={formData.newArchivePriceAttributes.errorMessage}
              onChange={(e) =>
                formData.newArchivePriceAttributes.handleChange(e.currentTarget.value)
              }
            />
            <QboTextField
              id="qboPrice"
              placeholder={t('plans_list_page.labels.qbo_price')}
              label={t('plans_list_page.labels.qbo_price')}
              fullWidth
              value={formData.newQboPriceAttributes.value}
              errorMessage={formData.newQboPriceAttributes.errorMessage}
              onChange={(e) => formData.newQboPriceAttributes.handleChange(e.currentTarget.value)}
            />
            <QboTextField
              id="nfpBackupPrice"
              placeholder={t('plans_list_page.labels.nfp_backup_price')}
              label={t('plans_list_page.labels.nfp_backup_price')}
              fullWidth
              value={formData.newNfpBackupPriceAttributes.value}
              errorMessage={formData.newNfpBackupPriceAttributes.errorMessage}
              onChange={(e) => {
                formData.newNfpBackupPriceAttributes.handleChange(e.currentTarget.value);
              }}
            />
            <QboTextField
              id="nfpArchivePrice"
              placeholder={t('plans_list_page.labels.nfp_archive_price')}
              label={t('plans_list_page.labels.nfp_archive_price')}
              fullWidth
              value={formData.newNfpArchivePriceAttributes.value}
              errorMessage={formData.newNfpArchivePriceAttributes.errorMessage}
              onChange={(e) =>
                formData.newNfpArchivePriceAttributes.handleChange(e.currentTarget.value)
              }
            />
            <QboTextField
              id="entraBackupPrice"
              placeholder={t('plans_list_page.labels.entra_backup_price')}
              label={t('plans_list_page.labels.entra_backup_price')}
              fullWidth
              value={formData.newEntraBackupPriceAttributes.value}
              errorMessage={formData.newEntraBackupPriceAttributes.errorMessage}
              onChange={(e) => {
                formData.newEntraBackupPriceAttributes.handleChange(e.currentTarget.value);
              }}
            />
            <QboTextField
              id="entraArchivePrice"
              placeholder={t('plans_list_page.labels.entra_archive_price')}
              label={t('plans_list_page.labels.entra_archive_price')}
              fullWidth
              value={formData.newEntraArchivePriceAttributes.value}
              errorMessage={formData.newEntraArchivePriceAttributes.errorMessage}
              onChange={(e) =>
                formData.newEntraArchivePriceAttributes.handleChange(e.currentTarget.value)
              }
            />
            <QboTextField
              id="nfpEntraBackupPrice"
              placeholder={t('plans_list_page.labels.nfp_entra_backup_price')}
              label={t('plans_list_page.labels.nfp_entra_backup_price')}
              fullWidth
              value={formData.newNfpEntraBackupPriceAttributes.value}
              errorMessage={formData.newNfpEntraBackupPriceAttributes.errorMessage}
              onChange={(e) => {
                formData.newNfpEntraBackupPriceAttributes.handleChange(e.currentTarget.value);
              }}
            />
            <QboTextField
              id="nfpEntraArchivePrice"
              placeholder={t('plans_list_page.labels.nfp_entra_archive_price')}
              label={t('plans_list_page.labels.nfp_entra_archive_price')}
              fullWidth
              value={formData.newNfpEntraArchivePriceAttributes.value}
              errorMessage={formData.newNfpEntraArchivePriceAttributes.errorMessage}
              onChange={(e) =>
                formData.newNfpEntraArchivePriceAttributes.handleChange(e.currentTarget.value)
              }
            />
          </>
        )}
        {[PLAN_PRICE, ORGANIZATION_PRICE].includes(formData.requestTypeAttributes.value) && (
          <>
            <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
              <div className="QboTextField__label" id="transferType">
                {t('plans_list_page.labels.plan_id')}
                <span style={{ color: 'red' }}> *</span>
              </div>
              <PlanFilterSection
                resellerId={formData.partnerIdAttributes.value}
                label=""
                value={formData.planIdAttributes.value}
                marginRightValue={2}
                buttonWidth="100%"
                inputSize={null}
                defaultValue={null}
                handleChange={(value) => {
                  formData.planIdAttributes.handleChange(value);
                }}
              />
              {formData.planIdAttributes.errorMessage && (
                <span className="QboTextField__error-text">
                  <ErrorIcon />
                  {` ${formData.planIdAttributes.errorMessage}`}
                </span>
              )}
            </FormControl>
            <QboTextField
              id="newPlanPrice"
              required={isAstericRequired}
              placeholder={t('plans_list_page.labels.new_plan_price')}
              label={t('plans_list_page.labels.new_plan_price')}
              fullWidth
              value={formData.newPlanPriceAttributes.value}
              errorMessage={formData.newPlanPriceAttributes.errorMessage}
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                formData.newPlanPriceAttributes.handleChange(e.currentTarget.value);
              }}
            />
          </>
        )}
        {formData.requestTypeAttributes.value === ORGANIZATION_PRICE && (
          <>
            <QboTextField
              id="accountIds"
              required={isAstericRequired}
              placeholder={t('plans_list_page.labels.account_ids_placeholder')}
              label={t('plans_list_page.labels.account_ids')}
              multiline={Boolean(true)}
              rows={5}
              fullWidth
              value={formData.accountIdsAttributes.value}
              onChange={(e) => formData.accountIdsAttributes.handleChange(e.currentTarget.value)}
              errorMessage={formData.accountIdsAttributes.errorMessage}
            />
            <QboTextField
              id="csvFile"
              placeholder={t('bulk_purge.labels.csv_file')}
              label={t('bulk_purge.labels.csv_file')}
              type="file"
              inputRef={inputRef}
              fullWidth
              inputProps={{ maxLength: 225 }}
              value={formData.FileAttributes?.value?.file}
              onChange={(event) => {
                handleOnFileUpload(event, setAccountLists);
                formData.FileAttributes.handleChange(event?.target?.files[0]);
              }}
              errorMessage={formData.FileAttributes.errorMessage}
            />
          </>
        )}
      </QboFormGroup>
    </ITBox>
  );
}

SpecialPricingFormSection.propTypes = {
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  requestTypeOptions: PropTypes.object,
  detailState: PropTypes.object,
};

SpecialPricingFormSection.defaultProps = {
  formData: {
    supportTicketAttributes: {},
    escalationTicketAttributes: {},
    partnerIdAttributes: {},
    requestTypeAttributes: {},
    descriptionAttributes: {},
  },
  onSubmit: () => {},
  requestTypeOptions: {},
  detailState: {},
};
