import { createSlice } from '@reduxjs/toolkit';

import {
  getResellerPlatformsAsync,
  getResellerPlatformsExtraReducers,
} from './serviceActions/getResellerPlatformsAsync';

const initialState = {
  getResellerPlatformsState: {
    fetching: false,
    success: false,
    error: null,
    statusCode: null,
    data: [],
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const resellerPlatformsSlice = createSlice({
  name: 'ResellerPlatforms',
  initialState,
  reducers: {
    resetResellerPlatformsState(state) {
      state.getResellerPlatformsState = initialState.getResellerPlatformsState;
    },
    resetResellerPlatformsStatusCodeState(state) {
      state.getResellerPlatformsState.statusCode =
        initialState.getResellerPlatformsState.statusCode;
    },
  },
  extraReducers: {
    ...getResellerPlatformsExtraReducers,
  },
});

export { getResellerPlatformsAsync };
export const { resetResellerPlatformsState, resetResellerPlatformsStatusCodeState } =
  resellerPlatformsSlice.actions;
export default resellerPlatformsSlice.reducer;
