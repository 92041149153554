export const JOB_STATUS_COLUMNS = [
  { label: 'Job ID', columnName: 'jobId' },
  { label: 'Job Type', columnName: 'jobType', size: 'sm' },
  { label: 'Agent ID', columnName: 'agentId', size: 'sm' },
  { label: 'Agent Type', columnName: 'agentType', size: 'sm' },
  { label: 'Problem', columnName: 'problemText', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Updated At', columnName: 'jobLastUpdatedAt', size: 'sm' },
  { label: 'Notified At', columnName: 'notifiedAt', size: 'sm' },
  { label: 'Counter', columnName: 'counter', size: 'sm' },
];

export const jobTypeItems = [
  {
    id: 1,
    value: 'archive_folder_syncs',
    title: 'Archive Folder Syncs',
  },
  { id: 2, value: 'archives', title: 'Archives' },
  { id: 3, value: 'backup_exchanges', title: 'Backup Exchanges' },
  { id: 4, value: 'downloads', title: 'Downloads' },
  { id: 5, value: 'email_backups', title: 'Email Backups' },
  { id: 6, value: 'gd_backups', title: 'GDrive Backups' },
  { id: 7, value: 'migrates', title: 'Migrates' },
  { id: 8, value: 'ms_chat_backups', title: 'MS Chat Backups' },
  { id: 9, value: 'ms_group_backups', title: 'MS Group Backups' },
  { id: 10, value: 'od_backups', title: 'One Drive Backups' },
  {
    id: 11,
    value: 'od_snapshot_vault_processes',
    title: 'One Drive Snapshot Vault Processes',
  },
  { id: 12, value: 'od_vault_processes', title: 'One Drive Vault Processes' },
  { id: 13, value: 'restores', title: 'Restores' },
  { id: 14, value: 'sd_backup_domains', title: 'Shared Drive Backup Domains' },
  { id: 15, value: 'sd_backups', title: 'Shared Drive Backups' },
  { id: 16, value: 'sp_backup_domains', title: 'Sharepoint Backup Domains' },
  { id: 17, value: 'sp_backups', title: 'Sharepoint Backups' },
  {
    id: 18,
    value: 'sp_snapshot_vault_processes',
    title: 'Sharepoint Snapshot Vault Processes',
  },
  { id: 19, value: 'sp_vault_processes', title: 'Sharepoint Vault Processes' },
];

export const problemItems = [
  {
    id: 1,
    value: 'phantom',
    title: 'Phantom',
  },
  {
    id: 2,
    value: 'long_running',
    title: 'Long Running',
  },
];

export const buttonStyle = {
  height: 36,
  backgroundColor: '#a5a5a5',
  textDecoration: 'none',
  color: 'black',
  '&:hover': {
    backgroundColor: '#a5a5a5',
  },
  textTransform: 'unset',
  fontSize: 14,
};
