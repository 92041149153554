import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { QboButton, QboTextField, QboTypography } from '@ui/Components';
import { useTranslation } from 'react-i18next';
import useFeatureFlag from '@hooks/useFeatureFlag';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import { useDispatch } from 'react-redux';
import { showFixedAlert } from '@features/application/applicationSlice';
import NotFoundSection from '../SystemStatusPage/NotFoundSection';

const initialSearchState = {
  inputValue: '',
  submittedValue: '',
};

const initialEditState = {
  isEditting: false,
  originalValues: {},
  fieldValues: {},
  errorMessages: {},
};

function isEditable(label, data) {
  const editable = label?.editable;
  if (typeof editable === 'boolean') return editable;
  if (typeof editable === 'function') return editable(data);
  return !!editable;
}

const mask = (value) => {
  const s = `${value}`;
  if (s.length === 0) return '-';
  let visible = Math.floor(s.length / 2);
  if (visible > 10) visible = 10;
  const masked = s.length - visible;
  return s.substring(0, visible) + '*'.repeat(masked);
};

const getDisplayValue = (data, field, valType = '', options = undefined) => {
  const result = data && data[field];

  if (valType === 'bool' && result === null) {
    return 'false';
  }
  if (result === null) {
    return '-';
  }
  if (valType === 'maskedString') {
    return mask(result);
  }
  if (options && options.length > 0) {
    const optionLabel = options.find((opt) => opt.id === result)?.label;
    return optionLabel || `${result}`;
  }
  return `${result}`;
};

export default function OrganizationSettingsSection(props) {
  const { data, labels, onSave } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { featureFlags, hasPermission } = useFeatureFlag();
  const [searchState, setSearchState] = useState(initialSearchState);
  const [editState, setEditState] = useState(initialEditState);

  const filteredLabels = useMemo(() => {
    if (!searchState.submittedValue) return labels;
    return labels.filter(
      (f) =>
        f.label.toLowerCase().includes(searchState.submittedValue.toLowerCase()) ||
        f.properties.toLowerCase().includes(searchState.submittedValue.toLowerCase())
    );
  }, [searchState.submittedValue, labels]);

  const editable = useMemo(() => {
    if (!filteredLabels) return false;
    for (let i = 0; i < filteredLabels.length; i += 1) {
      if (isEditable(filteredLabels[i], data)) return true;
    }
    return false;
  }, [filteredLabels]);

  const changedValues = useMemo(() => {
    if (!editState.originalValues || !editState.fieldValues) {
      return editState.fieldValues ?? {};
    }
    const retval = {};
    Object.keys(editState.originalValues).forEach((k) => {
      if (editState.originalValues[k] === editState.fieldValues[k]) return;
      retval[k] = editState.fieldValues[k] ?? null;
    });
    return retval;
  }, [editState]);

  const isSaveable = changedValues && Object.keys(changedValues).length > 0;

  const handleInitializeEdit = () => {
    const fieldValues = {};
    filteredLabels
      .filter((f) => isEditable(f, data) && f.properties)
      .forEach((f) => {
        fieldValues[f.properties] =
          f.valType === 'float' || f.valType === 'double'
            ? data[f.properties].toString()
            : data[f.properties];
      });

    setEditState({
      fieldValues,
      originalValues: fieldValues,
      errorMessages: {},
      isEditting: true,
    });
  };

  const handleCancelEdit = () => {
    setEditState(initialEditState);
  };

  const handleUpdateField = (field, value) => {
    setEditState({
      ...editState,
      fieldValues: {
        ...editState.fieldValues,
        [field.properties]:
          typeof field.changeHandler === 'function' ? field.changeHandler(value) : value,
      },
    });
  };

  const handleSave = () => {
    const errorMessages = {};
    let isValid = true;
    filteredLabels.forEach((f) => {
      if (!Object.keys(changedValues).includes(f.properties)) return;
      if (typeof f.validate === 'function') {
        const errorMessage = f.validate(changedValues[f.properties]);
        if (errorMessage) {
          errorMessages[f.properties] = t(errorMessage);
          isValid = false;
        }
      }
    });

    if (!isValid) {
      dispatch(
        showFixedAlert({
          descriptionHtml: t(
            'organization_details_page.edit_organization_settings.validations.validation_failure'
          ),
          severity: 'error',
        })
      );
      setEditState({ ...editState, errorMessages });
      return;
    }

    if (!isSaveable) {
      dispatch(
        showFixedAlert({
          descriptionHtml: t(
            'organization_details_page.edit_organization_settings.validations.no_changes'
          ),
          severity: 'error',
        })
      );
      return;
    }

    const editData = Object.keys(changedValues).map((k) => {
      const label = filteredLabels.find((l) => l.properties === k);
      return {
        label: label.label,
        subHeadingText: label.subHeadingText,
        key: k,
        value: changedValues[k],
      };
    });

    onSave(editData);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setEditState(initialEditState);
    setSearchState({ ...searchState, submittedValue: searchState.inputValue });
  };

  const handleSearchReset = () => {
    setEditState(initialEditState);
    setSearchState(initialSearchState);
  };

  return (
    <>
      <div className="SettingsSection__toolbar">
        <form className="SettingsSection__search" onSubmit={handleSearchSubmit}>
          <div className="SettingsSection__search-input-wrapper">
            <QboTextField
              id="search"
              value={searchState.inputValue}
              isWidthSetExplicit
              explicitStyleClass="SettingsSection__search-input"
              placeholder="Search settings"
              inputProps={{ maxLength: 225 }}
              onChange={(e) => {
                setSearchState({ ...searchState, inputValue: e.currentTarget.value });
              }}
            />
            <QboButton variant="contained" type="submit">
              Search
            </QboButton>
            <QboButton variant="text" type="button" onClick={handleSearchReset}>
              Reset
            </QboButton>
          </div>
          {searchState.submittedValue && (
            <QboTypography className="SettingsSection__match-text">
              {t('organization_details_page.search_text', { value: searchState.submittedValue })}
            </QboTypography>
          )}
        </form>

        {hasPermission(featureFlags.EditOrganizationSettings) && editable && (
          <div className="SettingsSection__buttons">
            {editState.isEditting ? (
              <>
                <Button
                  className="SettingsSection__cancel-button"
                  onClick={() => handleCancelEdit()}>
                  Cancel
                </Button>
                <QboButton
                  variant="contained"
                  disabled={!isSaveable}
                  withTitle={!isSaveable}
                  title={
                    !isSaveable
                      ? t(
                          'organization_details_page.edit_organization_settings.validations.no_changes'
                        )
                      : null
                  }
                  onClick={handleSave}>
                  Save
                </QboButton>
              </>
            ) : (
              <QboButton variant="contained" onClick={handleInitializeEdit}>
                Edit
              </QboButton>
            )}
          </div>
        )}
      </div>
      {filteredLabels?.map((label) => {
        return editState.isEditting && isEditable(label, data) ? (
          <QboCardSingleList
            key={label.label}
            subHeadingText={label?.subHeadingText}
            leftContent={label.label}
            editable
            value={editState.fieldValues[label.properties]}
            errorMessage={editState.errorMessages[label.properties]}
            valueType={label.valType}
            onChange={(v) => handleUpdateField(label, v)}
            options={label.options}
            defaultOption={label.defaultOption}
          />
        ) : (
          <QboCardSingleList
            key={label.label}
            subHeadingText={label?.subHeadingText}
            valueType={label.valType}
            leftContent={label.label}
            rightContent={getDisplayValue(data, label.properties, label.valType, label.options)}
          />
        );
      })}
      {filteredLabels.length === 0 && (
        <NotFoundSection
          forbiddenErrorMessage={{
            heading: t('organization_details_page.no_result_text', {
              value: searchState.submittedValue,
            }),
          }}
        />
      )}
    </>
  );
}

OrganizationSettingsSection.propTypes = {
  data: PropTypes.object,
  labels: PropTypes.arrayOf(PropTypes.object),
  onSave: PropTypes.func,
};

OrganizationSettingsSection.defaultProps = {
  data: {},
  labels: [],
  onSave: () => {},
};
