import { QboCard, QboNoStatus, QboTypography } from '@ui/Components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import QboTitleWithCollapsableButton from '@ui/Components/QboTitleWithCollapsableButton';
import QboCardSingleList from '@ui/Components/QboCardSingleList';
import useIntegrationService from './pageHooks/useIntegrationService';

export default function IntegrationPage({ resellerId }) {
  const { t } = useTranslation();
  const { getPlatformsState, getResellerPlatformsState } = useIntegrationService(resellerId);

  const { fetching, data: platformsData } = getPlatformsState;
  const { fetching: loading, data: resellerPlatformsData } = getResellerPlatformsState;
  const [availableResellerPlatform, setAvailableResellerPlatform] = useState([]);
  const [accordionState, setAccordionState] = useState([]);

  useEffect(() => {
    setAvailableResellerPlatform(
      resellerPlatformsData.map((row) => {
        return row.platform_code;
      })
    );
  }, [resellerPlatformsData]);

  useEffect(() => {
    setAccordionState(
      platformsData.map((row) => {
        return { [row.code]: false };
      })
    );
  }, [platformsData]);

  const handleOnIconButtonClick = (e, code) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [code]: !prevState[code],
    }));
  };

  const renderLoading = () => {
    return (
      <div style={{ display: 'contents' }}>
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
        <Skeleton variant="text" width="80%" />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="text" width="70%" />
      </div>
    );
  };

  const renderList = (platform) => {
    if (loading) {
      return renderLoading();
    }

    const resellerPlatform = resellerPlatformsData?.find((row) => {
      return row.platform_code === platform.code;
    });
    return (
      <QboCard>
        <QboTypography variant="h5" sx={{ marginLeft: 2, marginTop: 2, marginBottom: 1 }}>
          Integration Config
        </QboTypography>
        {platform?.integration_config_template?.map((item) => {
          const config = resellerPlatform.integration_config?.find((x) => {
            return x.id === item.id;
          });
          return (
            <QboCardSingleList
              key={item.id}
              leftContent={item.name}
              subHeadingText={item?.subHeadingText}
              rightContent={config?.text || config?.value || '-'}
            />
          );
        })}
        <QboTypography variant="h5" sx={{ marginLeft: 2, marginTop: 2, marginBottom: 1 }}>
          Service Mapping
        </QboTypography>
        {platform?.service_board_mapping_template?.map((item) => {
          const mapping = resellerPlatform.service_board_mapping?.find((x) => {
            return x.id === item.id;
          });
          return (
            <QboCardSingleList
              key={item.id}
              leftContent={item.name}
              subHeadingText={item?.subHeadingText}
              rightContent={mapping?.text || mapping?.value || '-'}
            />
          );
        })}
      </QboCard>
    );
  };

  const renderAccordion = () => {
    if (fetching) {
      return renderLoading();
    }

    if (!platformsData) {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <QboNoStatus title={t('subscription_history.no_result')} />
        </QboCard>
      );
    }

    return platformsData.map((row) => {
      return (
        <QboCard sx={{ marginBottom: 2 }}>
          <QboTitleWithCollapsableButton
            collapseVisibility={availableResellerPlatform.includes(row.code) ? '' : 'hidden'}
            isCollapseButtonEnabled={false}
            title={row.title}
            variant="h5"
            flagType={row.title}
            open={accordionState[row.code]}
            style={{ display: 'flex', justifyContent: 'space-between' }}
            handleOnIconButtonClick={(e) => {
              handleOnIconButtonClick(e, row.code);
            }}
          />
          {(loading ||
            (accordionState[row.code] && availableResellerPlatform.includes(row.code))) &&
            renderList(row)}
        </QboCard>
      );
    });
  };

  return <>{renderAccordion()}</>;
}

IntegrationPage.propTypes = {
  resellerId: PropTypes.number,
};

IntegrationPage.defaultProps = {
  resellerId: null,
};
