import React, { useEffect } from 'react';
import {
  ITBox,
  QboCheckbox,
  QboFormGroup,
  QboModal,
  QboSpinnerProgress,
  QboTextField,
} from '@ui/Components';
import ErrorIcon from '@mui/icons-material/Error';
import PropTypes from 'prop-types';
import { FormControl, Grid, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useToggleMFA from './pageHooks/useToggleMFA';
import useValidateMFA from './pageHooks/useValidateMFA';

function ToggleMFAModal({
  closeModal,
  showModal,
  title,
  children,
  selectedAccount,
  buttonDisabled,
}) {
  if (!selectedAccount) return null;
  const { t } = useTranslation();
  const validation = useValidateMFA(selectedAccount);
  const { form, onValidateData, toggleMFAState } = useToggleMFA({
    selectedAccount,
    onSuccessRequest: closeModal,
  });
  const mfaOptions = {
    [true]: 'Enable',
    [false]: 'Disable',
  };
  const email = selectedAccount?.email;

  const closeAndReset = () => {
    closeModal();
    form.resetForm();
    validation.reset();
  };

  useEffect(() => {
    form.attributes.mfaStatusAttributes.handleChange(selectedAccount?.mfaStatus);
  }, [selectedAccount]);

  return (
    <>
      {validation.validating && (
        <QboSpinnerProgress className="DefaultApplication__not-ready-loading" overlay />
      )}
      <QboModal
        onClose={closeAndReset}
        open={showModal && validation.isValid}
        title={title}
        showCloseIcon
        preventOutsideClick>
        <ITBox className="TransferSubscriptionFormPage__wrapper" direction="column">
          <QboFormGroup
            loadingButton={toggleMFAState.fetching}
            onSubmit={onValidateData}
            withGenerateAndResetButton
            handleOnResetButton={closeAndReset}
            secondButtonLabel="Cancel"
            firstButtonLabel="Submit"
            buttonDisabled={buttonDisabled}>
            {children}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <QboTextField
                  id="supportTicket"
                  required
                  placeholder="Support Ticket"
                  label="Support Ticket"
                  fullWidth
                  value={form.attributes.supportTicketAttributes.value}
                  errorMessage={form.attributes.supportTicketAttributes.errorMessage}
                  inputProps={{ maxLength: 225 }}
                  onChange={(e) => {
                    form.attributes.supportTicketAttributes.handleChange(e.currentTarget.value);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <QboTextField
                  id="escalationTicket"
                  placeholder="Escalation Ticket"
                  label="Escalation Ticket"
                  fullWidth
                  value={form.attributes.escalationTicketAttributes.value}
                  onChange={(e) =>
                    form.attributes.escalationTicketAttributes.handleChange(e.currentTarget.value)
                  }
                  inputProps={{ maxLength: 225 }}
                />
              </Grid>
            </Grid>

            <QboTextField
              id="description"
              placeholder="Description"
              label="Description"
              fullWidth
              value={form.attributes.descriptionAttributes.value}
              onChange={(e) =>
                form.attributes.descriptionAttributes.handleChange(e.currentTarget.value)
              }
              inputProps={{ maxLength: 225 }}
            />
            <FormControl className="FormControl__default-wrapper FormControl__full-width-wrapper">
              <div className="QboTextField__label" id="transferType">
                MFA Status
                <span style={{ color: 'red' }}> *</span>
              </div>
              <TextField
                value={form.attributes.mfaStatusAttributes.value}
                select
                onChange={(e) => form.attributes.mfaStatusAttributes.handleChange(e.target.value)}>
                {Object.keys(mfaOptions).map((option) => (
                  <MenuItem key={option} value={option}>
                    {mfaOptions[option]}
                  </MenuItem>
                ))}
              </TextField>
              {form.attributes.mfaStatusAttributes.errorMessage && (
                <span className="QboTextField__error-text">
                  <ErrorIcon />
                  {` ${form.attributes.mfaStatusAttributes.errorMessage}`}
                </span>
              )}
            </FormControl>
            <QboCheckbox
              id="resetMFA"
              label={t('user_page.toggle_mfa.labels.reset_mfa', { email })}
              checked={form.attributes.resetMfaAttributes.value}
              onChange={(e) => {
                form.attributes.resetMfaAttributes.handleChange(e.currentTarget.checked);
              }}
            />
          </QboFormGroup>
        </ITBox>
      </QboModal>
    </>
  );
}

ToggleMFAModal.propTypes = {
  closeModal: PropTypes.func,
  showModal: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  selectedAccount: PropTypes.object,
  buttonDisabled: PropTypes.bool,
};

ToggleMFAModal.defaultProps = {
  closeModal: () => {},
  showModal: false,
  title: '',
  children: '',
  selectedAccount: null,
  buttonDisabled: false,
};

export default ToggleMFAModal;
