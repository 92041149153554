import { createAsyncThunk } from '@reduxjs/toolkit';
import UserBackupService from '@services/WebApiService/UserBackupService';

const changeSubscriptionAsync = createAsyncThunk(
  'ChangeSubscription/changeSubscription',
  async (paramsList) => {
    try {
      const data = await UserBackupService.changeSubscriptionApi(paramsList);
      return data;
    } catch (error) {
      return { error: error.response };
    }
  }
);

const { pending, fulfilled, rejected } = changeSubscriptionAsync;

const changeSubscriptionExtraReducers = {
  [pending]: (state) => {
    state.changeSubscriptionState.fetching = true;
  },
  [rejected]: (state) => {
    state.changeSubscriptionState.fetching = false;
  },
  [fulfilled]: (state, action) => {
    state.changeSubscriptionState.fetching = false;

    if (action?.payload && !action?.payload?.error) {
      state.changeSubscriptionState.statusCode = action?.payload?.status;
      state.changeSubscriptionState.message = action?.payload?.data?.message;
    }

    if (action?.payload?.status === 403) {
      state.changeSubscriptionState.message = 'You are not authorize to access this resource';
    }

    if (!action?.payload) {
      state.changeSubscriptionState.message = 'Something went wrong while processing your request.';
    }

    if (action?.payload?.error) {
      const statusMessage = action?.payload?.error?.data;
      state.changeSubscriptionState.statusCode = action?.payload?.error?.status;
      state.changeSubscriptionState.message = statusMessage?.title || statusMessage?.message;
      state.changeSubscriptionState.error = action.payload.error;
    } else {
      state.changeSubscriptionState.success = true;
      state.changeSubscriptionState.serviceType = action.payload.data.data.serviceTypeCode;
    }
  },
};

export { changeSubscriptionAsync, changeSubscriptionExtraReducers };
