import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import WebApiRoutes from '@config/Routes/WebApiRoutes';
import WebApiGeneralRoutes from '@config/Routes/WebApiGeneralRoutes';
import { internals } from '@azure/msal-browser';

const baseQuery = fetchBaseQuery({
  baseUrl: WebApiRoutes.getBaseUrl(),
});

const JobStatusActivities = createApi({
  reducerPath: 'jobStatusActivities',
  baseQuery,
  endpoints: (builder) => ({
    getJobStatuses: builder.mutation({
      query: ({ accessToken, pageNumber, pageSize, filters }) => {
        const searchById = /^\d+$/.test(filters.search) ? Number.parseInt(filters.search, 10) : 0;
        const fullTextSearch = searchById ? '' : filters.search;
        return {
          url: WebApiGeneralRoutes.apiV1Path(`/job-statuses`, {
            pageNumber,
            pageSize,
          }),
          method: 'POST',
          headers: { Authorization: `Bearer ${accessToken}` },
          body: {
            'search-by-id': searchById,
            'full-text-search': fullTextSearch,
            filters: [
              {
                field: 'filter_problem',
                value: filters.problem || '',
              },
              {
                field: 'filter_jobType',
                value: filters.jobType || '',
              },
            ],
          },
        };
      },
    }),
  }),
});

export const { useGetJobStatusesMutation } = JobStatusActivities;

export default JobStatusActivities;
