import {
  numberOfOrganizationListPath,
  numberOfSubResellersListPath,
  numberOfUsersListPath,
  resellersListPath,
  showUsersListPath,
} from '@config/Routes/WebClientRoutes';

export default function useDataService() {
  const buttonStyle = {
    height: 36,
    backgroundColor: '#a5a5a5',
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      backgroundColor: '#a5a5a5',
    },
    textTransform: 'unset',
    fontSize: 14,
  };

  const columnNames = [
    { id: 1, value: 'id', title: 'ID' },
    { id: 2, value: 'partner_type', title: 'Partner Type' },
    { id: 3, value: 'name', title: 'Name' },
    { id: 4, value: 'country', title: 'Country' },
    { id: 5, value: 'parent', title: 'Parent' },
    { id: 6, value: 'owner_email', title: 'Owner Email' },
    { id: 7, value: 'integration_type', title: 'Integration Type' },
    { id: 8, value: 'created_on', title: 'Created On' },
  ];

  const integrationTypes = [
    { id: 1, value: 'Normal Dme/ARPP', title: 'Normal Dme/ARPP' },
    { id: 2, value: 'APS', title: 'APS' },
    { id: 3, value: 'PSA', title: 'PSA' },
    { id: 4, value: 'API', title: 'API' },
    { id: 5, value: 'C-panel', title: 'C-panel' },
  ];

  const salesOwnersList = [
    { id: 1, value: 'weyman', title: 'Weyman' },
    { id: 2, value: 'austin', title: 'Austin' },
    { id: 3, value: 'udit', title: 'Udit' },
    { id: 4, value: 'marco', title: 'Marco' },
    { id: 5, value: 'ridley', title: 'Ridley' },
    { id: 6, value: 'misuzu', title: 'Misuzu' },
    { id: 7, value: 'ray', title: 'Ray' },
    { id: 8, value: 'scott', title: 'Scott' },
    { id: 9, value: 'jordon', title: 'Jordon' },
    { id: 10, value: 'olivia', title: 'Olivia' },
    { id: 11, value: 'jamie', title: 'Jamie' },
    { id: 12, value: 'maria', title: 'Maria' },
    { id: 12, value: 'osh', title: 'Osh' },
  ];

  const statusMenuItems = [
    { id: 1, value: 'active', title: 'Active' },
    { id: 2, value: 'inactive', title: 'Inactive' },
    { id: 3, value: 'test', title: 'Test' },
  ];

  const clickableCoulmnList = [
    { id: 1, column: 'name', path: (row) => [resellersListPath, `id=${row.id}&name=${row.name}`] },
    {
      id: 2,
      column: 'numberOfUsers',
      path: (row) => [resellersListPath, `id=${row.id}&name=${row.name}&tab=${4}`],
      tab: 4,
    },
    {
      id: 3,
      column: 'numberOfSubResellers',
      path: (row) => [resellersListPath, `id=${row.id}&name=${row.name}&tab=${3}`],
      tab: 3,
    },
    {
      id: 4,
      column: 'numberOfOrganisations',
      path: (row) => [resellersListPath, `id=${row.id}&name=${row.name}&tab=${5}`],
      tab: 5,
    },
    {
      id: 5,
      column: 'parent',
      path: (row) => [resellersListPath, `id=${row.parentId}&name=${row.parent}`],
    },
  ];

  const names = ['Distributor', 'Sub-Reseller', 'Direct-Reseller'];

  const modalNames = {
    BulkCreateOrganization: 'bulkCreateOrganization',
    BulkCreateSubReseller: 'bulkCreateSubReseller',
  };

  return {
    names,
    statusMenuItems,
    salesOwnersList,
    columnNames,
    buttonStyle,
    integrationTypes,
    clickableCoulmnList,
    modalNames,
  };
}
