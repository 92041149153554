import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import WebApiRoutes from '@config/Routes/WebApiRoutes';
import WebApiGeneralRoutes from '@config/Routes/WebApiGeneralRoutes';

const baseQuery = fetchBaseQuery({
  baseUrl: WebApiRoutes.getBaseUrl(),
});

const SetupHostedExchangeApi = createApi({
  reducerPath: 'setupHostedExchangeApi',
  baseQuery,
  endpoints: (builder) => ({
    addHostedExchange: builder.mutation({
      query: ({ parameters, accessToken }) => ({
        url: WebApiGeneralRoutes.apiV1Path('/setup-hosted-exchange'),
        method: 'POST',
        body: parameters,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    }),
    workerJobItems: builder.query({
      query: ({ jobId, accessToken }) => ({
        url: WebApiGeneralRoutes.apiV1Path(`/setup-hosted-exchange/${jobId}/items`),
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    }),
    adminTemplate: builder.query({
      query: ({ accessToken }) => ({
        url: WebApiGeneralRoutes.apiV1Path(`/setup-hosted-exchange/admin-template`),
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
        responseHandler: async (response) => {
          return response.text();
        },
      }),
    }),
    mailboxTemplate: builder.query({
      query: ({ accessToken }) => ({
        url: WebApiGeneralRoutes.apiV1Path(`/setup-hosted-exchange/mailbox-template`),
        method: 'GET',
        headers: { Authorization: `Bearer ${accessToken}` },
        responseHandler: async (response) => {
          return response.text();
        },
      }),
    }),
    validateOrganization: builder.mutation({
      query: ({ orgOwnerId, accessToken }) => ({
        url: WebApiGeneralRoutes.apiV1Path(`/organization-details`),
        method: 'POST',
        params: { ownerId: orgOwnerId },
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    }),
  }),
});

export const {
  useAddHostedExchangeMutation,
  useWorkerJobItemsQuery,
  useLazyAdminTemplateQuery,
  useLazyMailboxTemplateQuery,
  useValidateOrganizationMutation,
} = SetupHostedExchangeApi;

export default SetupHostedExchangeApi;
