import useUrlQuery from '@hooks/useUrlQuery';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  QboCard,
  QboCardListItem,
  QboFormGroup,
  QboPagination,
  QboSimpleDataTable,
  QboTextField,
} from '@ui/Components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QboSortByButton from '@ui/Components/QboSortByButton';
import { useGetJobStatusesMutation } from '@services/WebApiService/JobStatusActivities';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import {
  buttonStyle,
  JOB_STATUS_COLUMNS,
  jobTypeItems,
  problemItems,
} from './pageHooks/constants/jobStatusConstants';

const defaultPagination = { pageNumber: 1, pageSize: 10 };

const defaultFilters = {
  search: '',
  problem: '',
  jobType: '',
};

export default function JobStatusActivitiesPage() {
  const { t } = useTranslation();
  const { query, setQuery } = useUrlQuery();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);

  // mutation
  const [getJobStatuses, getJobStatusesState] = useGetJobStatusesMutation();
  const { isLoading, rows, errorMessage, statusCode, responsePagination } = useMemo(
    () => ({
      isLoading: getJobStatusesState.isLoading,
      rows: getJobStatusesState.data?.data ?? [],
      errorMessage: getJobStatusesState.error?.data?.message,
      statusCode: getJobStatusesState.error?.status,
      responsePagination: JSON.parse(getJobStatusesState.data?.pagination || '{}'),
    }),
    [getJobStatusesState]
  );
  const [fetchFlag, setFetchFlag] = useState(true);

  // pagination
  const [paginationState, setPaginationState] = useState(defaultPagination);
  const resetPagination = () => setPaginationState(defaultPagination);
  const handleChangePage = (newPage) => {
    setPaginationState({ pageNumber: newPage, pageSize: paginationState.pageSize });
  };
  const handleChangePageSize = (newPageSize) => {
    setPaginationState({ pageNumber: 1, pageSize: newPageSize });
  };
  useEffect(() => {
    setFetchFlag(true);
  }, [paginationState]);

  // filters
  const queryFilters = useMemo(
    () => ({
      search: query.search || '',
      problem: problemItems.find((i) => i.value === query.problem)?.value || '',
      jobType: jobTypeItems.find((i) => i.value === query.jobType)?.value || '',
    }),
    [query]
  );
  const [filterState, setFilterState] = useState(queryFilters);
  const handleFilterChange = useCallback(
    (key, value) => {
      setFilterState({ ...filterState, [key]: value });
    },
    [filterState]
  );
  const resetFilters = useCallback(() => {
    setQuery({
      ...query,
      ...defaultFilters,
    });
    setFilterState(defaultFilters);
    resetPagination();
    setFetchFlag(true);
  }, [query]);
  const applyFiltersToQuery = useCallback(() => {
    setQuery({
      ...query,
      ...filterState,
    });
  }, [query, filterState]);

  // fetch handler
  const fetch = useCallback(() => {
    getJobStatuses({
      accessToken: accessTokenSet,
      pageNumber: paginationState.pageNumber,
      pageSize: paginationState.pageSize,
      filters: filterState,
    });
  }, [accessTokenSet, paginationState, filterState]);
  useEffect(() => {
    if (!fetchFlag) return;
    fetch();
    setFetchFlag(false);
  }, [fetchFlag, fetch]);

  // handlers
  const handleOnSearchClick = useCallback(
    (e) => {
      e.preventDefault();
      applyFiltersToQuery();
      resetPagination();
      setFetchFlag(true);
    },
    [applyFiltersToQuery, resetPagination]
  );

  return (
    <>
      <HeaderPageBlock>Job Status</HeaderPageBlock>
      <QboCard isMainWrapper>
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={resetFilters}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <QboTextField
            id="search"
            value={filterState.search}
            isWidthSetExplicit
            placeholder="Job ID/Agent ID"
            inputProps={{ maxLength: 225 }}
            sx={{ marginBottom: 2 }}
            onChange={(e) => handleFilterChange('search', e.currentTarget.value)}
          />
          <div>
            <QboSortByButton
              defaultValue="All"
              marginRightValue={2}
              value={filterState.jobType}
              buttonWidth={220}
              startAdornmentTextWidth={140}
              startAdornmentText="Job Type"
              handleChange={(e) => {
                handleFilterChange('jobType', e.target.value);
              }}
              menuItemList={jobTypeItems}
            />
            <QboSortByButton
              defaultValue="All"
              marginRightValue={2}
              value={filterState.problem}
              buttonWidth={220}
              startAdornmentTextWidth={70}
              startAdornmentText="Problem"
              handleChange={(e) => {
                handleFilterChange('problem', e.target.value);
              }}
              menuItemList={problemItems}
            />
          </div>
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        {(rows.length > 0 || isLoading) && (
          <QboSimpleDataTable
            sx={{ width: '100%' }}
            rows={rows}
            header={JOB_STATUS_COLUMNS}
            loading={isLoading}
          />
        )}
        {(rows.length > 0 || isLoading) && (
          <QboCardListItem
            justifyContent="right"
            alignItems="center"
            style={{ paddingRight: '1.5em' }}
            rightContent={
              <QboPagination
                hasNextLink={responsePagination?.HasNext}
                hasPrevLink={responsePagination?.HasPrevious}
                onClickPrevLink={() => {
                  const newPageNumber = paginationState.pageNumber - 1;
                  handleChangePage(newPageNumber);
                }}
                onClickNextLink={() => {
                  const newPageNumber = paginationState.pageNumber + 1;
                  handleChangePage(newPageNumber);
                }}
                onChangePageNumber={handleChangePageSize}
                pageNumber={paginationState.pageSize}
                paginationLabel="Page size"
              />
            }
          />
        )}

        {!rows?.length &&
          !isLoading &&
          (statusCode === 500 ? (
            <NoStatusSection
              forbiddenErrorMessage={{ heading: t('error'), caption: errorMessage }}
            />
          ) : (
            <NoStatusSection forbiddenErrorMessage={errorMessage} />
          ))}
      </QboCard>
    </>
  );
}
