import { createSlice } from '@reduxjs/toolkit';
import {
  getChangeSubscriptionPlansAsync,
  getChangeSubscriptionPlansExtraReducers,
} from './serviceActions/getChangeSubscriptionPlansAsync';

const initialState = {
  getChangeSubscriptionPlansState: {
    fetching: false,
    statusCode: null,
    list: [],
    errorMessage: null,
    pagination: {},
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
  },
};

const getChangeSubscriptionPlansSlice = createSlice({
  name: 'getChangeSubscriptionPlans',
  initialState,
  reducers: {
    resetGetChangeSubscriptionPlansState(state) {
      state.getChangeSubscriptionPlansState = initialState.getChangeSubscriptionPlansState;
    },
  },
  extraReducers: {
    ...getChangeSubscriptionPlansExtraReducers,
  },
});

export { getChangeSubscriptionPlansAsync };

export const { resetGetChangeSubscriptionPlansState } = getChangeSubscriptionPlansSlice.actions;
export default getChangeSubscriptionPlansSlice.reducer;
