import { constant } from '@config/BaseSetting';
import useDownload from '@hooks/useDownload';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useLocalStorage from '@hooks/useLocalStorage';
import {
  useLazyAdminTemplateQuery,
  useLazyMailboxTemplateQuery,
} from '@services/WebApiService/SetupHostedExchange';
import { QboButton, QboCard, QboSnackbar, QboTypography } from '@ui/Components';
import React, { useState } from 'react';

export default function SetupHostedExhangeTemplates() {
  const { hasPermission, featureFlags } = useFeatureFlag();
  const [accessToken] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [snackbarState, setSnackbarState] = useState({ open: false });

  const [
    fetchAdminTemplate,
    { data: adminTemplate, error: errorAdmin, isLoading: isAdminLoading },
  ] = useLazyAdminTemplateQuery();

  const [
    fetchMailboxTemplate,
    { data: mailboxTemplate, error: errorMailbox, isLoading: isMailboxLoading },
  ] = useLazyMailboxTemplateQuery();

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleDownloadAdminTemplate = async () => {
    await fetchAdminTemplate({ accessToken }).unwrap();
  };

  const handleDownloadMailboxTemplate = async () => {
    await fetchMailboxTemplate({ accessToken });
  };

  const handleResetExport = () => {};

  useDownload(
    adminTemplate,
    'text/csv;charset=utf-8',
    'setup-admin-hosted-exchange-template.csv',
    handleResetExport,
    () => setSnackbarState({ open: true, type: 'success', message: 'Successfully downloaded' }),
    (err) => setSnackbarState({ open: true, type: 'error', message: err })
  );

  useDownload(
    mailboxTemplate,
    'text/csv;charset=utf-8',
    'setup-mailbox-hosted-exchange-template.csv',
    handleResetExport,
    () => setSnackbarState({ open: true, type: 'success', message: 'Successfully downloaded' }),
    (err) => setSnackbarState({ open: true, type: 'error', message: err })
  );

  if (!hasPermission(featureFlags.SetupHostedExchange)) return null;

  const fetching = isAdminLoading || isMailboxLoading;

  return (
    <>
      <QboSnackbar onClose={handleSnackbarClose} {...snackbarState} />
      <QboCard isMainWrapper sx={{ paddingBottom: '20px' }}>
        <QboTypography
          sx={{
            fontSize: 18,
            color: '#000000DE',
            fontWeight: 600,
            marginBottom: '8px',
          }}>
          Templates
        </QboTypography>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <QboButton
            variant="contained"
            onClick={handleDownloadAdminTemplate}
            loading={isAdminLoading}>
            Setup Admin Template
          </QboButton>
          <QboButton
            variant="contained"
            onClick={handleDownloadMailboxTemplate}
            loading={isMailboxLoading}>
            Setup Mailbox Template
          </QboButton>
        </div>
      </QboCard>
    </>
  );
}
