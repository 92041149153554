import { organizationInformationPath, resellersListPath } from '@config/Routes/WebClientRoutes';

export const EMAIL_STATUS = 'email';
export const EMAIL_STATUS_COLUMNS = [
  { label: 'ID', columnName: 'backupId' },
  { label: 'Email Account ID', columnName: 'userBackupId' },
  {
    label: 'Email',
    columnName: 'email',
    size: 'sm',
  },
  {
    label: 'Organization Name',
    columnName: 'organizationName',
    size: 'sm',
    navigateURL: (row) =>
      `${organizationInformationPath}?id=${row.organizationOwnerId}&name=${row.organizationName}`,
  },
  {
    label: 'Partner Name',
    columnName: 'partnerName',
    size: 'sm',
    navigateURL: (row) => `${resellersListPath}?id=${row.partnerId}&name=${row.partnerName}`,
  },
  { label: 'Backup Status', columnName: 'status', size: 'sm' },
  { label: 'Additional Status', columnName: 'additionalStatus', size: 'sm' },
  { label: 'Start Backup Time', columnName: 'startTime', size: 'sm' },
  { label: 'End Backup Time', columnName: 'endTime', size: 'sm' },
  { label: 'Elapsed Time', columnName: 'elapsedTime', size: 'sm' },
  { label: 'Email Account Deactivated Since', columnName: 'deactivatedSince', size: 'sm' },
  { label: 'Email Account Deactivated By', columnName: 'deactivatedBy', size: 'sm' },
  { label: 'Backup Storage KB', columnName: 'backupStorage', size: 'sm' },
  { label: 'Compressed Storage', columnName: 'compressedStorage', size: 'sm' },
  { label: 'Current Message Numbers', columnName: 'currentMessagesCount', size: 'sm' },
  { label: 'Total Message Numbers', columnName: 'totalMessagesCount', size: 'sm' },
  { label: 'Retries Time', columnName: 'retries', size: 'sm' },
  { label: 'Error Messages', columnName: 'errorMessage', size: 'sm' },
  { label: 'Skip Messages', columnName: 'skipMessages', size: 'sm' },
  { label: 'XML Response', columnName: 'lastXMLResponse', size: 'sm' },
  { label: 'Run on Machine IP', columnName: 'machineIP', size: 'sm' },
  { label: 'Created At', columnName: 'createdAt', size: 'sm' },
  { label: 'Last Data Updated At', columnName: 'updatedAt', size: 'sm' },
];

export const systemStatusItems = [
  {
    id: 1,
    value: 'completed',
    title: 'Completed',
  },
  {
    id: 2,
    value: 'running',
    title: 'Running',
  },
  {
    id: 3,
    value: 'error',
    title: 'Error',
  },
];

export const elapsedItems = [
  {
    id: 1,
    value: '24',
    title: 'More than 24 hours',
  },
];

export const buttonStyle = {
  height: 36,
  backgroundColor: '#a5a5a5',
  textDecoration: 'none',
  color: 'black',
  '&:hover': {
    backgroundColor: '#a5a5a5',
  },
  textTransform: 'unset',
  fontSize: 14,
};
