import React from 'react';
import PropTypes from 'prop-types';
import { ITBox, QboLabelGroup } from '@ui/Components';
import { Grid } from '@mui/material';

export default function ToggleMFAConfirmation({ selectedAccount }) {
  if (!selectedAccount) return null;

  return (
    <ITBox
      className="TransferSubresellerPage__information-box"
      direction="column"
      sx={{ marginBottom: '20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <QboLabelGroup title="Account ID" description={selectedAccount?.id?.toString() || '-'} />
        </Grid>
        <Grid item xs={6}>
          <QboLabelGroup
            title="Current 2FA status"
            description={selectedAccount?.mfaStatusText?.toString() || '-'}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <QboLabelGroup title="Name" description={selectedAccount?.name?.toString() || '-'} />
        </Grid>
        <Grid item xs={6}>
          <QboLabelGroup title="Email" description={selectedAccount?.email?.toString() || '-'} />
        </Grid>
      </Grid>

      {(!!selectedAccount?.organizationOwnerId || !!selectedAccount?.organizationName) && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <QboLabelGroup
              title="Organization Owner ID"
              description={selectedAccount?.organizationOwnerId?.toString() || '-'}
            />
          </Grid>
          <Grid item xs={6}>
            <QboLabelGroup
              title="Organization Name"
              description={selectedAccount?.organizationName || '-'}
            />
          </Grid>
        </Grid>
      )}

      {(!!selectedAccount?.partnerId || !!selectedAccount?.partnerName) && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <QboLabelGroup
              title="Partner ID"
              description={selectedAccount?.partnerId?.toString() || '-'}
            />
          </Grid>
          <Grid item xs={6}>
            <QboLabelGroup
              title="Partner Organization Name"
              description={selectedAccount?.partnerName?.toString() || '-'}
            />
          </Grid>
        </Grid>
      )}
    </ITBox>
  );
}

ToggleMFAConfirmation.propTypes = {
  selectedAccount: PropTypes.object,
};

ToggleMFAConfirmation.defaultProps = {
  selectedAccount: null,
};
