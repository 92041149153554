import { createSlice } from '@reduxjs/toolkit';
import {
  changeSubscriptionAsync,
  changeSubscriptionExtraReducers,
} from './serviceActions/changeSubscriptionAsync';

const initialState = {
  changeSubscriptionState: {
    fetching: false,
    statusCode: null,
    error: null,
    message: null,
    errorMessage: null,
    forbiddenState: false,
    forbiddenErrorMessage: null,
    corsErrorMessage: null,
    serviceType: null,
  },
};

const changeSubscriptionSlice = createSlice({
  name: 'changeSubscription',
  initialState,
  reducers: {
    resetChangeSubscriptionState(state) {
      state.changeSubscriptionState = initialState.changeSubscriptionState;
    },
  },
  extraReducers: {
    ...changeSubscriptionExtraReducers,
  },
});

export { changeSubscriptionAsync };

export const { resetChangeSubscriptionState } = changeSubscriptionSlice.actions;
export default changeSubscriptionSlice.reducer;
