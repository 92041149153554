import React, { useEffect, useState, useRef, useMemo } from 'react';
import { showPartnerListPath } from '@config/Routes/WebClientRoutes';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import { QboBreadcrumbs, QboCard, QboTabs, QboTypography } from '@ui/Components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  setOrganizationTabState,
  setPartnerDetailsState,
  setPlanTabState,
  setTabState,
  setUserTabState,
} from '@features/application/applicationSlice';
import ResellerNotificationsListPage from '@pages/ResellersPage/ResellerNotificationsListPage';
import ManageLogPage from '@pages/ManageLogPage';
import PlansListPage from '@pages/PlansListPage';
import OrganizationListPage from '@pages/OrganizationListPage';
import UserListPage from '@pages/UsersListPage';
import ResellersPage from '@pages/ResellersPage';
import SubResellerPage from '@pages/SubResellerPage';
import { resellerLogType } from '@pages/ManageLogPage/pageHooks/useManageLog';
import InvoicesListPage from '@pages/InvoicesListPage';
import useFeatureFlag from '@hooks/useFeatureFlag';
import useUrlQuery from '@hooks/useUrlQuery';
import IntegrationPage from '@pages/IntegrationPage';

function TextWithLabel({ title, description, color }) {
  return (
    <div style={{ display: 'flex', marginTop: 15 }}>
      <QboTypography sx={{ fontSize: 13, fontWeight: 700 }}>{title}</QboTypography>
      <QboTypography wrap sx={{ fontSize: 13, color: { color } }}>
        &nbsp;{description}
      </QboTypography>
    </div>
  );
}

TextWithLabel.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.string,
};

TextWithLabel.defaultProps = {
  title: '',
  color: '',
  description: '',
};

export default function ResellerTabs() {
  const { query, setQuery } = useUrlQuery();
  const { partnerDetailsState } = useSelector((state) => state.application);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedTabState } = useSelector((state) => state.application);
  const previousPropsRef = useRef(null);
  const previousResellerPropsRef = useRef(null);
  const { featureFlags, hasPermission } = useFeatureFlag();

  const { getSelectedResellerConfigsState } = useSelector(
    (state) => state.selectedResellerConfigsState
  );

  const { row } = partnerDetailsState;

  useEffect(() => {
    const original = row?.row?.original || row;
    setQuery({
      ...query,
      id: query.id || original?.id,
      name: query.name || original?.name,
    });
  }, [row]);

  const current = useMemo(() => {
    const original = row?.row?.original || row;
    return {
      ...original,
      id: query.id || original?.id,
      name: query.name || original?.name,
    };
  }, [row, query]);

  const names = [
    'Settings',
    'Plans',
    'Notifications',
    'Sub-Resellers',
    'Users',
    'Organisations',
    'Logs',
    'Invoices',
    'Integrations',
  ];

  const selectTab = useMemo(() => {
    const i = parseInt(query.tab, 10);
    return Number.isNaN(i) || i >= names.length ? 0 : i;
  }, [query.tab]);

  const handleOnTabClick = (e, newValue) => {
    // dispatch(setTabState(newValue));
    // setSelectTab(newValue);
    setQuery({ ...query, tab: newValue || undefined });
  };

  useEffect(() => {
    if (selectedTabState.tabState) {
      // setSelectTab(selectedTabState.tabState);
      setQuery({ ...query, tab: selectedTabState.tabState });
    }
  }, [selectedTabState.tabState]);

  useEffect(() => {
    if (!current.id || !current.name) {
      navigate(showPartnerListPath);
    }
  }, [current]);

  const handleOnBreadcrumbClick = () => {
    setQuery({});
    dispatch(setPartnerDetailsState({}));
    dispatch(setPlanTabState(null));
    dispatch(setOrganizationTabState(null));
    dispatch(setUserTabState(null));
  };

  const yourComponent = (namesArray, selectTabState, originalState) => {
    if (namesArray[selectTabState] === 'Plans') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <PlansListPage
            resellerID={originalState?.id}
            tabFlag={!false}
            selectTab={selectTab}
            plan={originalState?.plan}
          />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Organisations') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <OrganizationListPage
            resellerID={originalState?.id}
            tabFlag={!false}
            selectTab={selectTab}
            plan={(originalState?.planId && originalState?.planName) || ''}
            resellerIDProp={previousResellerPropsRef}
          />
        </QboCard>
      );
    }
    if (namesArray[selectTabState] === 'Users') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <UserListPage resellerID={originalState?.id} tabFlag={!false} selectTab={selectTab} />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Settings') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <ResellersPage
            resellerID={originalState?.id}
            resellerName={originalState?.name}
            parentId={originalState?.parentId}
          />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Notifications') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <ResellerNotificationsListPage resellerID={originalState?.id} />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Sub-Resellers') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <SubResellerPage
            resellerID={originalState?.id}
            tabFlag={!false}
            selectTab={selectTab}
            resellerIDProp={previousPropsRef}
          />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Logs') {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <ManageLogPage type={resellerLogType} id={originalState?.id} />
        </QboCard>
      );
    }

    if (namesArray[selectTabState] === 'Invoices' && hasPermission(featureFlags.InvoicesList)) {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <InvoicesListPage resellerId={originalState?.id} />
        </QboCard>
      );
    }
    if (
      namesArray[selectTabState] === 'Integrations' &&
      hasPermission(featureFlags.PsaIntegration)
    ) {
      return (
        <QboCard noPadding isBordered sx={{ marginTop: 2 }}>
          <IntegrationPage resellerId={originalState?.id} />
        </QboCard>
      );
    }

    return (
      <QboCard sx={{ marginTop: 2 }} isCentered isBordered>
        <HeaderPageBlock>
          The {namesArray[selectTabState] || namesArray[0]} feature will be available soon.
        </HeaderPageBlock>
      </QboCard>
    );
  };

  return (
    <>
      <HeaderPageBlock>Partner Information</HeaderPageBlock>
      <QboBreadcrumbs sx={{ marginBottom: 2 }}>
        <Link
          onClick={() => handleOnBreadcrumbClick()}
          to={showPartnerListPath}
          style={{
            color: '#1c63d5',
            fontSize: '1.5rem',
            textDecoration: 'underline',
          }}>
          Partners
        </Link>
        <QboTypography color="text.primary" style={{ fontSize: '1.5rem' }}>
          Partner Information
        </QboTypography>
      </QboBreadcrumbs>
      {current?.name && current?.id && (
        <QboCard isMainWrapper>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
            <QboTypography variant="h5" wrap sx={{ fontSize: 18 }}>
              {current.name}
            </QboTypography>
            <QboTypography variant="h5" sx={{ marginLeft: 1, fontSize: 16 }}>
              (ID: {current.id})
            </QboTypography>
          </div>

          <div
            style={{
              overflowX: 'auto',
              marginRight: 5,
              display: 'flex',
              scrollbarWidth: 'thin',
              backgroundColor: '#c4e3f0',
            }}>
            <QboTabs tabIndex={selectTab} title={names} onChange={handleOnTabClick} />
          </div>

          {yourComponent(names, selectTab, current)}
        </QboCard>
      )}
    </>
  );
}
