import { useState } from 'react';
import * as yup from 'yup';

const descriptionName = 'description';
const supportTicketName = 'supportTicket';
const escalationTicketName = 'escalationTicket';
const setupTypeName = 'setupType';
const organizationIdName = 'organizationId';
const emailName = 'email';
const ewsUrlName = 'ewsUrl';
const passwordName = 'password';
const csvAdminValid = 'csvAdminValid';
const csvMailboxValid = 'csvMailboxValid';

const InitialForm = {
  [descriptionName]: '',
  [supportTicketName]: '',
  [escalationTicketName]: '',
  [setupTypeName]: '',
  [organizationIdName]: '',
  [emailName]: '',
  [ewsUrlName]: '',
  [passwordName]: '',
  [csvAdminValid]: false,
  [csvMailboxValid]: false,
};

export default function useSetupHostedExchagFormValidation() {
  const [isValid, setIsValid] = useState(false);
  const [errors, setErrors] = useState('');
  const [formData, setFormData] = useState(InitialForm);

  const formSchema = yup.object().shape({
    [csvAdminValid]: yup
      .string()
      .nullable()
      .when(setupTypeName, {
        is: (value) => {
          return value === 'bulk_setup_admin';
        },
        then: () => yup.boolean().isTrue('Please upload a valid CSV file'),
      }),
    [csvMailboxValid]: yup
      .string()
      .nullable()
      .when(setupTypeName, {
        is: (value) => {
          return value === 'bulk_setup_mailbox';
        },
        then: () => yup.boolean().isTrue('Please upload a valid CSV file'),
      }),
    [supportTicketName]: yup.string().required('Support Ticket is required field'),
    [setupTypeName]: yup.string().required('Setup Type is required field'),
    [organizationIdName]: yup
      .string()
      .nullable()
      .when(setupTypeName, {
        is: (value) => {
          return ['setup_admin', 'setup_mailbox'].includes(value);
        },
        then: () => yup.string().required('Organization is required field'),
      }),
    [emailName]: yup
      .string()
      .nullable()
      .when(setupTypeName, {
        is: (value) => {
          return ['setup_admin', 'setup_mailbox'].includes(value);
        },
        then: () => yup.string().required('Email is required field'),
      }),
    [ewsUrlName]: yup
      .string()
      .nullable()
      .when(setupTypeName, {
        is: (value) => {
          return value === 'setup_admin';
        },
        then: () => yup.string().required('URL is required field'),
      }),
    [passwordName]: yup
      .string()
      .nullable()
      .when(setupTypeName, {
        is: (value) => {
          return value === 'setup_admin';
        },
        then: () => yup.string().required('Password is required field'),
      }),
  });

  const validate = async (value) => {
    await formSchema.validate(value, { abortEarly: false }).catch((err) => {
      const errorLogs = err.inner.reduce((acc, error) => {
        return {
          ...acc,
          [error.path]: error.message,
        };
      }, {});
      setErrors(errorLogs);
      return errorLogs;
    });
    const validation = await formSchema.isValid(value);
    if (validation) {
      setErrors({});
    }
    setIsValid(validation);
    return validation;
  };

  const validateAll = () => {
    validate(formData);
  };

  const updateFormData = (newData) => {
    setFormData((prev) => {
      return { ...prev, ...newData };
    });
  };

  const supportTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [supportTicketName]: value });
    },
    errorMessage: errors[supportTicketName],
    value: formData[supportTicketName],
  };

  const descriptionAttributes = {
    handleChange: async (value) => {
      updateFormData({ [descriptionName]: value });
    },
    value: formData[descriptionName],
  };

  const escalationTicketAttributes = {
    handleChange: async (value) => {
      updateFormData({ [escalationTicketName]: value });
    },
    value: formData[escalationTicketName],
  };

  const setupTypeAttributes = {
    handleChange: async (value) => {
      updateFormData({ [setupTypeName]: value });
    },
    errorMessage: errors[setupTypeName],
    value: formData[setupTypeName],
  };

  const organizationIdAttributes = {
    handleChange: async (value) => {
      updateFormData({ [organizationIdName]: value });
    },
    errorMessage: errors[organizationIdName],
    value: formData[organizationIdName],
  };

  const emailAttributes = {
    handleChange: async (value) => {
      updateFormData({ [emailName]: value });
    },
    errorMessage: errors[emailName],
    value: formData[emailName],
  };

  const ewsUrlAttributes = {
    handleChange: async (value) => {
      updateFormData({ [ewsUrlName]: value });
    },
    errorMessage: errors[ewsUrlName],
    value: formData[ewsUrlName],
  };

  const passwordAttributes = {
    handleChange: async (value) => {
      updateFormData({ [passwordName]: value });
    },
    errorMessage: errors[passwordName],
    value: formData[passwordName],
  };

  const csvAdminValidAttributes = {
    handleChange: async (value) => {
      updateFormData({ [csvAdminValid]: value });
    },
    errorMessage: errors[csvAdminValid],
    value: formData[csvAdminValid],
  };

  const csvMailboxValidAttributes = {
    handleChange: async (value) => {
      updateFormData({ [csvMailboxValid]: value });
    },
    errorMessage: errors[csvMailboxValid],
    value: formData[csvMailboxValid],
  };

  const resetForm = () => {
    setFormData(InitialForm);
    setErrors({});
    setIsValid(false);
  };

  return {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    setupTypeAttributes,
    organizationIdAttributes,
    emailAttributes,
    ewsUrlAttributes,
    passwordAttributes,
    csvAdminValidAttributes,
    csvMailboxValidAttributes,
    validateAll,
    isValid,
    validate,
    resetForm,
  };
}
