const { default: useFormValidation } = require('./useFormValidation');

function useForm() {
  const {
    formData,
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    mfaStatusAttributes,
    resetMfaAttributes,
    validateAll,
    isValid,
    resetForm,
  } = useFormValidation();

  const attributes = {
    supportTicketAttributes,
    escalationTicketAttributes,
    descriptionAttributes,
    mfaStatusAttributes,
    resetMfaAttributes,
  };

  const onValidateData = (e) => {
    e.preventDefault();
    validateAll();
  };

  return {
    attributes,
    isValid,
    resetForm,
    onValidateData,
  };
}

export default useForm;
