import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  EmailOutlined as EmailOutlinedIcon,
  CloudSyncOutlined,
  ContactMail,
  Backup,
  Receipt,
  CloudDownload,
  Restore,
  MoveDown,
  CloudSync,
  ManageHistory,
} from '@mui/icons-material';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import BackupIcon from '@mui/icons-material/Backup';
import PlusOneIcon from '@mui/icons-material/PlusOne';
import ToggleOn from '@mui/icons-material/ToggleOn';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import MicrosoftIcon from '@mui/icons-material/Microsoft';
import GoogleIcon from '@mui/icons-material/Google';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import ListIcon from '@mui/icons-material/List';
import GroupsIcon from '@mui/icons-material/Groups';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';

import {
  mailBoxChangePath,
  mailBoxSyncPath,
  viewAutoDiscoveryPath,
  viewExcludedMailBoxPath,
  domainBackupsPath,
  seatCountChangePath,
  viewTenantAuthErrorsPath,
  switchSPATypePath,
  recreateBackupAdminPath,
  changeBackupAdminPath,
  seatUsageReportsPagePath,
  monthlyBackupReportsPagePath,
  bulkPurgePath,
  purgeExternalAccountPath,
  purgeSharedDrivePath,
  m365TenantListPath,
  gwsTenantListPath,
  showOrganizationListPath,
  showUsersListPath,
  showPartnerListPath,
  transferPath,
  transferSubscriptionPagePath,
  transferSubresellerPagePath,
  organizationInformationPath,
  resellersListPath,
  showPlansListPath,
  bulkPurgeListPath,
  purgeM365TenantPath,
  m365TenantDetailPath,
  purgeGWSTenantPath,
  userBackupInfoPath,
  userBackupDetailPath,
  gwsTenantDetailPath,
  hexTenantListPath,
  dataIngestionPath,
  dataIngestionListPath,
  hexTenantDetailPath,
  switchCredentialsPath,
  showOrganizationUsersListPath,
  backupStatusesPath,
  downloadStatusesPath,
  invoicesListPath,
  restoreStatusesPath,
  migrateStatusesPath,
  jobStatusActivitiesPath,
} from '@config/Routes/WebClientRoutes';
import {
  setPurgeSublink,
  setSeatUsageSublink,
  setTransferSublink,
  setPartnerManagementLink,
  setTransferlink,
  setToolLink,
  setBillingLink,
  setOrganizationLink,
  setPartnerLink,
  setTenantLink,
  setSystemStatusLink,
} from '@features/application/applicationSlice';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';

export default function useMenuBlockService() {
  const [activeMenu] = useLocalStorage(constant.MENU_STORAGE);
  const [activeMenu1] = useLocalStorage(constant.MENUS_FLAG_STATE);
  const dispatch = useDispatch();

  const {
    seatUsageSublink,
    purgeSublink,
    partnerManagementLink,
    toolLink,
    billingLink,
    organizationLink,
    partnerLink,
    tenantLink,
    systemStatusLink,
  } = useSelector((state) => state.application);

  const toolsObjects = [
    {
      path: mailBoxChangePath,
      title: 'Mailbox Change',
      icon: <EmailOutlinedIcon className="Icon__default" />,
    },
    {
      path: mailBoxSyncPath,
      title: 'Mailbox Change(O365)',
      icon: <EmailOutlinedIcon className="Icon__default" />,
    },
    {
      path: viewAutoDiscoveryPath,
      title: 'Auto Discover Status',
      icon: <TravelExploreIcon className="Icon__default" />,
    },
    {
      path: viewExcludedMailBoxPath,
      title: 'Excluded Mailbox Check',
      icon: <ViewListOutlinedIcon className="Icon__default" />,
    },
    {
      path: domainBackupsPath,
      title: 'Domain Backup (O365)',
      icon: <BackupIcon className="Icon__default" />,
    },
    {
      path: seatCountChangePath,
      title: 'Seat Count Change',
      icon: <PlusOneIcon className="Icon__default" />,
    },
    {
      path: viewTenantAuthErrorsPath,
      title: 'Check auth Errors',
      icon: <ViewListOutlinedIcon className="Icon__default" />,
    },
    {
      path: switchSPATypePath,
      title: 'Switch SPA Type',
      icon: <ToggleOn className="Icon__default" />,
    },
    {
      path: recreateBackupAdminPath,
      title: 'Recreate Backup Admin',
      icon: <AdminPanelSettingsIcon className="Icon__default" />,
    },
    {
      path: changeBackupAdminPath,
      title: 'Rename Backup Admin',
      icon: <ManageAccountsIcon className="Icon__default" />,
    },
    {
      path: switchCredentialsPath,
      title: 'Switch Tenant',
      icon: <SwitchAccountIcon className="Icon__default" />,
    },
  ];

  const menuReportsObject = [
    {
      path: seatUsageReportsPagePath,
      title: 'Seat Usage Report',
      icon: <CloudSyncOutlined className="Icon__default" />,
    },
    {
      path: monthlyBackupReportsPagePath,
      title: 'Monthly Backup Report',
      icon: <CloudSyncOutlined className="Icon__default" />,
    },
  ];

  const purgeMenuObjects = [
    {
      path: bulkPurgePath,
      title: 'Mail Accounts',
      icon: <GroupRemoveIcon className="Icon__default" />,
    },
    {
      path: purgeExternalAccountPath,
      title: 'External Account',
      icon: <PersonRemoveIcon className="Icon__default" />,
    },
    {
      path: purgeSharedDrivePath,
      title: 'SD Domain',
      icon: <CancelScheduleSendIcon className="Icon__default" />,
    },
    {
      path: purgeM365TenantPath,
      title: 'M365 Tenant',
      icon: <BookmarkRemoveIcon className="Icon__default" />,
    },
    {
      path: purgeGWSTenantPath,
      title: 'GWS Tenant',
      icon: <GoogleIcon className="Icon__default" />,
    },
  ];

  const tenantsMenuObjects = [
    {
      path: m365TenantListPath,
      title: 'M365 Tenant',
      icon: <MicrosoftIcon className="Icon__default" />,
      forceActivePath: [m365TenantListPath, m365TenantDetailPath],
    },
    {
      path: gwsTenantListPath,
      title: 'GWS Tenant',
      icon: <GoogleIcon className="Icon__default" />,
      forceActivePath: [gwsTenantListPath, gwsTenantDetailPath],
    },
    {
      path: hexTenantListPath,
      title: 'HEX Tenant',
      icon: <ChangeCircleIcon className="Icon__default" />,
      forceActivePath: [hexTenantListPath, hexTenantDetailPath],
    },
  ];

  const partnerMenuObjects = [
    {
      path: showPartnerListPath,
      title: 'Partners',
      icon: <ListIcon className="Icon__default" />,
      forceActivePath: [showPartnerListPath, resellersListPath],
    },
    {
      path: showUsersListPath,
      title: 'Partner Users',
      icon: <GroupsIcon className="Icon__default" />,
    },
  ];

  const userAndOrgMenuObjects = [
    {
      path: userBackupInfoPath,
      title: 'Backups',
      icon: <ContactMail className="Icon__default" />,
      forceActivePath: [userBackupInfoPath, userBackupDetailPath],
    },
  ];

  const systemStatusMenuObjects = [
    {
      path: backupStatusesPath,
      title: 'Backup Status',
      icon: <Backup className="Icon__default" />,
      forceActivePath: [backupStatusesPath],
    },
    {
      path: restoreStatusesPath,
      title: 'Restore Status',
      icon: <Restore className="Icon__default" />,
      forceActivePath: [restoreStatusesPath],
    },
    {
      path: downloadStatusesPath,
      title: 'Download Status',
      icon: <CloudDownload className="Icon__default" />,
      forceActivePath: [downloadStatusesPath],
    },
    {
      path: migrateStatusesPath,
      title: 'Migrate Status',
      icon: <CloudSync className="Icon__default" />,
      forceActivePath: [migrateStatusesPath],
    },
    {
      path: jobStatusActivitiesPath,
      title: 'Job Status',
      icon: <ManageHistory className="Icon__default" />,
      forceActivePath: [jobStatusActivitiesPath],
    },
  ];

  const organisationMenuObject = [
    {
      path: showOrganizationListPath,
      title: 'Organisations',
      icon: <TransferWithinAStationIcon className="Icon__default" />,
      forceActivePath: [showOrganizationListPath, organizationInformationPath],
    },
    {
      path: showOrganizationUsersListPath,
      title: 'Organisation Users',
      icon: <GroupsIcon className="Icon__default" />,
    },
  ];

  const orgMenuObjects = [...organisationMenuObject, ...tenantsMenuObjects];

  const subscriptionMenuObjects = [
    {
      path: transferSubscriptionPagePath,
      title: 'Initiate',
      icon: <PlayArrowIcon className="Icon__default" />,
    },
    {
      path: transferPath,
      title: 'Transfer',
      icon: <MoveUpIcon className="Icon__default" />,
    },
  ];
  const partnerTransferMenuObjects = [
    {
      path: transferSubresellerPagePath,
      title: 'Partner Transfer',
      icon: <TransferWithinAStationIcon className="Icon__default" />,
    },
  ];

  const partnerManagementMenuObjects = [
    ...partnerMenuObjects,
    ...userAndOrgMenuObjects,
    ...partnerTransferMenuObjects,
    ...subscriptionMenuObjects,
    ...orgMenuObjects,
    ...systemStatusMenuObjects,
  ];

  const transferMenuObjects = [...partnerTransferMenuObjects, ...subscriptionMenuObjects];

  const billingMenuObjects = [
    {
      path: showPlansListPath,
      title: 'Plans',
      icon: <NoteAltIcon className="Icon__default" />,
    },
    {
      path: invoicesListPath,
      title: 'Invoices',
      icon: <Receipt className="Icon__default" />,
    },
  ];

  const {
    TRANSFER_SUBSCRIPTION_MENU,
    TRANSFER,
    SEAT_USAGE,
    MAILBOX_PURGE,
    EXTERNAL_ACCOUNT,
    PURGE_M365_TENANT,
    GWS_TENANT_PURGE,
    SHARED_DRIVE,
    TENANT_M365_INFO,
    TENANT_GWS_INFO,
    TENANT_HEX_INFO,
    PARTNER_TRANSFER_MENU,
    TRANSFER_PATH_MENU,
    PARTNER_MENU,
    ORGANIZATION_MENU,
    USERS_MENU,
    MONTHLY_BACKUP_REPORT_MENU,
    MAILBOX_CHANGE,
    MAILBOX_SYNC,
    VIEW_EXCLUDED_MAILBOX,
    VIEW_AUTO_DISCOVERY,
    DOMAIN_BACKUPS,
    SEAT_COUNT_CHANGE_MENU,
    CHECK_AUTH_ERROR_MENU,
    PLANS_MENU,
    SWITCH_SPA_TYPE_MENU,
    RECREATE_BCKUP_ADMIN_MENU,
    USERBACKUP_MENU,
    M365_TENANT_DETAILS,
    GWS_TENANT_DETAILS,
  } = constant.MENUS;

  const partnerManagementMenus = [
    PARTNER_MENU,
    ORGANIZATION_MENU,
    USERS_MENU,
    PARTNER_TRANSFER_MENU,
    TRANSFER_SUBSCRIPTION_MENU,
    TRANSFER,
    TRANSFER_PATH_MENU,
    TENANT_M365_INFO,
    TENANT_GWS_INFO,
    M365_TENANT_DETAILS,
    USERBACKUP_MENU,
    GWS_TENANT_DETAILS,
    TENANT_HEX_INFO,
  ];
  const transferMenus = [
    PARTNER_TRANSFER_MENU,
    TRANSFER_SUBSCRIPTION_MENU,
    TRANSFER,
    TRANSFER_PATH_MENU,
  ];

  const purgeMenus = [
    SHARED_DRIVE,
    MAILBOX_PURGE,
    EXTERNAL_ACCOUNT,
    PURGE_M365_TENANT,
    GWS_TENANT_PURGE,
  ];

  const toolMenus = [
    MAILBOX_CHANGE,
    MAILBOX_SYNC,
    VIEW_EXCLUDED_MAILBOX,
    VIEW_AUTO_DISCOVERY,
    DOMAIN_BACKUPS,
    SEAT_COUNT_CHANGE_MENU,
    CHECK_AUTH_ERROR_MENU,
    SWITCH_SPA_TYPE_MENU,
    RECREATE_BCKUP_ADMIN_MENU,
  ];

  const tranferMenuList = [TRANSFER_SUBSCRIPTION_MENU, TRANSFER];
  const monthlyAndSeatUsage = [MONTHLY_BACKUP_REPORT_MENU, SEAT_USAGE];
  const tenantMenuList = [
    TENANT_M365_INFO,
    TENANT_GWS_INFO,
    M365_TENANT_DETAILS,
    GWS_TENANT_DETAILS,
    TENANT_HEX_INFO,
  ];
  const tenantMenuWithOrgList = [
    TENANT_M365_INFO,
    TENANT_GWS_INFO,
    ORGANIZATION_MENU,
    M365_TENANT_DETAILS,
    GWS_TENANT_DETAILS,
    TENANT_HEX_INFO,
  ];
  const planMenu = [PLANS_MENU];

  const collapsibleButtonHandler = () => {
    const flagState = JSON.parse(activeMenu1);
    if (partnerManagementMenus.includes(activeMenu)) {
      if (!transferMenus.includes(activeMenu) && !flagState?.transferManagement) {
        dispatch(setTransferlink({ open: false }));
        dispatch(setTransferSublink({ open: false }));
      } else if (!tranferMenuList.includes(activeMenu)) {
        dispatch(setTransferSublink({ open: false }));
      }
    }
    if (
      !monthlyAndSeatUsage.includes(activeMenu) &&
      seatUsageSublink &&
      !flagState?.reportsManagement
    ) {
      dispatch(setSeatUsageSublink({ open: false }));
    }
    if (!purgeMenus.includes(activeMenu) && purgeSublink && !flagState?.purgeManagement) {
      dispatch(setPurgeSublink({ open: false, handleOnPurgeSublink: false }));
    }
    if (
      !partnerManagementMenus.includes(activeMenu) &&
      partnerManagementLink &&
      !flagState?.partnerManagement
    ) {
      dispatch(setPartnerManagementLink({ open: false }));
    }
    if (!toolMenus.includes(activeMenu) && toolLink && !flagState?.toolsManagement) {
      dispatch(setToolLink({ open: false }));
    }
    if (!planMenu.includes(activeMenu) && billingLink && !flagState?.billingManagement) {
      dispatch(setBillingLink({ open: false }));
    }
    if (!tenantMenuList.includes(activeMenu) && tenantLink && !flagState?.tenantManagement) {
      dispatch(setTenantLink({ open: false }));
    }
    if (
      !tenantMenuWithOrgList.includes(activeMenu) &&
      organizationLink &&
      !flagState?.orgManagement
    ) {
      dispatch(setOrganizationLink({ open: false }));
    }
    if (partnerLink && !flagState?.partnersManagement) {
      dispatch(setPartnerLink({ open: false }));
    }
    if (systemStatusLink && !flagState?.systemStatusManagement) {
      dispatch(setSystemStatusLink({ open: false }));
    }
  };

  const menuStateHandler = async (setFlagState, properties, value, flag) => {
    if (value === true || value === false) {
      setFlagState((prevState) => {
        if (flag) {
          return {
            ...prevState,
            [properties]: value,
          };
        }

        const newState = Object.keys(prevState ?? {}).reduce(
          (acc, key) => ({
            ...acc,
            [key]: null,
          }),
          {}
        );

        return {
          ...newState,
          [properties]: value,
        };
      });
    }
  };

  const menuFlagHandler = (activeMenuItem) => {
    if (activeMenuItem?.partnerManagement) {
      dispatch(setPartnerManagementLink({ open: activeMenuItem?.partnerManagement }));
      if (activeMenuItem?.orgManagement) {
        dispatch(setOrganizationLink({ open: activeMenuItem?.orgManagement }));
      }
      if (activeMenuItem?.orgManagement && activeMenuItem?.tenantManagement) {
        dispatch(setTenantLink({ open: activeMenuItem?.tenantManagement }));
      }
      if (activeMenuItem?.partnersManagement) {
        dispatch(setPartnerLink({ open: activeMenuItem?.partnersManagement }));
      }
      if (activeMenuItem?.systemStatusManagement) {
        dispatch(setSystemStatusLink({ open: activeMenuItem?.systemStatusManagement }));
      }
      if (activeMenuItem?.transferManagement) {
        dispatch(setTransferlink({ open: activeMenuItem?.transferManagement }));
      }
      if (activeMenuItem?.transferManagement && activeMenuItem?.subscriptionManagement) {
        dispatch(setTransferSublink({ open: activeMenuItem?.subscriptionManagement }));
      }
    }
    if (activeMenuItem?.purgeManagement) {
      dispatch(setPurgeSublink({ open: activeMenuItem?.purgeManagement }));
    }
    if (activeMenuItem?.reportsManagement) {
      dispatch(setSeatUsageSublink({ open: activeMenuItem?.reportsManagement }));
    }
    if (activeMenuItem?.toolsManagement) {
      dispatch(setToolLink({ open: activeMenuItem?.toolsManagement }));
    }
    if (activeMenuItem?.billingManagement) {
      dispatch(setBillingLink({ open: activeMenuItem?.billingManagement }));
    }
  };

  const menuStatesOne = [
    'transferManagement',
    'orgManagement',
    'subscriptionManagement',
    'tenantManagement',
    'partnersManagement',
    'systemStatusManagement',
  ];
  const menuStatesTwo = [
    'purgeManagement',
    'reportsManagement',
    'toolsManagement',
    'billingManagement',
  ];

  const partnerManagementPathList = [
    showPartnerListPath,
    showOrganizationListPath,
    showUsersListPath,
    transferSubresellerPagePath,
    transferPath,
    resellersListPath,
    m365TenantListPath,
    gwsTenantListPath,
    hexTenantListPath,
    organizationInformationPath,
    transferSubscriptionPagePath,
    m365TenantDetailPath,
    userBackupInfoPath,
    userBackupDetailPath,
    gwsTenantDetailPath,
    hexTenantDetailPath,
    backupStatusesPath,
    downloadStatusesPath,
  ];

  const partnerPathList = [showPartnerListPath, showUsersListPath, resellersListPath];

  const organisationPathList = [
    showOrganizationListPath,
    m365TenantListPath,
    gwsTenantListPath,
    organizationInformationPath,
    m365TenantDetailPath,
    gwsTenantDetailPath,
    hexTenantListPath,
    hexTenantDetailPath,
  ];

  const systemStatusPathList = [backupStatusesPath, downloadStatusesPath];

  const tenantsPathList = [m365TenantListPath, hexTenantListPath, gwsTenantListPath];

  const transferSubscriptionPathsList = [transferSubscriptionPagePath, transferPath];

  const transferPathList = [
    transferSubresellerPagePath,
    transferSubscriptionPagePath,
    transferPath,
  ];

  const ingestionPathList = [dataIngestionPath, dataIngestionListPath];

  const reportsPathList = [monthlyBackupReportsPagePath, seatUsageReportsPagePath];

  const plansListpath = [showPlansListPath];

  const purgePathList = [
    bulkPurgePath,
    purgeExternalAccountPath,
    purgeSharedDrivePath,
    bulkPurgeListPath,
    purgeM365TenantPath,
    purgeGWSTenantPath,
  ];

  const toolsPathList = [
    mailBoxChangePath,
    mailBoxSyncPath,
    viewAutoDiscoveryPath,
    viewExcludedMailBoxPath,
    domainBackupsPath,
    seatCountChangePath,
    viewTenantAuthErrorsPath,
    switchSPATypePath,
    recreateBackupAdminPath,
    changeBackupAdminPath,
    switchCredentialsPath,
  ];

  const tenantPathsLIst = [
    m365TenantListPath,
    gwsTenantListPath,
    m365TenantDetailPath,
    gwsTenantDetailPath,
    hexTenantListPath,
    hexTenantDetailPath,
  ];

  return {
    toolsObjects,
    menuReportsObject,
    purgeMenuObjects,
    tenantsMenuObjects,
    partnerMenuObjects,
    orgMenuObjects,
    userAndOrgMenuObjects,
    subscriptionMenuObjects,
    transferMenuObjects,
    partnerTransferMenuObjects,
    organisationMenuObject,
    partnerManagementMenuObjects,
    billingMenuObjects,
    partnerManagementPathList,
    organisationPathList,
    partnerPathList,
    tenantsPathList,
    transferPathList,
    purgePathList,
    toolsPathList,
    tenantPathsLIst,
    menuStateHandler,
    menuFlagHandler,
    menuStatesOne,
    menuStatesTwo,
    ingestionPathList,
    reportsPathList,
    plansListpath,
    transferSubscriptionPathsList,
    collapsibleButtonHandler,
    systemStatusMenuObjects,
    systemStatusPathList,
  };
}
